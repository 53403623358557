import { strings } from '~utils/strings';
import NewOrganization from './NewOrganization.react';

export default {
  metaInfo() {
    return { title: `${strings.metaTitle}${strings.newOrganization.newOrganization}` };
  },
  name: 'create-org',
  components: {
    'new-organization': NewOrganization,
  },
  methods: {
    route() {
      return this.$route;
    },
    router() {
      return this.$router;
    },
    store() {
      return this.$store;
    },
  },
};

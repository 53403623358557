import * as React from 'react';
import { SVGProps } from 'react';
import { COLORS } from '~utils/styling';

const AdmissionControllerIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx={10} cy={10} r={8.25} stroke={COLORS.CORE.WHITE} strokeWidth={1.5} />
    <path
      d="M8.35 13.64c.18.17.37.28.57.28.2 0 .38-.11.55-.28l5.45-5.42c.36-.38.25-.85-.02-1.12a.81.81 0 0 0-.51-.25.76.76 0 0 0-.6.24l-4.85 4.86-1.75-1.72a.73.73 0 0 0-.58-.24c-.2.01-.38.1-.52.24a.81.81 0 0 0-.24.52c-.01.2.06.4.24.58l.55.57c.56.58 1.12 1.18 1.7 1.74z"
      fill={COLORS.CORE.WHITE}
      stroke={COLORS.CORE.WHITE}
      strokeWidth={0.3}
    />
  </svg>
);

export default AdmissionControllerIcon;

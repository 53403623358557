import * as React from 'react';
import { SVGProps } from 'react';
import { COLORS } from '~utils/styling';

const PolicyIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M17.91 3.7a.53.53 0 0 0-.2-.39.53.53 0 0 0-.4-.1h-.01c-.4.03-4.12.41-6.78-2.25a.53.53 0 0 0-.75 0C7.12 3.62 3.42 3.25 3 3.2h-.01a.53.53 0 0 0-.41.11.53.53 0 0 0-.2.39c-.09 1.77-.14 3.9.04 5.9.19 1.98.61 3.85 1.5 5.08a13.77 13.77 0 0 0 3.44 3.1c.6.39 1.14.71 1.59.94.44.24.78.39.94.44.17.05.34.05.51 0a7 7 0 0 0 .95-.44c.44-.23 1-.55 1.59-.94a13.57 13.57 0 0 0 3.43-3.1M17.91 3.7h-.2.2zm0 0c.09 1.77.14 3.9-.04 5.9-.18 1.98-.6 3.85-1.5 5.08m0 0-.16-.12.16.12zM2.7 3.47a.33.33 0 0 0-.12.24l.12-.24zm12.82 10.58a12.53 12.53 0 0 1-3.17 2.85 18.28 18.28 0 0 1-2.2 1.23 18.4 18.4 0 0 1-2.2-1.23 12.6 12.6 0 0 1-3.17-2.85c-.74-1-1.12-2.63-1.3-4.46a38.47 38.47 0 0 1-.05-5.3 9.54 9.54 0 0 0 6.73-2.24 9.48 9.48 0 0 0 6.72 2.24c.07 1.66.1 3.55-.07 5.3-.17 1.83-.56 3.45-1.3 4.46zm-6.18-1.88v.2a.53.53 0 0 0 .38-.18l3.35-3.66a.53.53 0 0 0 .04-.68v-.02l-.07-.06a.53.53 0 0 0-.74.03l-2.98 3.27L7.99 9.7a.53.53 0 1 0-.75.74l1.72 1.75a.53.53 0 0 0 .38.17v-.2z"
      fill={COLORS.CORE.WHITE}
      stroke={COLORS.CORE.WHITE}
      strokeWidth={0.4}
    />
  </svg>
);

export default PolicyIcon;

import React, { useEffect, useMemo, useState, useRef, useCallback } from 'react';
import { Nav, NavItem, NavLink, InputGroup, Button } from 'react-bootstrap';
import Select, { components, DropdownIndicatorProps, GroupBase } from 'react-select';
import clsx from 'clsx';

import SelectArrow from '~reactIcons/SelectArrow.react';
import pinOutline from '@/assets/icons/pin-unselected.svg';
import pinFilled from '@/assets/icons/pin-selected.svg';

import {
  CLUSTER_OVERVIEW,
  DEFAULT_CLUSTER_OPTIONS,
  DEFAULT_REPOSITORY_OPTIONS,
  ExpandableItem,
  MAIN_NAVIGATION_ITEMS,
  NavigationItem,
  POLICY_WIZARD,
  REACT_REPOSITORIES,
  REACT_REPOSITORY,
  RESTRICTED_ALL_CLUSTERS_OPTION_ROUTES,
  SELECT_STYLES,
  COSTS_SETTINGS,
  COSTS_SETTINGS_CLUSTER,
  ACTION_ITEMS_REPORTS,
  ORG_DASHBOARD,
  NEW_CLUSTER,
  ADMIN,
  APP_GROUPS,
  REPORT_HUB_CONFIGURE,
  ADMISSION_REQUESTS_CLUSTER,
  CLUSTER_ADMISSION_CONTROLLER,
  RIGHT_SIZING_CLUSTER,
  RIGHT_SIZING,
  APP_GROUPS_SUMMARY,
} from '../Navigation.config.react';

import {
  shouldHideAddClusterButton,
  shouldHideClusterConfigureAlert,
  setStickyCluster,
} from './TopNavigation.helpers.react';
import { Labels, NavigateTo } from './TopNavigation.types.react';
import {
  clusterSelectionConfigs,
  clusterUnselectionConfigs,
  handlerNames,
  topNavigationActiveRoutes,
  noUpdateStickyCluster,
} from './TopNavigation.config.react';

import { Cluster, Repository, IStore, IRouter, IRoute, OptionType } from '~globalTypes';

import {
  TOGGLE_TOAST,
  SET_REPOSITORY,
  TOGGLE_ADD_REPO_MODAL,
  TOGGLE_REPO_SETTINGS_MODAL,
  UPDATE_CLUSTER,
  TOGGLE_ADD_APP_GROUP_MODAL,
} from '~store/action.types';
import logger from '~utils/logger';
import { COLORS } from '~utils/styling';
import { strings } from '~utils/strings';
import { BasicText } from '~utils/texts.react';

import './TopNavigation.react.scss';

interface TopNavigationProps {
  store: () => IStore;
  route: IRoute;
  router: () => IRouter;
  repositories: Repository[];
  apiOverride?: string;
}

const TopNavigation = ({ route, router, store, repositories, apiOverride }: TopNavigationProps) => {
  const [activeRoute, setActiveRoute] = useState<string>('');
  const [navItem, setNavItem] = useState<NavigationItem | ExpandableItem>();
  const [sticky, setSticky] = useState<boolean>(() => {
    return JSON.parse(localStorage.getItem(strings.noTranslate.sticky) || 'false');
  });
  const selectedCluster: string | undefined = store().getters.cluster?.Name;
  const selectedRepository: string | undefined = store().getters.repository?.Name;
  const isOrgOwner = store().getters.isOrgOwner;

  const errorToastId = useRef<number>(1);

  useEffect(() => {
    const currentRoute = route.name?.includes(POLICY_WIZARD) ? POLICY_WIZARD : route.name;

    if (!noUpdateStickyCluster.includes(route.name || '')) {
      setStickyCluster(selectedCluster ? selectedCluster : DEFAULT_CLUSTER_OPTIONS[0].value);
    }

    setActiveRoute(currentRoute ? currentRoute : '');

    const MAIN_NAVIGATION_ROUTE_ITEMS = MAIN_NAVIGATION_ITEMS(router()?.currentRoute?.name || '');

    if (route.name === ADMIN) {
      const foundMatch = MAIN_NAVIGATION_ROUTE_ITEMS.bottom.find((item) => item.title === strings.noTranslate.Profile);
      const foundNavItem = foundMatch?.expandableItems?.find((item) => item.route?.name === route.name);
      setNavItem(foundNavItem);
      return;
    }

    for (const key in MAIN_NAVIGATION_ROUTE_ITEMS) {
      MAIN_NAVIGATION_ROUTE_ITEMS[key].some((navItem) => {
        // Search for any secondary nav in expandable menus
        const expandableSecondaryNav = navItem.expandableItems?.find((expandableItem) =>
          expandableItem.secondaryNav?.find((item) => item.route?.name === route.name),
        );

        if (expandableSecondaryNav) {
          setNavItem(expandableSecondaryNav);
          return true;
        }
        const secondaryNav = navItem?.secondaryNav?.find((item) => item.route?.name === currentRoute);

        if (
          route.name === navItem.route?.name ||
          secondaryNav ||
          navItem.sameRoutes?.includes(route.name || '') ||
          (route.name === strings.AdmissionRequestsCluster && navItem.route?.name === strings.AdmissionRequests)
        ) {
          setNavItem(navItem || undefined);
          return true;
        }
      });
    }
  }, [route.name]);

  const handleClusterSelection = (cluster?: unknown): void => {
    const typedCluster = cluster as OptionType;
    logger.logEvent('cluster changed');

    if (route.name?.includes(ACTION_ITEMS_REPORTS)) {
      logger.logEvent('action-items-cluster-reports selected', typedCluster.value);
    }

    if (typedCluster?.value) {
      if (typedCluster.value !== selectedCluster) {
        setStickyCluster(typedCluster.value);
        store().dispatch(UPDATE_CLUSTER, typedCluster?.value);
      }
      handleSelectedCluster(typedCluster);
      return;
    } else {
      setStickyCluster();
      store().dispatch(UPDATE_CLUSTER, '');
      handleAllClustersSelected();
    }
  };

  const handleSelectedCluster = (cluster: OptionType) => {
    if (!cluster) return;

    for (const config of clusterSelectionConfigs) {
      if (config.conditionHandler(route.name!, config.conditionRoutes)) {
        if (config.routeName === strings.AdmissionRequests || config.routeName === strings.AdmissionRequestsCluster) {
          router().push({
            name: strings.AdmissionRequestsCluster,
            params: {
              org: route?.params?.org,
              cluster: cluster?.value,
              operation: strings.selectCluster,
            },
          });
          return;
        }
        switch (config.handlerName) {
          case handlerNames.handleActionItemPagesNavigation:
            handleActionItemPagesNavigation(config.routeName, cluster);
            return;
          case handlerNames.handleActionItemSummaryNavigation:
            handleActionItemSummaryNavigation(config.routeName, cluster);
            return;
          case handlerNames.handleActionItemReportsNavigation:
            handleActionItemReportsNavigation(config.routeName, cluster);
            return;
          case handlerNames.goToRoute:
            goToRoute(config.routeName, {
              org: route?.params?.org,
              cluster: cluster?.value,
            });
            return;
          case handlerNames.goToRouteWithMeta:
            goToRouteWithMeta(cluster);
            return;
          case handlerNames.handleCostSettingPageNavigation:
            goToRoute(COSTS_SETTINGS_CLUSTER, {
              org: route?.params?.org,
              cluster: cluster?.value,
            });
            return;
          case handlerNames.handleRightSizingPageNavigation:
            goToRoute(RIGHT_SIZING_CLUSTER, {
              org: route?.params?.org,
              cluster: cluster?.value,
            });
            return;
          case handlerNames.updateClusterReportHub:
            goToRoute(config.routeName, {
              org: route?.params?.org,
              cluster: cluster?.value,
            });
            store().dispatch(UPDATE_CLUSTER, cluster?.value);
            return;
          default:
            return;
        }
      }
    }
    goToRoute(CLUSTER_OVERVIEW, {
      org: route?.params?.org,
      cluster: cluster?.value,
    });
  };

  const handleAllClustersSelected = () => {
    for (const config of clusterUnselectionConfigs) {
      if (config.conditionHandler(route.name!, config.conditionRoutes)) {
        if (config.routeName === strings.AdmissionRequests || config.routeName === strings.AdmissionRequestsCluster) {
          router().push({
            name: strings.AdmissionRequests,
            params: {
              org: route?.params?.org,
              cluster: '',
              operation: strings.selectCluster,
            },
          });
          return;
        }
        switch (config.handlerName) {
          case handlerNames.handleActionItemPagesNavigation:
            handleActionItemPagesNavigation(config.routeName);
            return;
          case handlerNames.handleActionItemSummaryNavigation:
            handleActionItemSummaryNavigation(config.routeName);
            return;
          case handlerNames.handleActionItemReportsNavigation:
            handleActionItemReportsNavigation(config.routeName);
            return;
          case handlerNames.goToRoute:
            goToRoute(config.routeName, {
              org: route?.params?.org,
            });
            return;
          case handlerNames.handleCostSettingPageNavigation:
            goToRoute(COSTS_SETTINGS, {
              org: route?.params?.org,
            });
            return;
          case handlerNames.handleRightSizingPageNavigation:
            goToRoute(RIGHT_SIZING, {
              org: route?.params?.org,
            });
            return;
          case handlerNames.handlePageChange:
            handlePageChange(config.routeName);
            return;
          default:
            return;
        }
      }
    }
  };

  const handleActionItemPagesNavigation = (routeName: string, cluster?: OptionType) => {
    const navigateTo: NavigateTo = {
      name: routeName,
      params: {
        org: route?.params?.org,
        cluster: '',
      },
      query: {
        ...route.query,
        redirect: true,
      },
    };
    if (cluster) {
      navigateTo.params.cluster = cluster.value;
      navigateTo.query.Cluster = cluster.value;
    } else {
      delete navigateTo?.query?.Cluster;
      if (routeName === 'cumulative-costs') {
        delete navigateTo?.query?.clusters;
      }
    }
    router().push(navigateTo);
  };

  const handleActionItemReportsNavigation = (routeName: string, cluster?: OptionType) => {
    const navigateTo = {
      name: routeName,
      params: {
        org: route?.params?.org,
        cluster: '',
      },
      query: {
        ...route.query,
      },
    };
    if (cluster) {
      navigateTo.params.cluster = cluster.value;
      navigateTo.query.cluster = cluster.value;
    } else {
      delete navigateTo?.query?.cluster;
    }
    router().push(navigateTo);
  };

  const handleActionItemSummaryNavigation = (routeName: string, cluster?: OptionType) => {
    const navigateTo = {
      name: routeName,
      params: {
        org: route?.params?.org,
        cluster: '',
      },
      query: {
        ...route.query,
      },
    };
    if (cluster) {
      navigateTo.params.cluster = cluster.value;
      navigateTo.query.cluster = cluster.value;
    } else {
      delete navigateTo?.query?.cluster;
    }
    router().push(navigateTo);
  };

  const goToRoute = (routeName: string, params: { org?: string; cluster?: string }) => {
    if (!routeName || !params) return;
    router().push({
      name: routeName,
      params,
    });
  };

  const goToRouteWithMeta = (cluster: OptionType) => {
    router().push({
      name: route.meta?.goToCluster || route.name,
      params: {
        org: route?.params?.org,
        cluster: cluster?.value,
      },
    });
  };

  const handlePageChange = (routeName: string, event?: React.SyntheticEvent<unknown, Event>) => {
    if (event?.metaKey || event?.ctrlKey || event?.shiftKey) {
      // we're going to open the page in a new tab.
      return;
    }
    // stop propagation so that the page doesn't hard refresh on URL change
    event?.preventDefault();
    logger.logEvent(`${routeName} selected`);
    if (store().getters.clusterSummary?.SetupNeeded && !shouldHideClusterConfigureAlert(routeName!)) {
      const error = {
        message: Labels.clusterConfigureAlertMessage,
        position: Labels.clusterConfigureAlertPosition,
        type: Labels.clusterConfigureAlertType,
        id: String(errorToastId.current + 1),
        prevId: String(errorToastId.current),
      };
      store().commit(TOGGLE_TOAST, error);
      errorToastId.current += 1;
      return;
    }

    const selectedRouteName = routeName === CLUSTER_ADMISSION_CONTROLLER ? ADMISSION_REQUESTS_CLUSTER : routeName;

    router()
      .push(
        router().resolve({
          name: selectedRouteName,
        }).route,
      )
      .catch((e: any) => {
        if (e.message.includes(Labels.routerPushErrorMessage)) {
          return;
        }
        console.error(e);
      });
    setActiveRoute(selectedRouteName);
  };

  const DropdownIndicator = (props: DropdownIndicatorProps<OptionType, false, GroupBase<OptionType>>) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <SelectArrow />
        </components.DropdownIndicator>
      )
    );
  };

  const clusterOptions = useMemo(() => {
    if (!store().getters.clusters) return DEFAULT_CLUSTER_OPTIONS;
    const clusters = store().getters.clusters.map((cluster: Cluster) => ({
      label: cluster.Name,
      value: cluster.Name,
    }));
    return RESTRICTED_ALL_CLUSTERS_OPTION_ROUTES.includes(activeRoute)
      ? [...clusters]
      : [...DEFAULT_CLUSTER_OPTIONS, ...clusters];
  }, [store().getters.clusters?.length, activeRoute]);

  const repositoryOptions = useMemo(() => {
    if (!repositories) return DEFAULT_REPOSITORY_OPTIONS;
    const repositoryOptions = repositories.map((repository) => ({
      label: repository.Name,
      value: repository.Name,
    }));
    return [...DEFAULT_REPOSITORY_OPTIONS, ...repositoryOptions];
  }, [repositories?.length, activeRoute]);

  const handleRepositorySelection = (repositoryName?: unknown) => {
    const repoName = repositoryName as OptionType;
    const repository = repositories.find((repository) => repository.Name === repoName.value);
    logger.logEvent('repository selected');
    store().commit(SET_REPOSITORY, repository);
    router().push({
      name: repository ? REACT_REPOSITORY : REACT_REPOSITORIES,
      params: repository
        ? {
            org: route?.params?.org,
            repo: repository.Name,
          }
        : {
            org: route?.params?.org,
          },
    });
  };

  const toggleRepoModals = (isAddRepoModalShown: boolean, isRepoSettingsModalShown: boolean) => () => {
    store().commit(TOGGLE_ADD_REPO_MODAL, isAddRepoModalShown);
    store().commit(TOGGLE_REPO_SETTINGS_MODAL, isRepoSettingsModalShown);
  };

  const getActiveRoute = () => {
    if (!activeRoute) return undefined;
    for (const item of topNavigationActiveRoutes) {
      if (item.relatedRoutes?.length && item.relatedRoutes.includes(activeRoute)) {
        return item.activeRoute;
      }
    }
    return activeRoute;
  };

  const setStickyState = () => {
    const savedCluster = JSON.parse(localStorage.getItem(strings.noTranslate.currentCluster) || '{}');
    const updatedStickyOption = !sticky;
    setSticky(updatedStickyOption);

    if (savedCluster.length > 0 && !selectedCluster) {
      setStickyCluster();
    }
    localStorage.setItem(strings.noTranslate.sticky, JSON.stringify(updatedStickyOption));
  };

  return (
    <header style={{ height: '3rem' }} className={!navItem ? 'hidden' : ''}>
      <Nav
        variant="tabs"
        onSelect={(eventKey, e?: React.SyntheticEvent<unknown, Event>) => {
          if (eventKey && e) handlePageChange(eventKey, e);
        }}
        className={`navigation-header ${apiOverride ? 'api-override' : ''} favorite-padding`}
        activeKey={getActiveRoute()}
        aria-label={strings.topNav.navAriaLabel}
        role="navigation"
        data-cy="top-navigation"
      >
        {navItem?.showClusterDropdown ? (
          <InputGroup>
            <InputGroup.Prepend>
              <Button
                variant="white"
                onClick={setStickyState}
                title={strings.navigation.stickyTitle}
                data-cy="pin-cluster-button"
              >
                {sticky ? (
                  <img src={pinFilled} alt={strings.topNav.pinned} />
                ) : (
                  <img src={pinOutline} alt={strings.topNav.unpinned} />
                )}
              </Button>
            </InputGroup.Prepend>
            <form>
              <label id="clusterMenuSelectionLabel" htmlFor="clusterMenuSelection" className="sr-only">
                {strings.topNav.clusterMenu}
              </label>
              <Select
                classNamePrefix="clusters-select"
                aria-labelledby="clusterMenuSelectionLabel"
                inputId="clusterMenuSelection"
                isClearable={false}
                isSearchable={true}
                options={clusterOptions}
                styles={SELECT_STYLES}
                value={
                  selectedCluster && route.name !== ORG_DASHBOARD
                    ? { label: selectedCluster, value: selectedCluster }
                    : DEFAULT_CLUSTER_OPTIONS[0]
                }
                onChange={handleClusterSelection}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary: COLORS.VARIANTS.LIGHTER_GREY,
                    primary25: COLORS.VARIANTS.BAR_1,
                  },
                })}
                components={{
                  IndicatorSeparator: () => null,
                  DropdownIndicator,
                }}
                defaultValue={DEFAULT_CLUSTER_OPTIONS[0]}
              />
            </form>
          </InputGroup>
        ) : null}
        {navItem?.showRepositoriesDropdown ? (
          <form>
            <label id="repositoryMenuSelectionLabel" htmlFor="repositoryMenuSelection" className="sr-only">
              {strings.topNav.repoMenu}
            </label>
            <Select
              classNamePrefix="repositories-select"
              aria-labelledby="repositoryMenuSelectionLabel"
              inputId="repositoryMenuSelection"
              isClearable={false}
              isSearchable={true}
              options={repositoryOptions}
              styles={SELECT_STYLES}
              value={
                selectedRepository
                  ? { label: selectedRepository, value: selectedRepository }
                  : DEFAULT_REPOSITORY_OPTIONS[0]
              }
              onChange={handleRepositorySelection}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary: COLORS.VARIANTS.LIGHTER_GREY,
                  primary25: COLORS.VARIANTS.BAR_1,
                },
              })}
              components={{
                IndicatorSeparator: () => null,
                DropdownIndicator,
              }}
              defaultValue={DEFAULT_REPOSITORY_OPTIONS[0]}
            />
          </form>
        ) : null}
        <h1
          className={clsx(
            'navigation-header-title',
            BasicText({
              size: strings.textStyling.lg,
              weight: 'semibold',
              color: strings.textStyling.primary,
            }),
            { 'sr-only': navItem?.showClusterDropdown || navItem?.showRepositoriesDropdown },
          )}
        >
          {navItem?.title || navItem?.label}
        </h1>
        <ul>
          {navItem?.secondaryNav?.map(
            (item) =>
              !item.isHidden && (
                <NavItem key={item.label} as="li">
                  <NavLink
                    eventKey={item?.route?.name}
                    href={router().resolve({ name: item?.route?.name }).href}
                    className={activeRoute}
                  >
                    {item.label}
                  </NavLink>
                </NavItem>
              ),
          )}
        </ul>
        {isOrgOwner && navItem?.showClusterDropdown && !shouldHideAddClusterButton(route.name!) && (
          <div className="add-cluster">
            <a href={router().resolve({ name: NEW_CLUSTER }).href}>{strings.topNav.addCluster}</a>
          </div>
        )}
        {navItem?.showRepositoriesDropdown && isOrgOwner && (
          <div className="right-actions">
            <span onClick={toggleRepoModals(false, true)}>{strings.topNav.Settings}</span>
            <span onClick={toggleRepoModals(true, false)}>{strings.topNav.addRepo}</span>
          </div>
        )}
        {route?.name?.includes(APP_GROUPS_SUMMARY) && (
          <div className="right-actions">
            <span onClick={() => store().commit(TOGGLE_ADD_APP_GROUP_MODAL, true)} data-cy="add-app-group-button">
              {strings.appGroups.addAppGroup}
            </span>
          </div>
        )}
        {selectedCluster && route?.name?.includes(strings.AdmissionRequests) && (
          <div className="right-actions">
            <span
              onClick={() =>
                router().push({
                  name: REPORT_HUB_CONFIGURE,
                  params: {
                    report: strings.admission,
                    cluster: selectedCluster,
                  },
                })
              }
            >
              {strings.general.Settings}
            </span>
          </div>
        )}
      </Nav>
    </header>
  );
};

export default TopNavigation;

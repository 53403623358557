import { strings } from '~utils/strings';

import {
  hideAddClusterButtonRoutes,
  hideClusterConfigureAlertRoutes,
} from './TopNavigation.config.react';

export const shouldHideAddClusterButton = (routeName: string) => {
  if (!hideAddClusterButtonRoutes?.length || !routeName) return;
  return hideAddClusterButtonRoutes.some((condition) =>
    condition.handler(routeName, [condition.name]),
  );
};

export const shouldHideClusterConfigureAlert = (routeName: string) => {
  if (!hideClusterConfigureAlertRoutes?.length || !routeName) return;
  return hideClusterConfigureAlertRoutes.some((condition) =>
    condition.handler(routeName, [condition.name]),
  );
};

export const setStickyCluster = (cluster?: string) => {
  localStorage.setItem(strings.noTranslate.currentCluster, JSON.stringify(cluster || ''));
};

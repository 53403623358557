import React from 'react';
import { Disclosure } from '@headlessui/react';
import {
  ExpandableItem,
  HIDE_LEFT_NAV_ROUTES,
  HIDDEN_ROUTES,
  NavigationItem,
} from '../../Navigation.config.react';
import {
  routeHref as routerHref,
  assertExpandableItemDependencies,
} from '../../Navigation.helpers.react';
import { IStore, IRouter } from '~globalTypes';
import './Item.react.scss';
import CaretIcon from '~reactIcons/Navigation/Caret.icon.react';
import logger from '~utils/logger';
import { strings } from '~utils/strings';
import { MediumBasicText, BasicText } from '~utils/texts.react';

type MenuItemsProps = {
  children: React.ReactNode;
  className?: string;
};

const MenuItems = ({ children, className, ...props }: MenuItemsProps) => {
  return (
    <ul className={`navigation-panel__items ${className}`} {...props}>
      {children}
    </ul>
  );
};

export default MenuItems;

type ItemsProps = {
  item: NavigationItem | ExpandableItem | any;
  onClick: (item: NavigationItem | ExpandableItem, e: React.SyntheticEvent) => any;
  onMouseHover: (label: string) => void;
  router: () => IRouter;
  store: () => IStore;
  collapsed?: boolean;
  hoveredItem?: string;
  label?: string;
  className?: string;
  icon?: any;
  active?: boolean;
  routeHref: { href?: string; newTab?: boolean };
};

MenuItems.Item = ({
  item,
  collapsed,
  hoveredItem,
  label,
  onClick,
  onMouseHover,
  router,
  store,
  active,
  routeHref,
  ...props
}: ItemsProps) => {
  const RenderHoveredItems = () => {
    if (item?.expandableItems?.length) {
      return (
        <div className="navigation-panel__collapsed-container">
          {item.expandableItems.map((expandableItem: any) => {
            if (!assertExpandableItemDependencies(store, expandableItem.route)) {
              return <></>;
            }
            if (expandableItem.route.type === strings.noTranslate.link) {
              const routeHref = routerHref(router, expandableItem.route);
              return (
                <a
                  href={routeHref.href}
                  target={routeHref.newTab ? '_blank' : strings.noTranslate.self}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  {expandableItem.label}
                </a>
              );
            }
            return (
              <span onClick={(e: React.SyntheticEvent) => handleItemClicked(expandableItem, e)}>
                {expandableItem.label}
              </span>
            );
          })}
        </div>
      );
    }
    return <span>{item.label}</span>;
  };

  const handleItemClicked = (item: NavigationItem | ExpandableItem, e: React.SyntheticEvent) => {
    e.stopPropagation();
    if (item?.route?.type === strings.noTranslate.link) return;
    logger.logEvent(`${item.label} route selected`);
    onClick(item, e);
  };

  if (
    HIDE_LEFT_NAV_ROUTES.includes(item?.route?.name) ||
    (HIDDEN_ROUTES.includes(item?.route?.name) && !localStorage.getItem('darkLaunchedFeatures'))
  ) {
    return <></>;
  }

  return (
    <li
      className={`${props.className || ''} menu--item ${active ? 'menu--item__active' : ''}`}
      onClick={(e: React.SyntheticEvent) => handleItemClicked(item, e)}
      key={item.label}
      onMouseEnter={() => onMouseHover(item.label)}
      onMouseLeave={() => onMouseHover('')}
      aria-current={active ? 'page' : false}
    >
      {/* Hover label */}
      <div
        className={`navigation-panel--item__hover
                      ${hoveredItem === item.label ? 'active' : ''}
                      ${hoveredItem === 'Help' && collapsed ? 'move-up' : ''}
                      ${collapsed ? '' : 'd-none'}`}
      >
        <RenderHoveredItems />
      </div>
      <a href={routeHref.href} target={routeHref.newTab ? '_blank' : strings.noTranslate.self}>
        {item?.icon ? (
          <item.icon
            aria-hidden={true}
            className={`expandable-caret ${
              item.label === 'Collapse' && collapsed ? 'caret-reverse' : ''
            }`}
          />
        ) : null}
        <span
          className={
            collapsed
              ? 'd-none'
              : item.icon
                ? MediumBasicText({
                    weight: strings.textStyling.thin,
                    color: strings.textStyling.white,
                  })
                : MediumBasicText({
                    color: strings.textStyling.white,
                    weight: strings.textStyling.thin,
                  })
          }
        >
          {label || item.label}
        </span>
      </a>
    </li>
  );
};

type ExpandableProps = {
  item: NavigationItem;
  label?: string;
  children: React.ReactNode;
  defaultOpen: boolean;
};

MenuItems.Expandable = ({ item, label, children, defaultOpen }: ExpandableProps) => {
  return (
    <li className="menu--item__expandable">
      <Disclosure defaultOpen={defaultOpen}>
        {({ open }) => (
          <>
            <Disclosure.Button
              className={MediumBasicText({
                weight: strings.textStyling.thin,
                color: strings.textStyling.white,
              })}
            >
              {item.icon ? <item.icon aria-hidden={true} /> : null}
              <span className="expandable-label">{label || item.label}</span>
              <CaretIcon
                style={{ margin: '0 1.5rem 0 auto' }}
                className={`expandable-caret ${open ? 'caret-reverse' : ''}`}
              />
            </Disclosure.Button>
            <Disclosure.Panel>{children}</Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </li>
  );
};

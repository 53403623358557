// Not sure why ESLint says this can't be found.
// eslint-disable-next-line
import { VexillaClient } from '@vexilla/client';

let environment = 'dev';
// Currently we want to treat our Cypress test env as prod
// duplicated assignment for ease of changing later
if (window.Cypress) {
  environment = 'prod';
} else if (window.insightsConfig.production) {
  environment = 'prod';
} else if (window.insightsConfig.staging) {
  environment = 'staging';
} else if (window.insightsConfig.feature) {
  environment = 'feature';
}

const vexilla = new VexillaClient({
  baseUrl: 'https://feature-flags.insights.fairwinds.com',
  environment,
  customInstanceHash: '',
});

// This helper function allows us to override values via localstorage
// eslint-disable-next-line
export function vexillaShould(flag) {
  const localShould = localStorage.getItem(flag);
  if (localShould === true || localShould === 'true') {
    return true;
  }

  if (localShould === false || localShould === 'false') {
    return false;
  }

  return vexilla.should(flag);
}

async function init() {
  try {
    if (!window.insightsConfig.selfHosted) {
      // eventually, we might want to fetch these on an interval
      const flags = await vexilla.getFlags('features.json', async (url) => {
        const response = await fetch(url);
        const responseData = await response.json();
        return responseData;
      });
      vexilla.setFlags(flags);
    }
  } catch (e) {
    // eslint-disable-next-line
    console.error('Error fetching feature flags', e);
  }
}

init();

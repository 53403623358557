import * as React from 'react';
import { SVGProps } from 'react';
import { COLORS } from '~utils/styling';

const ClustersIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <mask id="a" fill={COLORS.CORE.WHITE}>
      <rect x="1" y="13.6" width="18" height="5.4" rx=".5" />
    </mask>
    <rect
      x="1"
      y="13.6"
      width="18"
      height="5.4"
      rx=".5"
      stroke={COLORS.CORE.WHITE}
      strokeWidth="3"
      mask="url(#a)"
    />
    <path
      d="M15.4 15.85a.45.45 0 0 1 .9 0v.9a.45.45 0 0 1-.9 0v-.9ZM7.3 15.85a.45.45 0 0 1 .9 0v.9a.45.45 0 0 1-.9 0v-.9ZM3.7 15.85a.45.45 0 0 1 .9 0v.9a.45.45 0 0 1-.9 0v-.9ZM5.5 15.85a.45.45 0 0 1 .9 0v.9a.45.45 0 0 1-.9 0v-.9Z"
      fill={COLORS.CORE.WHITE}
    />
    <mask id="b" fill={COLORS.CORE.WHITE}>
      <rect x="1" y="7.3" width="18" height="5.4" rx=".5" />
    </mask>
    <rect
      x="1"
      y="7.3"
      width="18"
      height="5.4"
      rx=".5"
      stroke={COLORS.CORE.WHITE}
      strokeWidth="3"
      mask="url(#b)"
    />
    <path
      d="M15.4 9.55a.45.45 0 0 1 .9 0v.9a.45.45 0 0 1-.9 0v-.9ZM7.3 9.55a.45.45 0 1 1 .9 0v.9a.45.45 0 0 1-.9 0v-.9ZM3.7 9.55a.45.45 0 1 1 .9 0v.9a.45.45 0 0 1-.9 0v-.9ZM5.5 9.55a.45.45 0 1 1 .9 0v.9a.45.45 0 0 1-.9 0v-.9Z"
      fill={COLORS.CORE.WHITE}
    />
    <mask id="c" fill={COLORS.CORE.WHITE}>
      <rect x="1" y="1" width="18" height="5.4" rx=".5" />
    </mask>
    <rect
      x="1"
      y="1"
      width="18"
      height="5.4"
      rx=".5"
      stroke={COLORS.CORE.WHITE}
      strokeWidth="3"
      mask="url(#c)"
    />
    <path
      d="M15.4 3.25a.45.45 0 0 1 .9 0v.9a.45.45 0 0 1-.9 0v-.9ZM7.3 3.25a.45.45 0 1 1 .9 0v.9a.45.45 0 1 1-.9 0v-.9ZM3.7 3.25a.45.45 0 1 1 .9 0v.9a.45.45 0 1 1-.9 0v-.9ZM5.5 3.25a.45.45 0 1 1 .9 0v.9a.45.45 0 1 1-.9 0v-.9Z"
      fill={COLORS.CORE.WHITE}
    />
  </svg>
);

export default ClustersIcon;

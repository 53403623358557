import FileSaver from 'file-saver';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
// eslint-disable-next-line
import { strings } from '~utils/strings';
import timezone from 'dayjs/plugin/timezone';
import {
  CriticalThreshold,
  HighThreshold,
  NoneThreshold,
  LowThreshold,
  MediumThreshold,
  SHOW_DARK_FEATURES_ENDPOINTS,
} from './constants';
import { getCurrentTimezone } from './global.helpers.react';

dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);
dayjs.extend(timezone);

/* eslint-disable */

// Note(jd): Helper function c/o https://davidwalsh.name/query-string-javascript Thanks David!
export function getUrlParameter(name) {
  const bracketEscapedName = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
  const regex = new RegExp(`[\\?&]${bracketEscapedName}=([^&#]*)`);
  const results = regex.exec(window.location.search);
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

export const createCSVData = async (data, columnFields) => {
  return data.map((addon) => {
    const newObj = {};
    Object.keys(columnFields).forEach((column) => (newObj[columnFields[column]] = addon[column]));
    return newObj;
  });
};

export async function convertJsonToCsv(data, fields) {
  const Papaparse = await import('papaparse');
  if (fields) {
    return Papaparse.unparse({
      fields,
      data,
    });
  }
  return Papaparse.unparse(data);
}

export function downloadCsv(csvData, fileName) {
  const blob = new Blob([csvData], { type: 'application/csv;charset=UTF-8' });
  FileSaver.saveAs(blob, `${fileName}.csv`);
}

export function fixID(name) {
  let val = name || '';
  val = val.replace(/\W+/g, '-').replace(/_/g, '-');
  val = val.toLowerCase();
  return val;
}

export function getActionItemsQuery(
  category,
  severity,
  namespace,
  reportType,
  title,
  container,
  resourceName,
  cluster,
) {
  const q = {
    Fixed: 'false',
    Resolution: 'None',
    sortBy: 'Severity',
    sortDesc: 'true',
  };
  if (cluster) {
    q.Cluster = cluster;
  }
  if (category) {
    q.Category = category;
  }
  if (severity) {
    q.Severity = severity;
  }
  if (namespace) {
    q.ResourceNamespace = namespace;
  }
  if (reportType) {
    q.ReportType = reportType;
  }
  if (title) {
    q.Title = title;
  }
  if (container) {
    q.ContainerName = container;
  }
  if (resourceName) {
    q.ResourceName = resourceName;
  }
  q.redirect = 'true';
  return q;
}

export function formatCost(cost, showPositive) {
  if (cost === undefined) {
    return strings.general.na;
  }
  let val = Math.round(cost * 100) / 100;
  if (val < 0) {
    val = `-$${Math.abs(val)}`;
  } else {
    val = `${showPositive ? '+' : ''}$${val}`;
  }
  if (val.match(/\.\d$/)) {
    val += '0';
  }
  return `${val}/${strings.general.month}`;
}

export function truncateString(str, maxLength) {
  return str.length > maxLength
    ? `${str.substr(0, maxLength - 1)}${strings.punctuation.ellipsis}`
    : str;
}

export function randomAvatarColor(string, colors) {
  const charCodes = string
    .split('') // => ["A", "A"]
    .map((char) => char.charCodeAt(0)) // => [65, 65]
    .join(''); // => "6565"
  return colors[charCodes % colors.length];
}

export const sortAscending = (a, b) => {
  a = a.toLowerCase();
  b = b.toLowerCase();
  if (a > b) {
    return 1;
  }
  if (a < b) {
    return -1;
  }
  return 0;
};

export const sortAscendingWithKey = (key) => (a, b) => {
  if (a[key] < b[key]) {
    return -1;
  }
  if (a[key] > b[key]) {
    return 1;
  }
  return 0;
};

export const sortDescending = (a, b) => {
  a = a?.toLowerCase();
  b = b?.toLowerCase();
  if (a < b) {
    return 1;
  }
  if (a > b) {
    return -1;
  }
  return 0;
};

export const numericalSortDescending = (a, b) => (a < b ? 1 : -1);
export const numericalSortAscending = (a, b) => (a > b ? 1 : -1);

export const getGrade = (score) => {
  let grade = 'N/A';
  if (score === null || score === undefined) {
    grade = strings.general.month;
  } else if (score >= 97) {
    grade = 'A+';
  } else if (score >= 93) {
    grade = 'A';
  } else if (score >= 90) {
    grade = 'A-';
  } else if (score >= 87) {
    grade = 'B+';
  } else if (score >= 83) {
    grade = 'B';
  } else if (score >= 80) {
    grade = 'B-';
  } else if (score >= 77) {
    grade = 'C+';
  } else if (score >= 73) {
    grade = 'C';
  } else if (score >= 70) {
    grade = 'C-';
  } else if (score >= 67) {
    grade = 'D+';
  } else if (score >= 63) {
    grade = 'D';
  } else if (score >= 60) {
    grade = 'D-';
  } else {
    grade = 'F';
  }
  return grade;
};

export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const getSeverityLabel = (severity) => {
  let severityLabel;
  if (severity >= CriticalThreshold) {
    severityLabel = strings.severities.critical;
  } else if (severity >= HighThreshold) {
    severityLabel = strings.severities.high;
  } else if (severity >= MediumThreshold) {
    severityLabel = strings.severities.medium;
  } else if (severity > LowThreshold) {
    severityLabel = strings.severities.low;
  } else if (severity === NoneThreshold) {
    severityLabel = strings.severities.none;
  }
  return severityLabel;
};

const formatClipPathId = (splittedId, randomNumber) =>
  `${splittedId[0]}-${randomNumber}-${splittedId.slice(2).join('-')}`;

const generateRandomNumber = () => Math.floor(Math.random() * Date.now()) + 1;

const transformClipPathId = (id) => formatClipPathId(id.split('-'), generateRandomNumber());

// this function is used to pass the axe: duplicated-id for the c3 charts.
export const updateClipPathIds = (bindto) => {
  const elem = document.getElementById(bindto.slice(1));
  if (!elem) return;
  const clipPaths = elem
    .querySelector('svg')
    .querySelector('defs')
    .getElementsByTagName('clipPath');
  if (clipPaths?.length) {
    // eslint-disable-next-line no-restricted-syntax
    for (const clipPath of clipPaths) {
      clipPath.setAttribute('id', transformClipPathId(clipPath.id));
    }
  }
};

export const setAriaAttribute = ({ elements, attribute, value }) => {
  if (elements?.length) {
    // eslint-disable-next-line no-restricted-syntax
    for (const element of elements) {
      element.setAttribute(attribute, value);
    }
  }
};

export const setAriaAttributes = (configs) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const config of configs) {
    const { elements, attribute, value } = config;
    setAriaAttribute({ elements, attribute, value });
  }
};

export const copyToClipboard = async (content) => {
  await navigator.clipboard.writeText(content);
};

export const dateFromToday = (date) => {
  const timeZone = getCurrentTimezone();
  return timeZone ? dayjs(date).tz(timeZone).fromNow() : dayjs(date).fromNow();
};

// eslint-disable-next-line max-len
export const enableDarkLaunchedFeatures = !SHOW_DARK_FEATURES_ENDPOINTS.some(
  (host) => window.location.host.indexOf(host) > -1,
);

export const compareURLSearchParams = (params1, params2) =>
  Array.from(params1.entries()).sort().toString() ===
  Array.from(params2.entries()).sort().toString();

import * as React from 'react';
import { SVGProps } from 'react';
import { COLORS } from '~utils/styling';
import { strings } from '~utils/strings';

const HomeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    height="20px"
    width="20px"
    {...props}
  >
    <path
      d="M3.6 10.5v5.35a1 1 0 0 0 1 1h3.23a.3.3 0 0 0 .29-.3v-2.17c0-.6.3-1.17.8-1.51v0c.6-.4 1.35-.4 1.95-.05l.07.04c.57.34.92.95.94 1.61l.06 2.1c0 .15.13.28.29.28h3.14a1 1 0 0 0 1-1V10.5M1 10.07l8.27-6.58a1 1 0 0 1 1.24 0L19 10.06"
      stroke={COLORS.CORE.WHITE}
      strokeWidth={1.5}
      strokeLinecap={strings.noTranslate.round}
    />
  </svg>
);

export default HomeIcon;

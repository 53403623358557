import Vue from 'vue';
import { VuePlugin } from 'vuera';
import { BootstrapVue, ToastPlugin } from 'bootstrap-vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import { sendRequestMixin as sendRequest } from './utils/request';
import { loggerMixin } from './utils/logger';
import { enableDarkLaunchedFeatures } from './utils/helpers';
import { IS_PLG_UPGRADE_MODAL_SHOW } from './utils/constants';
import 'core-js/stable';
import 'regenerator-runtime/runtime';

Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(ToastPlugin);
Vue.use(VuePlugin);

Vue.mixin(sendRequest);
Vue.mixin(loggerMixin);

if (enableDarkLaunchedFeatures) {
  localStorage.setItem('darkLaunchedFeatures', 'true');
}

const searchParams = new URLSearchParams(window.location.search);

let apiHostOverride = searchParams.get('api_host');
if (apiHostOverride) {
  if (apiHostOverride === 'prod') {
    apiHostOverride = 'https://insights.fairwinds.com';
  }
  if (apiHostOverride === 'reset') {
    window.localStorage.removeItem('api_host');
  } else {
    window.localStorage.setItem('api_host', apiHostOverride);
  }
}

localStorage.removeItem(IS_PLG_UPGRADE_MODAL_SHOW);

(async () => {
  window.app = new Vue({
    router,
    store,
    render: (h) => h(App),
    vuetify,
  }).$mount('#vue-body');
})();

import React, { SyntheticEvent, useEffect, useMemo, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { Alert, Button, Form, FormGroup } from 'react-bootstrap';

import { Breadcrumbs, Card } from '@fairwindsops/ui-components';

import { getCurrentUser } from '~utils/request';

import { ORGS } from '~reactComponents/NavigationReact/Navigation.config.react';

import { IStore, IRoute, IRouter, IUserQuotas, Organization, CurrentUser } from '~globalTypes';

import { UPDATE_MEMBERSHIPS, UPDATE_CURRENT_USER } from '~store/action.types';

import { ONRAMP_FREE_TIER_KEYS, handlePageChange } from '~reactHelpers';
import { fixID } from '~utils/helpers';
import { sendRequest, REDIRECT_DELAY } from '~utils/request';
import { strings } from '~utils/strings';
import logger from '~logger';

import './NewOrganization.react.scss';

type NewOrganizationProps = {
  route: IRoute;
  router: () => IRouter;
  store: () => IStore;
};

const NewOrganization = ({ route, router, store }: NewOrganizationProps) => {
  const [orgId, setOrgId] = useState<string>('');
  const [quotas, setQuotas] = useState<IUserQuotas | null>(null);
  const currentUser = store().getters.user;

  useEffect(() => {
    const getUserQuotas = async () => {
      try {
        const response = await sendRequest('GET', '/v0/user/quotas', {}, null);

        if (response) {
          setQuotas(response);
        }
      } catch (e) {
        if (e) {
          logger.logError('error_retrieving_user_quotas', e);
        }
      }
    };

    getUserQuotas();
  }, []);

  const selfHosted = useMemo(() => {
    const windowObj = window as any;
    return windowObj.insightsConfig && windowObj.insightsConfig.selfHosted;
  }, [window]);

  const tinyContainerClass = useMemo(
    () =>
      route.name === 'auth-new-organization'
        ? 'new-organization__container new-organization__auth-org'
        : 'pr-2rem new-organization__container',
    [route],
  );

  const isInvalidOrg = () => {
    if (!orgId) {
      toast.error(strings.newOrganization.organizationNameRequired);
      return true;
    }

    return false;
  };

  const updateMemberships = async () => {
    await store().dispatch(UPDATE_MEMBERSHIPS, null);
  };

  const goToDefaultOrgPage = (org: Organization) => {
    setTimeout(() => {
      router().push({
        name: 'default-org',
        params: { org: org.Name },
      });
    }, REDIRECT_DELAY);
  };

  const resetUserCallbackUrl = async (currentUser: CurrentUser) => {
    const data = {
      FirstName: currentUser.FirstName,
      LastName: currentUser.LastName,
      JobTitle: currentUser.JobTitle,
      CountryAlpha2: currentUser.Country.Alpha2,
      CallbackUrl: '',
    };
    try {
      const { Success } = await sendRequest('PUT', '/v0/user', { data }, null);
      if (Success) {
        const response = await getCurrentUser();
        store().dispatch(UPDATE_CURRENT_USER, response.user);
      }
    } catch (e) {
      logger.logError('error_adding_country_to_user', e);
    }
  };

  const handleOnRampUsers = async (org: Organization) => {
    if (!currentUser.CallbackUrl && !localStorage.getItem(ONRAMP_FREE_TIER_KEYS.callbackUrl)) {
      // not coming from OSS login
      return;
    }

    let callbackURL;
    if (currentUser.CallbackUrl) {
      callbackURL = currentUser.CallbackUrl;
      await resetUserCallbackUrl(currentUser);
    } else {
      callbackURL = localStorage.getItem(ONRAMP_FREE_TIER_KEYS.callbackUrl);
    }
    localStorage.removeItem(ONRAMP_FREE_TIER_KEYS.callbackUrl);
    localStorage.removeItem(ONRAMP_FREE_TIER_KEYS.source);

    // redirect browser to callbackUrl -> passing token, email, org
    const localOSSAuthServer = `${callbackURL}?code=${
      currentUser.AuthorizationCode
    }&user=${encodeURIComponent(currentUser.Email)}&organization=${org.Name}`;
    window.location.href = localOSSAuthServer; // redirect to the local server
  };

  const createOrg = async (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (isInvalidOrg()) {
      return;
    }

    let org = null;

    try {
      org = await sendRequest('POST', `/v0/organizations/${orgId}`, {}, null);
    } catch (e: any) {
      if (e) {
        logger.logError('error_creating_new_organization', e);
        toast.error(e.message);
      }
    }

    await updateMemberships();

    if (org) {
      await handleOnRampUsers(org);
      goToDefaultOrgPage(org);
    }
  };

  if (!quotas) {
    return <></>;
  }

  const breadcrumbsList = [
    {
      id: ORGS,
      label: strings.navigation.Organizations,
      href: `/orgs`,
    },
    {
      id: 'last',
      label: strings.newOrganization.newOrganization,
      href: ``,
      isActive: true,
    },
  ];

  return (
    <Card className={tinyContainerClass}>
      <Card.Body padded>
        {route.name !== 'auth-new-organization' && (
          <Breadcrumbs
            data={breadcrumbsList}
            onClick={(route: string) => {
              handlePageChange(router, route);
            }}
          />
        )}
        <h1 className="new-organization__title">{strings.newOrganization.newOrganization}</h1>
        {!currentUser.IsSuperAdmin && !currentUser.CanCreateOrg ? (
          <span>
            Thank you for your interest in Fairwinds Insights! If you would like to setup an evaluation account, please
            visit{' '}
            <a href="https://www.fairwinds.com/insights-getstarted" target="_blank">
              https://www.fairwinds.com/insights-getstarted
            </a>{' '}
            to book a setup call.
          </span>
        ) : quotas.canCreateFreeOrganization ? (
          <>
            <p>{strings.newOrganization.title}</p>
            <Form onSubmit={createOrg}>
              <fieldset>
                <FormGroup>
                  <Form.Label>{strings.newOrganization.organizationID}</Form.Label>
                  <Form.Control
                    onChange={(e) => setOrgId(fixID(e.target.value))}
                    placeholder={strings.newOrganization.name}
                    type="text"
                    value={orgId}
                  />
                </FormGroup>
                <Button type="submit" variant="primary" onClick={createOrg}>
                  {strings.newOrganization.createOrganization}
                </Button>
              </fieldset>
            </Form>
          </>
        ) : (
          <Alert variant="warning">
            <span>
              {selfHosted
                ? `${strings.newOrganization.selfHostedMessage} `
                : `${strings.newOrganization.freeTierMessage} `}
              {strings.newOrganization.please}{' '}
              <a href="https://www.fairwinds.com/insights">{strings.newOrganization.visitOurWebsite}</a>{' '}
              {strings.newOrganization.toLearnAbout}{' '}
              <a href={strings.noTranslate.mailToSales}>{strings.newOrganization.salesEmail}</a>.
            </span>
          </Alert>
        )}
      </Card.Body>
      <Toaster />
    </Card>
  );
};

export default NewOrganization;

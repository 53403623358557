import { strings } from '~utils/strings';

export const Labels = {
  clusterConfigureAlertMessage: strings.navigation.clusterConfigureAlertMessage,
  clusterConfigureAlertPosition: 'b-toaster-top-center',
  clusterConfigureAlertType: 'error',
  routerPushErrorMessage: 'Redirected when going',
} as const;

export type NavigateTo = {
  name: string;
  params: {
    org: string;
    cluster?: string;
  };
  query: {
    redirect: boolean;
    Cluster?: string;
    clusters?: string;
  };
};

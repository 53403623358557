import * as React from 'react';
import { SVGProps } from 'react';
import { COLORS } from '~utils/styling';

const AutomationIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M19 9.08h.15V2.15H12.2v2.82H9.72v3.37h-1.9V5.28H.84v6.94h6.97V9.44h1.91v5.84h2.48v2.58h6.95v-6.93H12.2v3.25h-1.37v-8.1h1.37v3H19zm-5.7-5.82h4.74v4.72h-4.73V3.26zM6.7 11.1H1.96V6.39H6.7v4.72zm6.6.93h4.74v4.72h-4.73v-4.72z"
      fill={COLORS.CORE.WHITE}
      stroke={COLORS.CORE.WHITE}
      strokeWidth={0.3}
    />
  </svg>
);

export default AutomationIcon;

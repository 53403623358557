import React from 'react';
import { Badge } from 'react-bootstrap';
import './Tag.react.scss';

type OptionType = {
  value: string;
  label: string;
};

type ITagProps = {
  children: string;
  hollow?: boolean;
  fontSize?: string;
  isGray?: boolean;
  isRemovable?: boolean;
  removeTag?: (option: OptionType) => void;
  option?: OptionType;
  variant?: string;
  tagClassNames?: string;
};

const Tag = ({
  children,
  hollow,
  fontSize,
  isGray,
  isRemovable,
  removeTag,
  option = { value: '', label: '' },
  variant,
  tagClassNames,
}: ITagProps): JSX.Element => {
  return (
    <Badge
      variant={variant ? variant : 'tag'}
      className={`custom--tag ${hollow ? 'hollow' : ''} ${isGray ? 'change-color' : ''} ${
        tagClassNames ? tagClassNames : ''
      }`}
      title={children}
      style={{
        fontSize: `${fontSize}`,
        fontFamily: isGray ? `IBM Plex Mono, monospace` : '',
      }}
    >
      {children}
      {isRemovable && children !== '...' && removeTag && (
        <span className="ml-2 mr-1 close-btn" onClick={() => removeTag(option)}>
          x
        </span>
      )}
    </Badge>
  );
};

export default Tag;

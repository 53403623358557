// eslint-disable-next-line
import { strings } from '@/utils/strings';

export default [
  {
    path: '/open-source',
    name: 'open-source',
    component: () => import(/* webpackChunkName: "legal" */ '@/views/legal/openSources.vue'),
    meta: { title: strings.metaTitle + strings.navigation.openSource },
  },
  {
    path: '/customer-agreement',
    name: 'customer-agreement',
    beforeEnter: () => {
      window.location.href = 'https://www.fairwinds.com/insights-terms-and-conditions';
    },
  },
];

import * as React from 'react';
import { SVGProps } from 'react';
import { COLORS } from '~utils/styling';

const CaretIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={14} height={9} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12.4.75 7 7.77 1.58.75H12.4zM1.28.35zM6.8 8.03l.2.15-.2-.15z"
      stroke={COLORS.CORE.WHITE}
      strokeWidth={1.5}
    />
  </svg>
);

export default CaretIcon;

import * as React from 'react';
import { SVGProps } from 'react';
import { COLORS } from '~utils/styling';

const ComplianceIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      stroke={COLORS.CORE.WHITE}
      strokeWidth={1.3}
      d="M13.27 1H4a1 1 0 0 0-1 1v16a1 1 0 0 0 1 1h12.18a1 1 0 0 0 1-1V4.86a.18.18 0 0 0-.05-.12M13.27 1v3.28c0 .28.23.5.5.5l3.36-.04M13.27 1l3.86 3.74"
    />
    <path
      fill={COLORS.CORE.WHITE}
      stroke={COLORS.CORE.WHITE}
      strokeWidth={0.3}
      d="M11 5.15H5.32a.47.47 0 1 0 0 .93H11a.47.47 0 0 0 0-.93Zm1.26 1.9H5.32a.47.47 0 1 0 0 .93h6.94a.47.47 0 0 0 0-.93ZM11 8.85H5.32a.47.47 0 1 0 0 .93H11a.47.47 0 0 0 0-.93ZM12.25 17l3.1-3.39a.45.45 0 0 0 .02-.59v-.01l-.05-.05a.45.45 0 0 0-.63.03l-2.78 3.04-1.26-1.28a.45.45 0 0 0-.67-.03l.1.1-.1-.1a.45.45 0 0 0 .03.67L11.6 17a.4.4 0 0 0 .15.1l.06-.13-.06.13c.05.03.11.04.17.04h.01c.12 0 .24-.06.32-.15Z"
    />
  </svg>
);

export default ComplianceIcon;

import axios from 'axios';

// https://github.com/axios/axios/issues/31
// Create intercepting get function which returns cached promise,
// hence multiple requests to the same URL will be resolved by
// a single promise.
function cachingGet(get) {
  const cache = new Map();

  return function cachedGet(url, ...args) {
    const key = url;
    if (cache.has(key)) {
      return cache.get(key);
    }
    const request = get(url, ...args);
    cache.set(key, request);
    return request;
  };
}

// TODO: cleanup all of this.
const instance = axios.create();
instance.cachingGet = cachingGet(instance.get);
export const REDIRECT_DELAY = 500;

let controller = null;
let requestsPaused = false;
if (window.AbortController) {
  controller = new AbortController();
}

export function abortAndPauseAllRequests() {
  if (!window.AbortController) return;
  // FIXME: this is a hack to make sure we don't get a response with set-cookie when logging out
  requestsPaused = true;
  setTimeout(() => {
    requestsPaused = false;
  }, 1000);
  controller.abort();
  controller = new AbortController();
}

export function unpauseAllRequests() {
  requestsPaused = false;
}

/**
 *
 * @param {string}  method  HTTP request method
 * @param {string}  url     The URL to send a request to
 * @param {Object}  [options] The request options
 * @param {any}     bvToast Toast instance
 * @returns {Promise<AxiosResponse<any>>|*}
 */
// eslint-disable-next-line default-param-last
export function sendRequest(method, url, options = {}, bvToast) {
  if (requestsPaused) {
    return Promise.reject(new Error('Requests have been paused'));
  }
  if (options?.blockAllOtherRequests) {
    // This is a bit of a hack for the `/logout` request. It prevents
    // other requests from firing and resetting the cookie.
    abortAndPauseAllRequests();
  }
  let host = url.includes('https://') ? '' : `${window.location.protocol}//${window.location.host}`;
  let hostOverride = window.localStorage.getItem('api_host');

  if (hostOverride) {
    // a trailing slash breaks the fetching
    if (hostOverride.endsWith('/')) {
      hostOverride = hostOverride.slice(0, hostOverride.length - 1);
    }
    host = hostOverride;
  }

  if (options?.showSuccessAlert === undefined) {
    options.showSuccessAlert = method !== 'GET';
  }
  if (options?.showErrorAlert === undefined) {
    options.showErrorAlert = true;
  }

  const callback = (res) => {
    if (options.showSuccessAlert) {
      bvToast?.toast('Success!', {
        toaster: 'b-toaster-top-center',
        noCloseButton: true,
        toastClass: 'all-toasts success-toast',
      });
    }
    return res.data;
  };
  const callbackWithHeaders = (res) => {
    if (options.showSuccessAlert) {
      bvToast?.toast('Success!', {
        toaster: 'b-toaster-top-center',
        noCloseButton: true,
        toastClass: 'all-toasts success-toast',
      });
    }
    return { data: res.data, headers: res.headers };
  };
  const errorCallback = (err) => {
    let errorMessage = 'Unknown error';
    if (err.response && err.response.data) {
      const header = (err.response.headers && err.response.headers['content-type']) || '';
      if (err.response.status === 403) {
        errorMessage = err.response.data;
      } else if (header.indexOf('text/plain') === 0) {
        errorMessage = err.response.data;
      } else if (err.response.data.Error) {
        errorMessage = err.response.data.Error;
      }
    }

    if (options.showErrorAlert) {
      bvToast?.toast(errorMessage, {
        toaster: 'b-toaster-top-center',
        noCloseButton: true,
        toastClass: 'all-toasts error-toast',
      });
    }
    if (err.response && err.response.status === 401) {
      window.location.replace(window.location.origin);
    }
    const rejection = new Error(errorMessage);
    rejection.status = err.response && err.response.status;
    return Promise.reject(rejection);
  };
  const fullUrl = host + url;
  const withCredentials = fullUrl.includes('insights.fairwinds.com');
  if (method === 'GET' && options?.cache) {
    return instance
      .cachingGet(fullUrl, {
        withCredentials,
      })
      .then(callback, errorCallback);
  }
  return axios({
    method,
    headers: options?.headers || {},
    url: fullUrl,
    data: options.data || null,
    signal: controller?.signal,
    // this is only needed for CORS.
    // when in dev, we are on the same domain so CORS is no longer an issue
    withCredentials,
  })
    .then((res) => {
      if (options?.blockAllOtherRequests) {
        unpauseAllRequests();
      }
      return res;
    })
    .then(options?.returnHeaders ? callbackWithHeaders : callback, errorCallback);
}

export const sendRequestMixin = {
  methods: {
    sendRequest(method, url, options) {
      return sendRequest(method, url, options, this.$bvToast);
    },
  },
};

export const getCurrentUser = async () => sendRequest('GET', '/v0/current-user');

import * as React from 'react';
import { SVGProps } from 'react';
import { COLORS } from '~utils/styling';

const CollapseIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="m12.25 15.41-7.02-5.4 7.02-5.41v10.8zm.4-11.12zM4.97 9.8l-.15.2.15-.2z"
      stroke={COLORS.CORE.WHITE}
      strokeWidth={1.5}
    />
    <rect
      x={0.75}
      y={0.75}
      width={18.5}
      height={18.5}
      rx={1.25}
      stroke={COLORS.CORE.WHITE}
      strokeWidth={1.5}
    />
  </svg>
);

export default CollapseIcon;

import * as React from 'react';
import { COLORS } from '~utils/styling';

type FairwindsMinimalIconProps = {
  height?: string;
  width?: string;
  className?: string;
  link?: string;
};

const FairwindsMinimalIcon = ({
  height,
  width,
  className,
  link,
  ...props
}: FairwindsMinimalIconProps) => (
  <a href={link} aria-label="Fairwinds Brand Icon">
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 35"
      width={width}
      height={height}
      className={className}
      {...props}
    >
      <path
        d="M29.58 10.1H0V.91h32.33v6.44a2.75 2.75 0 0 1-2.75 2.75zM17.83 23.08H0v-9.2h20.57v6.44a2.75 2.75 0 0 1-2.74 2.76zM6.4 36.06H0v-9.2h9.14v6.43a2.75 2.75 0 0 1-2.74 2.77z"
        fill={COLORS.CORE.WHITE}
      />
    </svg>
  </a>
);

export default FairwindsMinimalIcon;

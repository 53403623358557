import Vue from 'vue';
import VueMeta from 'vue-meta';
import VueRouter from 'vue-router';
import store from '@/store';
import {
  UPDATE_TIERS,
  UPDATE_MEMBERSHIPS,
  UPDATE_ORG,
  UPDATE_CLUSTER,
  SET_ORG_REPOSITORIES,
  SET_REPOSITORY,
  SET_APP_READY,
  TOGGLE_TOAST,
} from '@/store/action.types';
import { getCurrentUser, sendRequest } from '@/utils/request';
import logger from '@/utils/logger';
// eslint-disable-next-line
import { strings } from '@/utils/strings';
import { vexillaShould } from '@/utils/feature-flags';
import { DemoOrgName, AgreementVersion, IS_PLG_UPGRADE_MODAL_SHOW } from '@/utils/constants';

import legalRoutes from './legal';
import clusterRoutes from './cluster';
import authRoutes from './auth';
import organizationRoutes from './organization';

/* eslint-disable */

const admin = () => import(/* webpackChunkName: "admin" */ '@/views/admin/index.vue');
const contactUs = () => import(/* webpackChunkName: "contactUs" */ '@/views/contact-us/index.vue');
const errorPage = () => import(/* webpackChunkName: "notFound" */ '@/views/error/error.vue');
const unsubscribe = () =>
  import(/* webpackChunkName: "unsubscribe" */ '@/views/unsubscribe/unsubscribe.vue');
const publicRepository = () =>
  import(
    /* webpackChunkName: "publicRepository" */ '@/views/repositories/public-repository/index.vue'
  );
const emptyDatadog = () =>
  import(/* webpackChunkName: "empty" */ '@/views/cluster/datadog-action-items/Empty/empty.vue');
const userInfo = () => import(/* webpackChunkName: "organizations" */ '@/views/userInfo/index.vue');
const profile = () =>
  import(/* webpackChunkName: "organizations" */ '@/views/userInfo/profile/profile.vue');
const region = () =>
  import(/* webpackChunkName: "organizations" */ '@/views/userInfo/region/index.vue');
const password = () =>
  import(
    /* webpackChunkName: "organizations" */ '@/views/userInfo/passwordReset/passwordReset.vue'
  );
const emailNotifications = () =>
  import(
    /* webpackChunkName: "organizations" */ '@/views/userInfo/notifications/notifications.vue'
  );

Vue.use(VueRouter);
Vue.use(VueMeta);

const superadminRoutes = [
  {
    path: '/admin',
    name: 'admin',
    props: true,
    component: admin,
    meta: { title: strings.metaTitle + strings.navigation.Admin },
  },
];

const baseRoutes = [
  {
    path: '/',
    name: 'home',
    redirect: { name: 'login' },
  },
  {
    path: '/contact-us',
    name: 'contact-us',
    component: contactUs,
    meta: { title: strings.metaTitle + strings.contactUs.title },
  },
  {
    path: '/unsubscribe',
    name: 'unsubscribe',
    component: unsubscribe,
    meta: { title: strings.metaTitle + strings.Unsubscribe },
  },
  {
    path: '/error',
    name: 'error',
    component: errorPage,
    meta: { title: strings.metaTitle + strings.Error },
  },
  {
    path: '/gh/:ghOrg/:ghRepo/:targetBranch?/:hash?',
    name: strings.noTranslate.publicRepo,
    component: publicRepository,
    props: true,
  },
  {
    path: '/empty-datadog',
    name: 'empty-datadog',
    component: emptyDatadog,
    props: true,
  },
  {
    path: '/user-settings',
    component: userInfo,
    props: true,
    children: [
      {
        path: '',
        name: 'default-profile',
        redirect: { name: 'profile' },
      },
      {
        path: 'details/',
        name: 'profile',
        component: profile,
        meta: {
          title: strings.metaTitle + strings.navigation.Profile + strings.navigation.Settings,
        },
      },
      {
        path: 'region/',
        name: 'region',
        component: region,
        meta: { title: 'Fairwinds Insights | Region Settings' },
      },
      {
        path: 'email-notifications/',
        name: 'email-notifications',
        component: emailNotifications,
        meta: { title: strings.metaTitle + strings.Email + strings.notificationSettings },
      },
      {
        path: 'reset-password/',
        name: 'reset-password',
        component: password,
        meta: { title: strings.metaTitle + strings.resetPassword },
      },
      {
        path: 'password/',
        name: 'password',
        meta: {
          title: strings.metaTitle + strings.navigation.Password + strings.navigation.Settings,
        },
        beforeEnter: async (to, from, next) => {
          let token = '';
          try {
            const results = await sendRequest('PUT', '/v0/password-reset', {
              showSuccessAlert: false,
            });
            token = results.Token;
          } catch (e) {
            const currentUser = store.getters.user;
            next({
              name: 'login',
              query: {
                email: currentUser.Email || '',
                forceLogin: true,
                redir: '/user-settings/password/',
              },
            });
            return;
          }
          next({
            path: '/user-settings/reset-password/',
            query: { token },
          });
        },
      },
    ],
  },
  {
    path: '*',
    name: strings.routes.notFound,
    component: errorPage,
  },
];

// ADD_ORG_PARAM_ROUTES, isPublic
const routes = [].concat(
  baseRoutes,
  legalRoutes,
  authRoutes,
  clusterRoutes,
  organizationRoutes,
  superadminRoutes,
);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
  routes,
});

const AUTH_CHILDREN_ROUTES = authRoutes.reduce(
  (acc, r) => (r.children ? acc.concat(r.children) : acc),
  [],
);

const BASE_CHILDREN_ROUTE_NAMES = baseRoutes.reduce(
  (acc, r) => (r.children ? acc.concat(r.children.map((childRoute) => childRoute.name)) : acc),
  [],
);

// belongs to the org routes but doesn't have the org prefix.
const SPECIAL_ROUTES = ['new-organization'];

const AUTH_ROUTE_NAMES = [null, ...authRoutes.concat(AUTH_CHILDREN_ROUTES).map((r) => r.name)];
const PUBLIC_ROUTE_NAMES = baseRoutes
  .concat(legalRoutes)
  .map((r) => r.name)
  .concat(AUTH_ROUTE_NAMES);
const SUPERADMIN_ROUTE_NAMES = superadminRoutes.map((r) => r.name);

router.beforeEach(vexillaShould(strings.featureFlags.fixLogin) ? newBeforeEach : oldBeforeEach);

router.afterEach(async (to, from) => {
  const PREVENT_ORG_UPDATE_ROUTES = [
    'email-notifications',
    'profile',
    'default-profile',
    'reset-password',
    'password',
    strings.noTranslate.publicRepo,
  ];
  // Forcing a component refresh when changing org from dashboard and when resolving admission action items
  if (
    (to.name === 'org-dashboard' && from.name === to.name && to.params.org !== from.params.org) ||
    (to.name === 'clusteradmission-controller' &&
      from.name === to.name &&
      (to?.params?.operation === 'resolution' || to?.params?.operation === strings.snooze))
  ) {
    router.go();
  }
  if (!to.params.org && !PREVENT_ORG_UPDATE_ROUTES.includes(to.name)) {
    await store.dispatch(UPDATE_ORG, to.params.org);
  }

  if (to.name === 'costs-settings' && to.query.Cluster) {
    store.dispatch(UPDATE_CLUSTER, to.query.Cluster);
  } else if (!to.params.cluster) {
    store.dispatch(UPDATE_CLUSTER, to.params.cluster);
  }
  store.commit(SET_APP_READY, true);
});

router.afterEach((to, from) => {
  const details = { ...to.params, path: to.path, name: to.name };
  if (to.params && to.params.linkID) {
    logger.logEvent(`click:${from.name}:${to.params.linkID}`, details);
  }
  if (
    from.params.cluster &&
    !to.path.includes('clusters/') &&
    to.name !== 'clusters' &&
    !to.name.includes('cumulative-costs') &&
    !to.name.includes('costs-settings') &&
    !to.name.includes('react-vulnerabilities')
  ) {
    store.dispatch(UPDATE_CLUSTER, null);
  }
  logger.logEvent(`view_page:${to.name}`);
});

router.afterEach((to) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    document.title = to.meta.title || 'Fairwinds Insights';
  });
});

export const ADD_ORG_PARAM_ROUTES = [
  ...AUTH_ROUTE_NAMES,
  ...BASE_CHILDREN_ROUTE_NAMES,
  ...PUBLIC_ROUTE_NAMES,
  ...SUPERADMIN_ROUTE_NAMES,
  ...SPECIAL_ROUTES,
].filter((route) => route);

// AgreementVersion and SUPERADMIN_ROUTE_NAMES are global at the moment
// For testing, it might be good to pass them into this function to keep it pure
function getRedirectRouteName(isPublic, currentUser, to) {
  if (isPublic && currentUser && to.name === 'login' && !to.query.forceLogin) {
    return 'orgs';
  } else if (
    (!currentUser.FirstName || !currentUser.LastName) &&
    to.name !== 'check-confirmation'
  ) {
    return 'profile-info';
  } else if (!currentUser.Confirmed) {
    return 'check-confirmation';
  } else if (SUPERADMIN_ROUTE_NAMES.includes(to.name) && !currentUser.IsSuperAdmin) {
    return strings.routes.notFound;
  } else if (currentUser.AgreementVersion < AgreementVersion) {
    return strings.routes.signTermsOfService;
  }

  return '';
}

async function newBeforeEach(to, from, next) {
  const isPublic = PUBLIC_ROUTE_NAMES.includes(to.name) || to.params.org === DemoOrgName;
  let currentUser = store.getters.user;

  // GUARDS: SIMPLE: START ----------------------------------------------------

  if (to.name === 'logout') {
    next();
    return;
  }

  // GUARDS: SIMPLE: END ------------------------------------------------------

  if (
    (AUTH_ROUTE_NAMES.includes(to.name) ||
      AUTH_ROUTE_NAMES.includes(from.name) ||
      from.name === null) &&
    !currentUser
  ) {
    try {
      const login = await getCurrentUser();
      currentUser = login.user;
      await store.dispatch(UPDATE_MEMBERSHIPS, login);
    } catch (e) {
      this?.logEvent('error_getting_current_user_router', e);
    }
  }

  // GUARDS: COMPLEX: START ---------------------------------------------------

  if (!currentUser) {
    if (
      !(
        AUTH_ROUTE_NAMES.includes(to.name) ||
        AUTH_ROUTE_NAMES.includes(from.name) ||
        from.name === null
      )
    ) {
      localStorage.setItem(strings.noTranslate.redirPath, to.fullPath);
      next({ name: 'login' });
    } else {
      next();
    }

    return;
  }

  // GUARDS: COMPLEX: END -----------------------------------------------------

  if (!store.getters.tiers) {
    try {
      await store.dispatch(UPDATE_TIERS);
    } catch (e) {
      logger.logEvent('error_getting_tiers_router', e);
    }
  }

  let redir = getRedirectRouteName(isPublic, currentUser, to);

  if (!redir) {
    const rawRedirPath = localStorage.getItem(strings.noTranslate.redirPath) || '';
    if (rawRedirPath) {
      localStorage.removeItem(strings.noTranslate.redirPath);
      next({ fullPath: rawRedirPath });
    }
  } else if (redir !== to.name) {
    next({ name: redir });
  }

  const { memberships } = store.getters;
  const org = localStorage.getItem(strings.localStorage.organization);
  const findMatch = memberships?.some((membership) => membership.Organization === org);

  if (window !== window.top && to.name !== 'empty-datadog' && to.name !== 'datadog-action-items') {
    if (!currentUser) {
      next();
    } else if (store.getters.organization && store.getters.cluster) {
      next({ name: 'datadog-action-items' });
    } else {
      next({ name: 'empty-datadog' });
    }
    return;
  }

  if (to.name === 'orgs') {
    if (findMatch) {
      next({ name: 'default-org', params: { org } });
      return;
    }
    localStorage.removeItem(strings.localStorage.organization);
    next();
    return;
  }

  try {
    if (
      to.params.org &&
      (!store.getters.organization || store.getters.organization.Name !== to.params.org)
    ) {
      await store.dispatch(UPDATE_ORG, to.params.org);
    }
    if (
      to.params.cluster &&
      (!store.getters.cluster || store.getters.cluster.Name !== to.params.cluster)
    ) {
      await store.dispatch(UPDATE_CLUSTER, to.params.cluster);
    }
    const isFreeTierDiabled = localStorage.getItem('isFreeTierDisabled');
    if (isFreeTierDiabled) {
      const { organization } = store.getters;
      if (
        organization &&
        !organization.Tier &&
        to.path.includes(`/orgs/${organization.Name}`) &&
        to.path !== `/orgs/${organization.Name}/trial-expired`
      ) {
        next({ path: `/orgs/${organization.Name}/trial-expired` });
        return;
      }
    }
  } catch (e) {
    if (e.status === 404) {
      next();
      return;
    }
    throw e;
  }
  if (
    to.params.cluster &&
    to.name !== 'clusterreport-hub' &&
    to.name !== 'clusterhub-install' &&
    !to.name.includes('react-vulnerabilities') &&
    !to.name.startsWith('hub-report') &&
    store.getters.clusterSummary &&
    store.getters.clusterSummary.SetupNeeded
  ) {
    const error = {
      message: strings.navigation.clusterConfigureAlertMessage,
      position: 'b-toaster-top-center',
      type: 'error',
    };
    store.commit(TOGGLE_TOAST, error);
    next({
      name: 'clusterreport-hub',
      query: { setup: true },
      params: { org: to.params.org, cluster: to.params.cluster },
    });
    return;
  }

  // maintain the setup query params even when navigating through the installation pages.
  if (
    from.query.setup &&
    !to.query.setup &&
    store.getters.clusterSummary &&
    store.getters.clusterSummary.SetupNeeded
  ) {
    next({ path: to.path, query: { setup: from.query.setup } });
  }

  if (store.getters.organization?.Tier === 0 && store.getters.organization?.isOrgsRedirected) {
    localStorage.removeItem(strings.localStorage.organization);
    localStorage.setItem(IS_PLG_UPGRADE_MODAL_SHOW, 'true');
    next({ name: 'orgs' });
    return;
  }
  next();
}

async function oldBeforeEach(to, from, next) {
  const isPublic = PUBLIC_ROUTE_NAMES.includes(to.name) || to.params.org === DemoOrgName;
  let currentUser = store.getters.user;

  if (
    (AUTH_ROUTE_NAMES.includes(to.name) ||
      AUTH_ROUTE_NAMES.includes(from.name) ||
      from.name === null) &&
    !currentUser
  ) {
    try {
      const login = await getCurrentUser();
      currentUser = login.user;
      await store.dispatch(UPDATE_MEMBERSHIPS, login);
    } catch (e) {
      this?.logEvent('error_getting_current_user_router', e);
    }
  }

  if (!store.getters.tiers) {
    try {
      await store.dispatch(UPDATE_TIERS);
    } catch (e) {
      logger.logEvent('error_getting_tiers_router', e);
    }
  }

  let redir = '';
  if (isPublic && currentUser && to.name === 'login' && !to.query.forceLogin) {
    next({ name: 'orgs' });
  } else if (isPublic) {
    redir = '';
  } else if (!currentUser) {
    redir = 'login';
  } else if (!currentUser.FirstName || !currentUser.LastName) {
    redir = 'profile-info';
  } else if (!currentUser.Confirmed) {
    redir = 'check-confirmation';
  } else if (SUPERADMIN_ROUTE_NAMES.includes(to.name) && !currentUser.IsSuperAdmin) {
    redir = strings.routes.notFound;
  } else if (currentUser.AgreementVersion < AgreementVersion) {
    redir = strings.routes.signTermsOfService;
  }

  if (redir && redir !== to.name) {
    next({ name: redir, query: { redir: to.fullPath } });
  } else {
    const { memberships } = store.getters;
    const org = localStorage.getItem(strings.localStorage.organization);
    const findMatch = memberships?.some((membership) => membership.Organization === org);

    if (
      window !== window.top &&
      to.name !== 'empty-datadog' &&
      to.name !== 'datadog-action-items'
    ) {
      if (!currentUser) {
        next();
      } else if (store.getters.organization && store.getters.cluster) {
        next({ name: 'datadog-action-items' });
      } else {
        next({ name: 'empty-datadog' });
      }
      return;
    }

    if (to.name === 'orgs') {
      if (findMatch) {
        next({ name: 'default-org', params: { org } });
        return;
      }
      localStorage.removeItem(strings.localStorage.organization);
      next();
      return;
    }

    try {
      if (
        to.params.org &&
        (!store.getters.organization || store.getters.organization.Name !== to.params.org)
      ) {
        await store.dispatch(UPDATE_ORG, to.params.org);
      }
      if (
        to.params.cluster &&
        (!store.getters.cluster || store.getters.cluster.Name !== to.params.cluster)
      ) {
        await store.dispatch(UPDATE_CLUSTER, to.params.cluster);
      }
      if (
        to.params.repo &&
        (!store.getters.repository || store.getters.repository.Name !== to.params.repo)
      ) {
        const repositories = await sendRequest(
          'GET',
          `/v0/organizations/${to.params.org}/ci/repositories`,
        );
        store.commit(SET_ORG_REPOSITORIES, repositories);
        const selectedRepository = repositories?.find(
          (repository) => repository.Name === to.params.repo,
        );
        store.commit(SET_REPOSITORY, selectedRepository);
      }
      const isFreeTierDiabled = localStorage.getItem('isFreeTierDisabled');
      if (isFreeTierDiabled) {
        const { organization } = store.getters;
        if (
          organization &&
          !organization.Tier &&
          to.path.includes(`/orgs/${organization.Name}`) &&
          to.path !== `/orgs/${organization.Name}/trial-expired`
        ) {
          next({ path: `/orgs/${organization.Name}/trial-expired` });
          return;
        }
      }
    } catch (e) {
      if (e.status === 404) {
        next();
        return;
      }
      throw e;
    }
    if (
      to.params.cluster &&
      to.name !== 'clusterreport-hub' &&
      to.name !== 'clusterhub-install' &&
      !to.name.includes('react-vulnerabilities') &&
      !to.name.startsWith('hub-report') &&
      store.getters.clusterSummary &&
      store.getters.clusterSummary.SetupNeeded
    ) {
      const error = {
        message: strings.navigation.clusterConfigureAlertMessage,
        position: 'b-toaster-top-center',
        type: 'error',
      };
      store.commit(TOGGLE_TOAST, error);
      next({
        name: 'clusterreport-hub',
        query: { setup: true },
        params: { org: to.params.org, cluster: to.params.cluster },
      });
      return;
    }

    // maintain the setup query params even when navigating through the installation pages.
    if (
      from.query.setup &&
      !to.query.setup &&
      store.getters.clusterSummary &&
      store.getters.clusterSummary.SetupNeeded
    ) {
      next({ path: to.path, query: { setup: from.query.setup } });
    }

    if (store.getters.organization?.Tier === 0 && store.getters.organization?.isOrgsRedirected) {
      localStorage.removeItem(strings.localStorage.organization);
      localStorage.setItem(IS_PLG_UPGRADE_MODAL_SHOW, 'true');
      next({ name: 'orgs' });
      return;
    }

    next();
  }
}

export default router;

import * as React from 'react';
import { SVGProps } from 'react';
import { COLORS } from '~utils/styling';

const SelectArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1rem"
    height="0.75rem"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 14 9"
    {...props}
  >
    <path
      d="m12.41.75-5.4 7.02L1.6.75h10.8ZM1.3.35Zm5.5 7.68"
      stroke={COLORS.CORE.PRIMARY}
      strokeWidth={1.5}
    />
  </svg>
);

export default SelectArrow;

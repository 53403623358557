import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import { VDialog } from 'vuetify/lib';
import CaretDownIcon from '@/components/Icons/caret-down.vue';

// Resolve conflict with VDialog of VueModalJs
Vue.component('VuetifyDialog', VDialog);

Vue.use(Vuetify);
export default new Vuetify({
  icons: {
    iconfont: 'fa',
    values: {
      'caret-down': {
        component: CaretDownIcon,
      },
    },
  },
  theme: {
    disable: true,
  },
});

import * as React from 'react';
import { SVGProps } from 'react';
import { COLORS } from '~utils/styling';

const SettingsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M15.2 13.14a.65.65 0 0 1 .67-.3l1.6.3a1.08 1.08 0 0 0 1.27-.89l.24-1.35a1.08 1.08 0 0 0-.88-1.27l-1.61-.28a.65.65 0 0 1-.53-.52.67.67 0 0 1 .22-.65l1.46-1.25a1.08 1.08 0 0 0 .18-1.45l-.88-1.26a1.08 1.08 0 0 0-1.43-.33l-1.68.94a.68.68 0 0 1-.94-.32.65.65 0 0 1-.04-.38l.28-1.61a1.08 1.08 0 0 0-.88-1.27l-1.35-.23a1.08 1.08 0 0 0-1.26.88l-.29 1.61a.65.65 0 0 1-.52.53.67.67 0 0 1-.65-.22L6.93 2.36a1.08 1.08 0 0 0-1.45-.18l-1.26.88a1.08 1.08 0 0 0-.33 1.43l.94 1.68a.68.68 0 0 1-.32.94.65.65 0 0 1-.38.04l-1.6-.28a1.08 1.08 0 0 0-1.27.88L1.02 9.1a1.08 1.08 0 0 0 .88 1.26l1.61.29a.65.65 0 0 1 .53.52.67.67 0 0 1-.22.65l-1.46 1.25a1.08 1.08 0 0 0-.18 1.45l.88 1.26a1.08 1.08 0 0 0 1.43.33l1.68-.94a.68.68 0 0 1 .94.32c.05.12.07.25.04.38l-.28 1.6a1.08 1.08 0 0 0 .88 1.27l1.35.24a1.08 1.08 0 0 0 1.27-.88l.28-1.61a.65.65 0 0 1 .52-.53.67.67 0 0 1 .65.22l1.25 1.46a1.08 1.08 0 0 0 1.45.18l1.26-.88a1.08 1.08 0 0 0 .33-1.43l-.94-1.68a.67.67 0 0 1 .02-.68l-.18-.12-.19-.1a1.1 1.1 0 0 0-.03 1.12l.94 1.68a.65.65 0 0 1-.2.87l-1.26.88a.65.65 0 0 1-.86-.12l-1.26-1.46a1.11 1.11 0 0 0-1.62-.06c-.15.15-.25.35-.3.56l-.3 1.62a.66.66 0 0 1-.76.53l-1.34-.24a.65.65 0 0 1-.53-.76l.29-1.6a1.08 1.08 0 0 0-.5-1.13c-.18-.1-.39-.17-.6-.17-.18 0-.36.05-.52.14l-1.7.94a.65.65 0 0 1-.86-.2l-.88-1.26a.65.65 0 0 1 .11-.86l1.46-1.26a1.11 1.11 0 0 0 .06-1.62 1.08 1.08 0 0 0-.57-.3l-1.61-.3a.65.65 0 0 1-.53-.76l.24-1.34a.65.65 0 0 1 .76-.53l1.6.29a1.08 1.08 0 0 0 1.13-.5 1.1 1.1 0 0 0 .03-1.12l-.94-1.7a.65.65 0 0 1 .2-.86l1.26-.88a.65.65 0 0 1 .86.11L7.85 4.1a1.11 1.11 0 0 0 1.62.06c.15-.15.25-.35.3-.56l.3-1.62a.65.65 0 0 1 .76-.53l1.34.24a.65.65 0 0 1 .53.76l-.29 1.6a1.08 1.08 0 0 0 .5 1.13 1.1 1.1 0 0 0 1.12.03l1.69-.94a.65.65 0 0 1 .86.2l.88 1.26a.65.65 0 0 1-.1.86l-1.47 1.26a1.11 1.11 0 0 0-.05 1.62c.15.16.35.26.56.3l1.62.3a.65.65 0 0 1 .53.76l-.24 1.34a.65.65 0 0 1-.76.53l-1.6-.29a1.08 1.08 0 0 0-1.12.5l.18.11.18.12h.01z"
      fill={COLORS.CORE.WHITE}
    />
    <path
      strokeWidth={0.75}
      d="M15.2 13.14a.65.65 0 0 1 .67-.3l1.6.3a1.08 1.08 0 0 0 1.27-.89l.24-1.35a1.08 1.08 0 0 0-.88-1.27l-1.61-.28a.65.65 0 0 1-.53-.52.67.67 0 0 1 .22-.65l1.46-1.25a1.08 1.08 0 0 0 .18-1.45l-.88-1.26a1.08 1.08 0 0 0-1.43-.33l-1.68.94a.68.68 0 0 1-.94-.32.65.65 0 0 1-.04-.38l.28-1.61a1.08 1.08 0 0 0-.88-1.27l-1.35-.23a1.08 1.08 0 0 0-1.26.88l-.29 1.61a.65.65 0 0 1-.52.53.67.67 0 0 1-.65-.22L6.93 2.36a1.08 1.08 0 0 0-1.45-.18l-1.26.88a1.08 1.08 0 0 0-.33 1.43l.94 1.68a.68.68 0 0 1-.32.94.65.65 0 0 1-.38.04l-1.6-.28a1.08 1.08 0 0 0-1.27.88L1.02 9.1a1.08 1.08 0 0 0 .88 1.26l1.61.29a.65.65 0 0 1 .53.52.67.67 0 0 1-.22.65l-1.46 1.25a1.08 1.08 0 0 0-.18 1.45l.88 1.26a1.08 1.08 0 0 0 1.43.33l1.68-.94a.68.68 0 0 1 .94.32c.05.12.07.25.04.38l-.28 1.6a1.08 1.08 0 0 0 .88 1.27l1.35.24a1.08 1.08 0 0 0 1.27-.88l.28-1.61a.65.65 0 0 1 .52-.53.67.67 0 0 1 .65.22l1.25 1.46a1.08 1.08 0 0 0 1.45.18l1.26-.88a1.08 1.08 0 0 0 .33-1.43l-.94-1.68a.67.67 0 0 1 .02-.68l-.18-.12-.19-.1a1.1 1.1 0 0 0-.03 1.12l.94 1.68a.65.65 0 0 1-.2.87l-1.26.88a.65.65 0 0 1-.86-.12l-1.26-1.46a1.11 1.11 0 0 0-1.62-.06c-.15.15-.25.35-.3.56l-.3 1.62a.66.66 0 0 1-.76.53l-1.34-.24a.65.65 0 0 1-.53-.76l.29-1.6a1.08 1.08 0 0 0-.5-1.13c-.18-.1-.39-.17-.6-.17-.18 0-.36.05-.52.14l-1.7.94a.65.65 0 0 1-.86-.2l-.88-1.26a.65.65 0 0 1 .11-.86l1.46-1.26a1.11 1.11 0 0 0 .06-1.62 1.08 1.08 0 0 0-.57-.3l-1.61-.3a.65.65 0 0 1-.53-.76l.24-1.34a.65.65 0 0 1 .76-.53l1.6.29a1.08 1.08 0 0 0 1.13-.5 1.1 1.1 0 0 0 .03-1.12l-.94-1.7a.65.65 0 0 1 .2-.86l1.26-.88a.65.65 0 0 1 .86.11L7.85 4.1a1.11 1.11 0 0 0 1.62.06c.15-.15.25-.35.3-.56l.3-1.62a.65.65 0 0 1 .76-.53l1.34.24a.65.65 0 0 1 .53.76l-.29 1.6a1.08 1.08 0 0 0 .5 1.13 1.1 1.1 0 0 0 1.12.03l1.69-.94a.65.65 0 0 1 .86.2l.88 1.26a.65.65 0 0 1-.1.86l-1.47 1.26a1.11 1.11 0 0 0-.05 1.62c.15.16.35.26.56.3l1.62.3a.65.65 0 0 1 .53.76l-.24 1.34a.65.65 0 0 1-.76.53l-1.6-.29a1.08 1.08 0 0 0-1.12.5l.18.11.18.12h.01z"
      stroke={COLORS.CORE.WHITE}
    />
    <path
      d="M10 6.3a3.7 3.7 0 1 0 0 7.4 3.7 3.7 0 0 0 0-7.4zm0 6.5a2.8 2.8 0 1 1 0-5.61 2.8 2.8 0 0 1 0 5.62z"
      fill={COLORS.CORE.WHITE}
      stroke={COLORS.CORE.WHITE}
      strokeWidth={0.45}
    />
  </svg>
);

export default SettingsIcon;

import store from '@/store';
// eslint-disable-next-line
import { strings } from '@/utils/strings';
import {
  ACTION_ITEMS_CLUSTER,
  ACTION_ITEMS_CLUSTER_SUMMARY,
} from '../reactComponents/NavigationReact/Navigation.config.react';

/* eslint-disable */

const clusterWrapper = () => import(/* webpackChunkName: "cluster" */ '@/views/cluster/notFoundWrapper/index.vue');
const rbac = () => import(/* webpackChunkName: "cluster" */ '@/views/cluster/rbac/index.vue');
const addons = () => import(/* webpackChunkName: "cluster" */ '@/views/cluster/add-ons/index.vue');
const reactActionItemsTable = () =>
  import(/* webpackChunkName: "organizations" */ '@/views/organization/actionItems/ActionItemsPage/index.vue');
const reactActionItemsPage = () =>
  import(/* webpackChunkName: "organizations" */ '@/views/organization/actionItems/index.vue');
const reactActionItemsLists = () =>
  import(/* webpackChunkName: "organizations" */ '@/views/organization/actionItems/ActionItemsList/index.vue');
const reactActionItemsReports = () =>
  import(/* webpackChunkName: "organizations" */ '@/views/organization/actionItems/ActionItemsReports/index.vue');
const reactActionItemsSummary = () =>
  import(/* webpackChunkName: "organizations" */ '@/views/organization/actionItems/ActionItemsSummary/index.vue');
const hubReport = () => import(/* webpackChunkName: "cluster" */ '@/views/hub/index.vue');
const hubReportInstall = () => import(/* webpackChunkName: "cluster" */ '@/views/hub/install/index.vue');
const hub = () => import(/* webpackChunkName: "cluster" */ '@/views/cluster/hub/index.vue');
const reactHubOverview = () => import(/* webpackChunkName: "cluster" */ '@/views/cluster/hub/overview/index.vue');
const datadogMyActionItems = () =>
  import(/* webpackChunkName: "datadog" */ '@/views/cluster/datadog-action-items/index.vue');
const reactEfficiency = () =>
  import(/* webpackChunkName: "organizations" */ '@/views/organization/efficiency/index.vue');
const reactOrgEfficiency = () =>
  import(/* webpackChunkName: "organizations" */ '@/views/organization/efficiency/Capacity/CapacityMain/index.vue');
const cumulativeCosts = () =>
  import(/* webpackChunkName: "organizations" */ '@/views/organization/efficiency/CumulativeCosts/index.vue');
const rightSizing = () =>
  import(/* webpackChunkName: "organizations" */ '@/views/organization/efficiency/RightSizing/index.vue');
const cumulativeCostsSettings = () =>
  import(/* webpackChunkName: "organizations" */ '@/views/organization/efficiency/CumulativeCosts/Settings/index.vue');
const reactOverview = () => import(/* webpackChunkName: "cluster" */ '@/views/cluster/overview/index.vue');

const clusterLevelReroute = (to, next, access) => {
  if (access) {
    return next();
  }
  next({ name: 'clusteroverview', params: { org: to.params.org, cluster: to.params.cluster } });
  return null;
};

const reactAdmissionRequests = () =>
  import(/* webpackChunkName: "organizations" */ '@/views/organization/admissionRequests/index.vue');

export default [
  {
    path: '/orgs/:org/clusters/:cluster',
    props: true,
    component: clusterWrapper,
    children: [
      {
        path: '',
        name: 'default-cluster',
        redirect: { name: 'clusteroverview' },
      },
      {
        path: 'overview/',
        name: 'clusteroverview',
        props: true,
        component: reactOverview,
        meta: { title: strings.metaTitle + strings.general.Cluster + strings.navigation.Overview },
        beforeEnter: (to, from, next) => {
          if (
            store.getters.organization?.Tier === 0 &&
            !store.getters.installedReports?.length &&
            !store.getters.cluster?.AgentChartVersion
          ) {
            next({ name: 'clusterreport-hub', params: { ...to.params }, query: { ...to.query } });
          }
          next();
        },
      },
      {
        path: 'action-items/',
        props: true,
        component: reactActionItemsPage,
        meta: { title: strings.metaTitle + strings.navigation.ActionItems + strings.Table },
        children: [
          {
            path: '',
            name: ACTION_ITEMS_CLUSTER,
            props: true,
            component: reactActionItemsTable,
            beforeEnter(to, from, next) {
              if (from.name !== ACTION_ITEMS_CLUSTER && !to.query.redirect) {
                return next({ ...to, query: { ...to.query, redirect: 'true' } });
              }
              return next();
            },
          },
          {
            path: 'lists',
            name: 'action-items-cluster-lists',
            props: true,
            meta: {
              title: strings.metaTitle + strings.navigation.ActionItems + strings.navigation.reactActionItemsLists,
            },
            component: reactActionItemsLists,
          },
          {
            path: 'reports',
            name: 'action-items-cluster-reports',
            props: true,
            meta: {
              title: strings.metaTitle + strings.navigation.ActionItems + strings.navigation.reports,
            },
            component: reactActionItemsReports,
          },
          {
            path: 'reports-print-preview',
            name: 'action-items-cluster-reports-print-preview',
            props: true,
            meta: {
              title: strings.metaTitle + strings.navigation.ActionItems + strings.navigation.reports,
            },
            component: reactActionItemsReports,
          },
          {
            path: strings.noTranslate.summary,
            name: ACTION_ITEMS_CLUSTER_SUMMARY,
            props: true,
            meta: {
              title: strings.metaTitle + strings.navigation.ActionItems + strings.navigation.summary,
            },
            component: reactActionItemsSummary,
            beforeEnter: (to, from, next) => {
              return next();
            },
          },
        ],
      },
      {
        path: 'add-ons',
        name: 'clusteradd-ons',
        props: true,
        component: addons,
        meta: { title: strings.metaTitle + strings.general.Cluster + strings.navigation.addOns },
        beforeEnter: (to, _, next) =>
          store.getters.organization?.Tier === 0 && !store.getters.installedReports?.length
            ? next({ name: 'clusterreport-hub', params: { ...to.params }, query: { ...to.query } })
            : clusterLevelReroute(to, next, store.getters.organizationTierAccess.AddonsTab),
      },
      {
        path: 'efficiency-clusters',
        props: true,
        component: reactEfficiency,
        children: [
          {
            path: '',
            name: 'node-efficiency',
            component: reactOrgEfficiency,
            meta: { title: strings.metaTitle + strings.navigation.Capacity },
          },
          {
            path: 'costs/',
            name: 'cumulative-costs-cluster',
            props: true,
            component: cumulativeCosts,
            meta: { title: 'Fairwinds Insights | Costs' },
            beforeEnter: (to, _, next) =>
              clusterLevelReroute(to, next, store.getters.organizationTierAccess.WorkloadsTab),
          },
          {
            path: 'right-sizing',
            name: 'right-sizing-cluster',
            props: true,
            component: rightSizing,
            meta: { title: 'Fairwinds Insights | Right-Sizing' },
            beforeEnter: (to, _, next) =>
              clusterLevelReroute(to, next, store.getters.organizationTierAccess.WorkloadsTab),
          },
          {
            path: strings.noTranslate.settings,
            name: 'costs-settings-cluster',
            component: cumulativeCostsSettings,
            meta: { title: 'Fairwinds Insights | Costs Settings' },
            beforeEnter: (to, _, next) =>
              clusterLevelReroute(to, next, store.getters.organizationTierAccess.ClusterCost),
          },
        ],
      },
      {
        path: 'rbac',
        name: 'clusterrbac',
        props: true,
        component: rbac,
        meta: { title: strings.metaTitle + strings.navigation.rbac },
        beforeEnter: (to, _, next) =>
          store.getters.organization?.Tier === 0 && !store.getters.installedReports?.length
            ? next({ name: 'clusterreport-hub', params: { ...to.params }, query: { ...to.query } })
            : clusterLevelReroute(to, next, store.getters.organizationTierAccess.RBACTab),
      },
      {
        path: 'admission-requests/',
        name: strings.AdmissionRequestsCluster,
        meta: { title: strings.metaTitle + strings.general.Admission },
        component: reactAdmissionRequests,
        children: [],
      },
      {
        path: 'my-action-items/',
        name: 'datadog-action-items',
        props: {
          default: true,
          cluster: (route) => ({ search: route.query.cluster }),
        },
        component: datadogMyActionItems,
        meta: { title: strings.metaTitle + strings.myActionItemsDatadog },
      },
      {
        path: 'report-hub/',
        component: hub,
        props: true,
        children: [
          {
            path: '',
            name: 'clusterreport-hub',
            props: true,
            meta: { title: strings.metaTitle + strings.navigation.installHub },
            component: reactHubOverview,
          },
          {
            path: 'report/:report/',
            name: 'hub-report',
            component: hubReport,
            children: [
              {
                path: '',
                name: 'default-hub-report',
                redirect: { name: 'hub-report-about' },
              },
              {
                path: 'about',
                name: 'hub-report-about',
                props: true,
                component: hubReport,
                meta: {
                  title: strings.metaTitle + strings.navigation.installHub + strings.reportAbout,
                },
              },
              {
                path: 'configure',
                name: 'hub-report-configure',
                props: true,
                component: hubReport,
                meta: {
                  title: strings.metaTitle + strings.navigation.installHub + strings.reportConfiguration,
                },
              },
              {
                path: 'history',
                name: 'hub-report-history',
                props: true,
                component: hubReport,
                meta: {
                  title: strings.metaTitle + strings.navigation.installHub + strings.reportAbout,
                },
              },
            ],
          },
          {
            path: 'install',
            name: 'clusterhub-install',
            component: hubReportInstall,
            meta: {
              title: strings.metaTitle + strings.navigation.installHub + strings.agentInstallInstructions,
            },
          },
        ],
      },
    ],
  },
];

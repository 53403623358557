import * as React from 'react';
import { SVGProps } from 'react';
import { COLORS } from '~utils/styling';

const VulnerabilitiesIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M5.27 5.5c.15.12.34.14.51.07l.74-.3 1.14.37c.13.05.27.04.4-.03a.51.51 0 0 0 .25-.3 1.77 1.77 0 0 1 3.45.52V9.3H8.23V7a.51.51 0 0 0-.34-.48l-1.17-.39a.51.51 0 0 0-.36.01l-1.04.44a.51.51 0 0 0-.3.47v2.24h-.29c-.82 0-1.48.66-1.48 1.48v6.9c0 .82.66 1.48 1.48 1.48h10.53c.82 0 1.48-.66 1.48-1.48v-6.9c0-.82-.66-1.48-1.48-1.48h-.28V5.84a5 5 0 0 0-9.9-.82.5.5 0 0 0 .19.48zm0 0 .1-.12-.1.12zm1.4-1.27a.52.52 0 0 0-.3 0 3.97 3.97 0 0 1 7.59 1.61v3.45h-1.18V5.84a2.79 2.79 0 0 0-5.24-1.31l-.87-.3zM6.03 9.3V7.4l.55-.23.63.21V9.3H6.03zm9.7 8.38c0 .25-.21.46-.47.46H4.73a.46.46 0 0 1-.46-.46v-6.9c0-.25.2-.46.46-.46h10.54c.25 0 .45.2.45.46v6.9z"
      fill={COLORS.CORE.WHITE}
      stroke={COLORS.CORE.WHITE}
      strokeWidth={0.3}
    />
    <rect
      x={6.4}
      y={12.06}
      width={1.5}
      height={7.5}
      rx={0.75}
      transform="rotate(-45 6.4 12.06)"
      fill={COLORS.CORE.WHITE}
    />
    <rect
      x={11.7}
      y={11}
      width={1.5}
      height={7.5}
      rx={0.75}
      transform="rotate(45 11.7 11)"
      fill={COLORS.CORE.WHITE}
    />
  </svg>
);

export default VulnerabilitiesIcon;

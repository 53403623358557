import { mapGetters } from 'vuex';
import { TOGGLE_TOAST } from '@/store/action.types';

export default {
  name: 'toast',
  computed: {
    ...mapGetters(['toast']),
  },
  methods: {
    isTesting() {
      return localStorage.getItem('isTesting');
    },
  },
  watch: {
    async toast() {
      if (this.toast.message && !this.isTesting()) {
        if (this.toast.prevId) {
          const toastOuter = document.getElementById(`${this.toast.prevId}__toast_outer`);
          if (toastOuter) {
            toastOuter.style.display = 'none';
          }
        }

        const toastOption = {
          toaster: this.toast.position,
          noCloseButton: true,
          toastClass: `all-toasts ${this.toast.type}-toast`,
        };

        if (this.toast.id) {
          toastOption.id = this.toast.id;
        }

        this.$bvToast.toast(this.toast.message, toastOption);

        await this.$store.dispatch(TOGGLE_TOAST, { message: '', type: '' });
      }
    },
  },
};

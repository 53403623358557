import * as React from 'react';
import { SVGProps } from 'react';
import { COLORS } from '~utils/styling';

const EfficiencyIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <path
      d="m16.83 6.82-1.8-.19a.7.7 0 0 0-.09-.2l1.13-1.4a1.3 1.3 0 0 0-.1-1.74l-1.26-1.27a1.3 1.3 0 0 0-1.73-.1l-1.4 1.14c-.08-.04-.15-.06-.21-.1l-.19-1.79A1.3 1.3 0 0 0 9.9 0H8.1a1.3 1.3 0 0 0-1.28 1.17l-.19 1.8c-.08.03-.15.05-.2.09l-1.4-1.13a1.3 1.3 0 0 0-1.74.1L2.02 3.28a1.3 1.3 0 0 0-.1 1.73l1.14 1.4c-.04.08-.06.15-.1.21l-1.79.19A1.3 1.3 0 0 0 0 8.1v1.8c0 .66.51 1.22 1.17 1.28l1.8.19c.03.08.05.15.09.2l-1.13 1.4a1.3 1.3 0 0 0 .1 1.74l1.26 1.27a1.3 1.3 0 0 0 1.73.1l1.4-1.14c.08.04.15.06.21.1l.19 1.79A1.3 1.3 0 0 0 8.1 18h1.8a1.3 1.3 0 0 0 1.28-1.17l.19-1.8a.7.7 0 0 0 .2-.09l1.4 1.13a1.3 1.3 0 0 0 1.74-.1l1.27-1.26a1.3 1.3 0 0 0 .1-1.73l-1.14-1.4c.04-.08.06-.15.1-.21l1.79-.19A1.3 1.3 0 0 0 18 9.9V8.1c0-.66-.51-1.2-1.17-1.28ZM16.7 9.9l-2.08.2a.81.81 0 0 0-.7.57c-.08.2-.15.4-.25.6-.15.3-.1.65.1.91l1.32 1.63-1.26 1.26-1.61-1.32a.83.83 0 0 0-.9-.1c-.21.1-.4.2-.61.25a.87.87 0 0 0-.57.72l-.2 2.08h-1.8l-.2-2.08a.81.81 0 0 0-.57-.7c-.21-.08-.4-.15-.6-.25a.86.86 0 0 0-.39-.09.8.8 0 0 0-.52.19l-1.63 1.32-1.26-1.26 1.32-1.63c.2-.24.24-.6.1-.9-.1-.21-.2-.4-.25-.61a.87.87 0 0 0-.72-.57l-2.08-.2v-1.8l2.08-.2c.32-.04.6-.25.72-.57.07-.21.15-.4.24-.6a.84.84 0 0 0-.1-.91L2.98 4.2l1.26-1.26 1.6 1.32c.25.2.61.24.91.1.21-.1.4-.2.6-.25a.86.86 0 0 0 .57-.72l.21-2.08h1.8l.2 2.08c.04.32.27.6.57.7l.6.24c.3.16.65.12.91-.09l1.63-1.32 1.26 1.26-1.32 1.63c-.2.24-.25.6-.1.9.1.21.2.4.25.6.11.31.38.54.7.58l2.08.2v1.8Z"
      fill={COLORS.CORE.WHITE}
    />
    <path
      d="M11.14 8.12H9.95c-.03 0-.07-.04-.05-.07l.36-2.38a.47.47 0 0 0-.84-.36L6.55 9.16c-.22.3 0 .76.38.76h1.2c.03 0 .07.03.05.07l-.36 2.38c-.08.5.55.74.83.36l2.87-3.85a.48.48 0 0 0-.38-.76Z"
      fill={COLORS.CORE.WHITE}
    />
  </svg>
);

export default EfficiencyIcon;

import { getCurrentUser, sendRequest } from '@/utils/request';
import store from '../store/index';

/* eslint-disable */
function initHotJar() {
  (function (h, o, t, j, a, r) {
    h.hj =
      h.hj ||
      function () {
        (h.hj.q = h.hj.q || []).push(arguments);
      };
    h._hjSettings = { hjid: 1864235, hjsv: 6 };
    a = o.getElementsByTagName('head')[0];
    r = o.createElement('script');
    r.async = 1;
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
    a.appendChild(r);
  })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
}

export function getOrganization() {
  return store.getters.organization?.Name;
}

function isFreeTier() {
  return store.getters.organization?.Tier === 0;
}

const OrgTierNames = {
  0: 'Free',
  1: 'Enterprise',
  2: 'Business',
  3: 'Disabled',
};

export function getOrgTierName() {
  return OrgTierNames[store.getters.organization?.Tier];
}

function isAgentInstalled() {
  return !!store.getters.clusters?.find((c) => !!c.AgentChartVersion);
}

function isCICDInstalled() {
  return !!store.getters.repositories?.length;
}

async function isTicketingInstalled(baseUrl) {
  return (
    isGithubInstalled() || (await isJiraInstalled(baseUrl)) || (await isAzureInstalled(baseUrl))
  );
}

function isGithubInstalled() {
  return store.getters.repositories?.filter((r) => r.InstallationID).length > 0;
}

async function isJiraInstalled(baseUrl) {
  if (!baseUrl) {
    return;
  }
  try {
    const projects = await sendRequest('GET', `${baseUrl}/jira/projects`, {}, null);
    return projects && Object.keys(projects).length > 0;
  } catch (e) {
    return false;
  }
}

async function isAzureInstalled(baseUrl) {
  if (!baseUrl) {
    return;
  }
  try {
    const tokenSet = await sendRequest('GET', `${baseUrl}/azure/settings`, {}, null);
    return tokenSet?.IsAccessTokenSet;
  } catch (e) {
    return false;
  }
}

async function hasMultipleUsers(baseUrl) {
  const memberships = await getMemberships(baseUrl);
  if (!memberships?.length) {
    return false;
  }
  const distinctEmails = new Set();
  for (const membership of memberships) {
    if (membership?.Email && !distinctEmails.has(membership.Email)) {
      distinctEmails.add(membership.Email);
    }
  }
  return distinctEmails.size > 1;
}

async function getMemberships(baseUrl) {
  try {
    return await sendRequest('GET', `${baseUrl}/memberships`);
  } catch (e) {
    return null;
  }
}

function initMixpanel(user) {
  if (!window.insightsConfig.mixpanelID) return;

  (function (c, a) {
    if (!a.__SV) {
      var b = window;
      try {
        var d,
          m,
          j,
          k = b.location,
          f = k.hash;
        d = function (a, b) {
          return (m = a.match(RegExp(b + '=([^&]*)'))) ? m[1] : null;
        };
        f &&
          d(f, 'state') &&
          ((j = JSON.parse(decodeURIComponent(d(f, 'state')))),
          'mpeditor' === j.action &&
            (b.sessionStorage.setItem('_mpcehash', f),
            history.replaceState(j.desiredHash || '', c.title, k.pathname + k.search)));
      } catch (n) {}
      var l, h;
      window.mixpanel = a;
      a._i = [];
      a.init = function (b, d, g) {
        function c(b, i) {
          var a = i.split('.');
          2 == a.length && ((b = b[a[0]]), (i = a[1]));
          b[i] = function () {
            b.push([i].concat(Array.prototype.slice.call(arguments, 0)));
          };
        }
        var e = a;
        'undefined' !== typeof g ? (e = a[g] = []) : (g = 'mixpanel');
        e.people = e.people || [];
        e.toString = function (b) {
          var a = 'mixpanel';
          'mixpanel' !== g && (a += '.' + g);
          b || (a += ' (stub)');
          return a;
        };
        e.people.toString = function () {
          return e.toString(1) + '.people (stub)';
        };
        l =
          'disable time_event track track_pageview track_links track_forms track_with_groups add_group set_group remove_group register register_once alias unregister identify name_tag set_config reset opt_in_tracking opt_out_tracking has_opted_in_tracking has_opted_out_tracking clear_opt_in_out_tracking people.set people.set_once people.unset people.increment people.append people.union people.track_charge people.clear_charges people.delete_user people.remove'.split(
            ' ',
          );
        for (h = 0; h < l.length; h++) c(e, l[h]);
        var f = 'set set_once union unset remove delete'.split(' ');
        e.get_group = function () {
          function a(c) {
            b[c] = function () {
              call2_args = arguments;
              call2 = [c].concat(Array.prototype.slice.call(call2_args, 0));
              e.push([d, call2]);
            };
          }
          for (
            var b = {}, d = ['get_group'].concat(Array.prototype.slice.call(arguments, 0)), c = 0;
            c < f.length;
            c++
          )
            a(f[c]);
          return b;
        };
        a._i.push([b, d, g]);
      };
      a.__SV = 1.2;
      b = c.createElement('script');
      b.type = 'text/javascript';
      b.async = !0;
      b.src =
        'undefined' !== typeof MIXPANEL_CUSTOM_LIB_URL
          ? MIXPANEL_CUSTOM_LIB_URL
          : 'file:' === c.location.protocol &&
              '//cdn4.mxpnl.com/libs/mixpanel-2-latest.min.js'.match(/^\/\//)
            ? 'https://cdn4.mxpnl.com/libs/mixpanel-2-latest.min.js'
            : '//cdn4.mxpnl.com/libs/mixpanel-2-latest.min.js';
      d = c.getElementsByTagName('script')[0];
      d.parentNode.insertBefore(b, d);
    }
  })(document, window.mixpanel || []);

  mixpanel.init(window.insightsConfig.mixpanelID, {
    loaded: function () {
      if (mixpanel.get_distinct_id() === 'anonymous') {
        mixpanel.reset();
      }
    },
  });
  if (user) {
    mixpanel.identify(user.Email);
    const mixPanelUserData = {
      $email: user.Email,
      $created: user.CreatedOn,
      $first_name: user.FirstName,
      $last_name: user.LastName,
    };

    const organization = getOrganization();
    if (organization) {
      mixPanelUserData.organization = organization;
    }

    const orgTier = getOrgTierName();
    if (orgTier) {
      mixPanelUserData.org_tier = orgTier;
    }

    mixpanel.people.set(mixPanelUserData);
  }
}

function initTagManager() {
  (function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l != 'dataLayer' ? '&l=' + l : '';
    j.async = true;
    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    f.parentNode.insertBefore(j, f);
  })(window, document, 'script', 'dataLayer', 'GTM-TM95WXQ');
}

export default async function initPlugins() {
  let user = null;
  try {
    const userData = await getCurrentUser();
    user = userData && userData.user;
  } catch (e) {}

  initMixpanel(user);

  if (window.insightsConfig.enableMarketingPlugins) {
    initTagManager();
    initHotJar();
  }
}

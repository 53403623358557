export const UPDATE_MEMBERSHIPS = 'UPDATE_MEMBERSHIPS';
export const UPDATE_ORG = 'UPDATE_ORG';
export const UPDATE_CLUSTER = 'UPDATE_CLUSTER';
export const UPDATE_REPOSITORY = 'UPDATE_REPOSITORY';
export const UPDATE_TIERS = 'UPDATE_TIERS';
export const SET_MEMBERSHIPS = 'SET_MEMBERSHIPS';
export const SET_ORGANIZATION = 'SET_ORGANIZATION';
export const SET_REPOSITORY = 'SET_REPOSITORY';
export const SET_CLUSTERS = 'SET_CLUSTERS';
export const SET_CLUSTER = 'SET_CLUSTER';
export const SET_CLUSTER_SUMMARY = 'SET_CLUSTER_SUMMARY';
export const SET_TIERS = 'SET_TIERS';
export const SET_VULNERABILITIES = 'SET_VULNERABILITIES';
export const SET_ORG_REPOSITORIES = 'SET_ORG_REPOSITORIES';
export const REMOVE_ORG_REPOSITORY = 'REMOVE_ORG_REPOSITORY';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_AUTH_STEP = 'SET_AUTH_STEP';
export const REMOVE_CURRENT_USER = 'REMOVE_CURRENT_USER';
export const UPDATE_CURRENT_USER = 'UPDATE_CURRENT_USER';
export const UPDATE_REPORTS_CONFIGS = 'UPDATE_REPORTS_CONFIGS';
export const REMOVE_REPORT_CONFIGS = 'REMOVE_REPORT_CONFIGS';
export const SET_REPORTS_CONFIGS = 'SET_REPORTS_CONFIGS';
export const SET_REMOVED_REPORTS = 'SET_REMOVED_REPORTS';
export const SET_REPORTS_STATUS = 'SET_REPORTS_STATUS';
export const SET_APP_READY = 'SET_APP_READY';
export const TOGGLE_NAV_SIZE = 'TOGGLE_NAV_SIZE';
export const TOGGLE_TOAST = 'TOGGLE_TOAST';
export const SET_STRICT_SAML_ORGS = 'SET_STRICT_SAML_ORGS';
export const TOGGLE_ADD_REPO_MODAL = 'TOGGLE_ADD_REPO_MODAL';
export const TOGGLE_REPO_SETTINGS_MODAL = 'TOGGLE_REPO_SETTINGS_MODAL';
export const SET_IS_ERROR_PAGE_SHOWN = 'SET_IS_ERROR_PAGE_SHOWN';
export const TOGGLE_ADD_APP_GROUP_MODAL = 'TOGGLE_ADD_APP_GROUP_MODAL';

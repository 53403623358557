import * as React from 'react';
import { SVGProps } from 'react';

// making this icon smaller through an app breaks the icon
const ActionItemsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x="7.2998" y="9.09961" width="7.2" height="1.35" rx="0.675" fill="white" />
    <mask id="path-2-inside-1_1867_27527" fill="white">
      <rect x="1" y="7.2998" width="5.4" height="5.4" rx="0.5" />
    </mask>
    <rect
      x="1"
      y="7.2998"
      width="5.4"
      height="5.4"
      rx="0.5"
      stroke="white"
      strokeWidth="3"
      mask="url(#path-2-inside-1_1867_27527)"
    />
    <rect x="7.2998" y="15.4004" width="11.7" height="1.35" rx="0.675" fill="white" />
    <mask id="path-4-inside-2_1867_27527" fill="white">
      <rect x="1" y="13.6006" width="5.4" height="5.4" rx="0.5" />
    </mask>
    <rect
      x="1"
      y="13.6006"
      width="5.4"
      height="5.4"
      rx="0.5"
      stroke="white"
      strokeWidth="3"
      mask="url(#path-4-inside-2_1867_27527)"
    />
    <rect x="7.2998" y="2.7998" width="11.7" height="1.35" rx="0.675" fill="white" />
    <mask id="path-6-inside-3_1867_27527" fill="white">
      <rect x="1" y="1" width="5.4" height="5.4" rx="0.5" />
    </mask>
    <rect
      x="1"
      y="1"
      width="5.4"
      height="5.4"
      rx="0.5"
      stroke="white"
      strokeWidth="3"
      mask="url(#path-6-inside-3_1867_27527)"
    />
  </svg>
);

export default ActionItemsIcon;

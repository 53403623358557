import React from 'react';

import { Alert, Button, Modal } from 'react-bootstrap';

type MessageDialogProps = {
  buttonClasses?: string;
  buttonText?: string;
  isModalShown?: boolean;
  modalBodyClasses?: string;
  modalClasses?: string;
  modalContent?: string;
  modalContentClasses?: string;
  modalFooterClasses?: string;
  modalTitle?: string;
  onButtonClicked?: () => void;
  onModalHidden?: (isModalShown?: boolean | undefined) => void;
};

const MessageDialog = ({
  buttonClasses,
  buttonText,
  isModalShown,
  modalBodyClasses,
  modalClasses,
  modalContent,
  modalContentClasses,
  modalFooterClasses,
  modalTitle,
  onButtonClicked,
  onModalHidden,
}: MessageDialogProps) => {
  const closeModal = () => {
    onModalHidden && onModalHidden(false);
  };

  return (
    <Modal
      show={isModalShown}
      onHide={closeModal}
      className={`${modalClasses ? modalClasses : ''}`}
    >
      <Modal.Body className={`pt-0 ${modalBodyClasses ? modalBodyClasses : ''}`}>
        <div>
          <h3 className="modal-title">{modalTitle}</h3>
        </div>
        <p className={`${modalContentClasses ? modalContentClasses : ''}`}>
          {modalContent && (
            <Alert variant="danger" className="team-management__alert">
              {modalContent}
            </Alert>
          )}
        </p>
      </Modal.Body>
      <Modal.Footer className={`${modalFooterClasses ? modalFooterClasses : ''}`}>
        <Button
          className={`btn ${buttonClasses ? buttonClasses : ''}`}
          type="button"
          onClick={() => onButtonClicked && onButtonClicked()}
        >
          {buttonText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MessageDialog;

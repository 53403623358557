import * as React from 'react';
import { SVGProps } from 'react';
import { COLORS } from '~utils/styling';

const ProfileIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M16.65 19.15c.57 0 1.03-.46 1.03-1.03a7.61 7.61 0 1 0-15.23 0c0 .57.46 1.03 1.02 1.03h13.18zm-.15-1.18H3.63a6.44 6.44 0 0 1 12.87 0zM5.52 5.4a4.54 4.54 0 1 0 9.08 0 4.54 4.54 0 0 0-9.08 0zm7.9 0a3.36 3.36 0 1 1-6.72 0 3.36 3.36 0 0 1 6.73 0z"
      fill={COLORS.CORE.WHITE}
      stroke={COLORS.CORE.WHITE}
      strokeWidth={0.3}
    />
  </svg>
);

export default ProfileIcon;

import store from '@/store';
import neworg from '@/views/neworg/newOrganization.vue';
// eslint-disable-next-line
import { strings } from '~utils/strings';
import { sendRequest } from '@/utils/request';
import { IS_PLG_UPGRADE_MODAL_SHOW } from '@/utils/constants';
import {
  ACTION_ITEMS_SUMMARY,
  ALL_POLICIES,
  APP_GROUPS_REPORTS,
  POLICY_MAPPING,
} from '~reactComponents/NavigationReact/Navigation.config.react';
import { ONRAMP_FREE_TIER_KEYS } from '~reactHelpers';

/* eslint-disable */
const reactRepositories = () =>
  import(/* webpackChunkName: "organizations" */ '@/views/repositories/ReactRepositories/index.vue');
const reactRepository = () =>
  import(/* webpackChunkName: "organizations" */ '@/views/repositories/ReactRepository/index.vue');
const policy = () => import(/* webpackChunkName: "organizations" */ '@/views/organization/policy/index.vue');
const policiesList = () =>
  import(/* webpackChunkName: "organizations" */ '@/views/organization/policy/policies/policyList.vue');
const newPolicy = () => import(/* webpackChunkName: "organizations" */ '@/views/organization/policy/new/newPolicy.vue');
const editPolicy = () => import(/* webpackChunkName: "organizations" */ '@/views/organization/policy/edit/index.vue');
const policyMapping = () =>
  import(/* webpackChunkName: "organizations" */ '@/views/organization/policy/policyMapping/index.vue');
const automation = () =>
  import(/* webpackChunkName: "organizations" */ '@/views/organization/automationRules/index.vue');
const automationList = () =>
  import(
    /* webpackChunkName: "organizations" */ '@/views/organization/automationRules/rulesList/automationRulesList.vue'
  );
const automationTemplates = () =>
  import(
    /* webpackChunkName: "organizations" */ '@/views/organization/automationRules/templates/automationTemplatesList.vue'
  );
const reactAutomationLogs = () =>
  import(/* webpackChunkName: "organizations" */ '@/views/organization/automationRules/logs/index.vue');
const editRule = () =>
  import(/* webpackChunkName: "organizations" */ '@/views/organization/automationRules/edit/editAutomation.vue');
const newRule = () =>
  import(/* webpackChunkName: "organizations" */ '@/views/organization/automationRules/new/newAutomation.vue');
const reactActionItemsTable = () =>
  import(/* webpackChunkName: "organizations" */ '@/views/organization/actionItems/ActionItemsPage/index.vue');
const reactActionItemsPage = () =>
  import(/* webpackChunkName: "organizations" */ '@/views/organization/actionItems/index.vue');
const reactActionItemsLists = () =>
  import(/* webpackChunkName: "organizations" */ '@/views/organization/actionItems/ActionItemsList/index.vue');
const reactActionItemsReports = () =>
  import(/* webpackChunkName: "organizations" */ '@/views/organization/actionItems/ActionItemsReports/index.vue');
const reactActionItemsSummary = () =>
  import(/* webpackChunkName: "organizations" */ '@/views/organization/actionItems/ActionItemsSummary/index.vue');
const reactComplianceView = () =>
  import(/* webpackChunkName: "organizations" */ '@/views/organization/compliance/index.vue');
const reactCompliance = () =>
  import(/* webpackChunkName: "organizations" */ '@/views/organization/compliance/Compliance/index.vue');
const reactComplianceReport = () =>
  import(/* webpackChunkName: "organizations" */ '@/views/organization/compliance/Report/index.vue');
const reactDownloadReport = () =>
  import(/* webpackChunkName: "organizations" */ '@/views/organization/compliance/DownloadReport/index.vue');
const settings = () => import(/* webpackChunkName: "organizations" */ '@/views/organization/settings/index.vue');
// eslint-disable-next-line max-len
const settingsParent = () => import(/* webpackChunkName: "organizations" */ '@/views/organization/settings/router.vue');
const teamManagement = () =>
  import(/* webpackChunkName: "organizations" */ '@/views/organization/settings/teamManagement/teamManagement.vue');
// eslint-disable-next-line max-len
const inviteAccount = () =>
  import(/* webpackChunkName: "organizations" */ '@/views/organization/settings/inviteAccount/inviteAccount.vue');
const createTeam = () =>
  import(/* webpackChunkName: "organizations" */ '@/views/organization/settings/createTeam/createTeam.vue');
const clusters = () => import(/* webpackChunkName: "organizations" */ '@/views/clusters/clusters.vue');
const newcluster = () =>
  import(/* webpackChunkName: "organizations" */ '@/views/organization/newcluster/newcluster.vue');
const organizationWrapper = () =>
  import(/* webpackChunkName: "organizations" */ '@/views/organization/notFoundWrapper/index.vue');
const organizations = () => import(/* webpackChunkName: "organizations" */ '@/views/organizations/organizations.vue');
const reactVulnerabilitiesView = () =>
  import(/* webpackChunkName: "organizations" */ '@/views/organization/vulnerabilities/ReactVulnerabilities/index.vue');
const reactAllImages = () =>
  import(
    /* webpackChunkName: "organizations" */ '@/views/organization/vulnerabilities/ReactVulnerabilities/AllImages/index.vue'
  );
const reactImageDetail = () =>
  import(
    /* webpackChunkName: "organizations" */ '@/views/organization/vulnerabilities/ReactVulnerabilities/ImageDetail/index.vue'
  );
const reactAllVulnerabilities = () =>
  import(
    /* webpackChunkName: "organizations" */ '@/views/organization/vulnerabilities/ReactVulnerabilities/AllVulnerabilities/index.vue'
  );

// const hubReportInstall = () => import(/* webpackChunkName: "cluster" */ '@/views/hub/install/inde
const notifications = () =>
  import(/* webpackChunkName: "organizations" */ '@/views/organization/settings/notifications/index.vue');
const reactIntegrationsPage = () =>
  import(/* webpackChunkName: "organizations" */ '@/views/organization/settings/integrations/index.vue');
const datadog = () => import(/* webpackChunkName: "organizations" */ '@/views/organization/settings/datadog/index.vue');
const reactAuthTokens = () =>
  import(/* webpackChunkName: "organizations" */ '@/views/organization/settings/authTokens/index.vue');
const reactSsoPage = () =>
  import(/* webpackChunkName: "organizations" */ '@/views/organization/settings/sso/index.vue');
const reactDangerZone = () =>
  import(/* webpackChunkName: "organizations" */ '@/views/organization/settings/dangerZone/index.vue');
const orgDashboard = () =>
  import(/* webpackChunkName: "organizations" */ '@/views/organization/dashboard/dashboard.vue');
const reactEfficiency = () =>
  import(/* webpackChunkName: "organizations" */ '@/views/organization/efficiency/index.vue');
const reactOrgEfficiency = () =>
  import(/* webpackChunkName: "organizations" */ '@/views/organization/efficiency/Capacity/CapacityMain/index.vue');
const cumulativeCosts = () =>
  import(/* webpackChunkName: "organizations" */ '@/views/organization/efficiency/CumulativeCosts/index.vue');
const rightSizing = () =>
  import(/* webpackChunkName: "organizations" */ '@/views/organization/efficiency/RightSizing/index.vue');
const trialExpired = () =>
  import(/* webpackChunkName: "organizations" */ '@/views/organization/trial-expired/index.vue');
const reactAllPolicies = () =>
  import(/* webpackChunkName: "organizations" */ '@/views/organization/policy/allPolicies/index.vue');
const cumulativeCostsSettings = () =>
  import(/* webpackChunkName: "organizations" */ '@/views/organization/efficiency/CumulativeCosts/Settings/index.vue');
const appGroupsView = () => import(/* webpackChunkName: "organizations" */ '@/views/appGroups/index.vue');
const appGroupsSummary = () =>
  import(/* webpackChunkName: "organizations" */ '@/views/appGroups/summary/appGroupsSummary.vue');
const appGroupsReports = () =>
  import(/* webpackChunkName: "organizations" */ '@/views/appGroups/reports/appGroupsReports.vue');
const reactAdmissionRequests = () =>
  import(/* webpackChunkName: "organizations" */ '@/views/organization/admissionRequests/index.vue');
const strictOrgPage = () => import(/* webpackChunkName: "notFound" */ '@/views/strict-org/strict-org.vue');


const handleOnRampUserRedirect = () => {
  const currentUser = store.getters.user;
  const cb = localStorage.getItem(ONRAMP_FREE_TIER_KEYS.callbackUrl);
  const orgName = localStorage.getItem(strings.localStorage.organization);
  if (!cb || !orgName) {
    return;
  }

  localStorage.removeItem(ONRAMP_FREE_TIER_KEYS.callbackUrl);
  localStorage.removeItem(ONRAMP_FREE_TIER_KEYS.source);

  // if callback is set and there is an organization selected
  const localOSSAuthServer = `${cb}?code=${currentUser.AuthorizationCode}&user=${encodeURIComponent(
    currentUser.Email,
  )}&organization=${orgName}`;
  window.location.href = localOSSAuthServer; // redirect to the local server
};

export default [
  {
    path: '/new-organization',
    name: 'new-organization',
    component: neworg,
    meta: { title: strings.metaTitle + strings.createNewOrg },
  },
  {
    path: '/orgs',
    name: 'orgs',
    component: organizations,
    meta: { title: strings.metaTitle + strings.myOrgs },
    beforeEnter: (to, from, next) => {
      if (store.getters?.memberships?.length === 1 && !localStorage.getItem(IS_PLG_UPGRADE_MODAL_SHOW)) {
        return next({
          name: 'org-dashboard',
          params: {
            org: store.getters?.memberships[0].Organization,
          },
        });
      }
      return next();
    },
  },
  {
    path: '/orgs/:org/',
    props: true,
    component: organizationWrapper,
    children: [
      {
        path: '',
        name: 'default-org',
        redirect: { name: 'org-dashboard' },
      },
      {
        path: 'dashboard',
        name: 'org-dashboard',
        component: orgDashboard,
        meta: { title: strings.metaTitle + strings.Home },
        beforeEnter: (to, from, next) => {
          handleOnRampUserRedirect();
          const organization = store.getters?.organization;
          if (!organization) {
            return next();
          }
          if (store?.getters?.clusters?.length) {
            return next();
          }
          return next({ name: 'new-cluster', params: { org: organization.Name } });
        },
      },
      // support old route
      {
        path: 'clusters/',
        name: 'clusters',
        component: clusters,
        meta: { title: strings.metaTitle + strings.navigation.Clusters },
      },
      {
        path: 'app-groups/',
        component: appGroupsView,
        props: true,
        children: [
          {
            path: '',
            redirect: { name: 'app-groups-summary' },
            meta: { title: strings.metaTitle + strings.appGroups.AppGroupsSummary },
          },
          {
            path: 'app-groups-summary',
            name: 'app-groups-summary',
            component: appGroupsSummary,
            meta: { title: strings.metaTitle + strings.appGroups.AppGroupsSummary },
          },
          {
            path: APP_GROUPS_REPORTS,
            name: APP_GROUPS_REPORTS,
            component: appGroupsReports,
            meta: { title: strings.metaTitle + strings.appGroups.AppGroupsReports },
          }
        ],
      },
      {
        path: 'repositories/',
        name: 'repositories',
        meta: { title: strings.metaTitle + strings.general.Repositories },
        component: reactRepositories,
      },
      {
        path: 'repositories/:repo',
        name: 'repository',
        props: true,
        meta: { title: strings.metaTitle + strings.general.Repository },
        component: reactRepository,
      },
      {
        path: 'repositories/:repo/:branch',
        name: 'repository-branch',
        meta: { title: strings.metaTitle + strings.repoBranch },
        beforeEnter(to, _, next) {
          const repoParam = new URLSearchParams(to.params.repo).toString();
          return next({
            path: `orgs/${to.params.org}/repositories/${repoParam.slice(
              0,
              repoParam.length - 1,
            )}?branch=${to.params.branch}`,
          });
        },
      },
      {
        path: 'compliance/',
        name: 'compliance',
        component: reactComplianceView,
        children: [
          {
            path: '',
            name: 'compliance',
            component: reactCompliance,
            meta: { title: strings.metaTitle + strings.navigation.Compliance },
          },
          {
            path: 'report/',
            name: 'report',
            component: reactComplianceReport,
            meta: { title: strings.metaTitle + strings.complianceReport },
          },
          {
            path: 'download-report/',
            name: 'download-report',
            component: reactDownloadReport,
            meta: { title: strings.metaTitle + strings.Download + ' ' + strings.complianceReport },
          },
        ],
      },
      {
        path: 'new-cluster/',
        name: 'new-cluster',
        component: newcluster,
        meta: { title: strings.metaTitle + strings.createNewCluster },
      },
      {
        path: 'action-items',
        props: true,
        component: reactActionItemsPage,
        children: [
          {
            path: '',
            name: 'action-items',
            props: true,
            component: reactActionItemsTable,
            meta: { title: strings.metaTitle + strings.navigation.ActionItems + strings.Table },
            beforeEnter(to, from, next) {
              if (from.name !== 'action-items' && !to.query.redirect) {
                return next({ ...to, query: { ...to.query, redirect: 'true' } });
              }
              return next();
            },
          },
          {
            path: 'lists',
            name: 'action-items-lists',
            props: true,
            meta: {
              title: strings.metaTitle + strings.navigation.ActionItems + strings.navigation.Lists,
            },
            component: reactActionItemsLists,
          },
          {
            path: 'reports',
            name: 'action-items-reports',
            props: true,
            meta: {
              title: strings.metaTitle + strings.navigation.ActionItems + strings.navigation.reports,
            },
            component: reactActionItemsReports,
          },
          {
            path: 'reports-print-preview',
            name: 'action-items-reports-print-preview',
            props: true,
            meta: {
              title: strings.metaTitle + strings.navigation.ActionItems + strings.navigation.reports,
            },
            component: reactActionItemsReports,
          },
          {
            path: strings.noTranslate.summary,
            name: ACTION_ITEMS_SUMMARY,
            props: true,
            meta: {
              title: strings.metaTitle + strings.navigation.ActionItems + strings.navigation.summary,
            },
            component: reactActionItemsSummary,
            beforeEnter: (to, from, next) => {
              return next();
            },
          },
        ],
      },
      {
        path: 'vulnerabilities/',
        component: reactVulnerabilitiesView,
        props: true,
        children: [
          {
            path: '',
            redirect: { name: 'react-vulnerabilities-all-images' },
            meta: { title: strings.metaTitle + strings.navigation.Vulnerabilities },
          },
          {
            path: 'all-images',
            name: 'react-vulnerabilities-all-images',
            component: reactAllImages,
            meta: { title: strings.metaTitle + strings.allImageVulns },
          },
          {
            path: 'all-vulnerabilities/',
            name: 'react-vulnerabilities-all-vulnerabilities',
            component: reactAllVulnerabilities,
            meta: { title: strings.metaTitle + strings.navigation.allVulns },
          },
          {
            path: 'image/:selectedImage',
            name: 'react-vulnerabilities-image-detail',
            component: reactImageDetail,
            meta: { title: strings.metaTitle + strings.imageVulnsDetails },
          },
        ],
      },
      {
        path: 'vulnerabilities/:cluster',
        name: 'vulnerabilities-cluster',
        component: reactVulnerabilitiesView,
        children: [
          {
            path: '',
            redirect: { name: 'react-vulnerabilities-all-images-cluster' },
          },
          {
            path: 'all-images',
            name: 'react-vulnerabilities-all-images-cluster',
            component: reactAllImages,
            meta: { title: strings.metaTitle + strings.general.Cluster + strings.allImageVulns },
          },
          {
            path: 'all-vulnerabilities/',
            name: 'react-vulnerabilities-all-vulnerabilities-cluster',
            component: reactAllVulnerabilities,
            meta: {
              title: strings.metaTitle + strings.general.Cluster + strings.navigation.allVulns,
            },
          },
          {
            path: 'image/:selectedImage',
            name: 'react-vulnerabilities-image-detail-cluster',
            component: reactImageDetail,
            meta: { title: strings.metaTitle + strings.imageVulnsDetails },
          },
        ],
      },
      {
        path: 'policy',
        component: policy,
        children: [
          {
            path: '',
            name: ALL_POLICIES,
            component: reactAllPolicies,
            meta: { title: strings.metaTitle + strings.Policies },
          },
          {
            path: 'opa-lists/',
            name: 'policy-templates',
            component: policiesList,
            meta: { title: strings.metaTitle + strings.navigation.opaTemplates },
          },
          {
            path: 'policy-wizard/',
            name: 'policy-wizard',
            component: newPolicy,
            meta: { title: strings.metaTitle + strings.navigation.opaWizard },
          },
          {
            path: 'policy-wizard-edit/',
            name: 'policy-wizard-edit',
            component: editPolicy,
            beforeEnter: (to, from, next) => {
              const { name: policyName } = to.query;
              if (!policyName) {
                next({ name: 'policy', params: to.params, query: to.query });
                return;
              }
              next();
            },
            children: [
              {
                path: 'opa-template',
                name: 'edit-opa-template',
                component: editPolicy,
                meta: {
                  isTemplate: true,
                },
              },
            ],
          },
          {
            path: strings.noTranslate.policyMapping,
            name: POLICY_MAPPING,
            props: true,
            meta: {
              title: strings.metaTitle + strings.navigation.Policy + strings.navigation.policyMapping,
            },
            component: policyMapping,
            beforeEnter: (to, from, next) => {
              return next();
            },
          },
        ],
      },
      {
        path: 'automation/',
        component: automation,
        children: [
          {
            path: '',
            name: 'automation',
            component: automationList,
            meta: { title: strings.metaTitle + strings.navigation.Automation + strings.List },
          },
          {
            path: 'templates/',
            name: 'automation-templates',
            component: automationTemplates,
            meta: { title: strings.metaTitle + strings.automationTemplate },
          },
          {
            path: 'new/',
            name: 'new-rule',
            component: newRule,
            meta: { title: strings.metaTitle + strings.createAutomationRule },
          },
          {
            path: 'edit/',
            name: 'edit-rule',
            component: editRule,
            meta: { title: strings.metaTitle + strings.editAutomationRule },
            beforeEnter: (to, from, next) => {
              const { name } = to.query;
              if (!name && to.name !== 'edit-rule-template') {
                next({ name: 'automation', params: to.params, query: to.query });
                return;
              }
              next();
            },
            children: [
              {
                path: 'template',
                name: 'edit-rule-template',
                component: editRule,
                meta: { title: strings.metaTitle + strings.createAutoRuleFromTemplate },
              },
            ],
          },
          {
            path: 'logs/',
            name: 'automation-logs',
            component: reactAutomationLogs,
            meta: { title: strings.metaTitle + strings.automationLogs },
          },
        ],
      },
      {
        path: 'settings/',
        props: true,
        component: settings,
        children: [
          {
            path: '',
            name: 'default-settings',
            redirect: { name: 'team-management' },
          },
          {
            path: 'team-management/',
            component: settingsParent,
            children: [
              {
                path: '',
                name: 'team-management',
                component: teamManagement,
                meta: { title: strings.metaTitle + strings.navigation.teamManagement },
              },
              {
                path: 'new-team/',
                name: 'new-team',
                component: createTeam,
                meta: { title: strings.metaTitle + strings.createNewTeam },
              },
              {
                path: 'invite-users/',
                name: 'invite-users',
                component: inviteAccount,
                meta: { title: strings.metaTitle + strings.inviteUsers },
              },
            ],
          },
          {
            path: 'notifications/',
            name: 'notifications',
            component: notifications,
            meta: { title: strings.metaTitle + strings.notificationSettings },
          },
          {
            path: 'integrations/',
            name: 'integrations',
            component: reactIntegrationsPage,
            meta: { title: strings.metaTitle + strings.navigation.Integrations },
            children: [
              {
                name: 'github',
                path: 'github',
                beforeEnter: async () => {
                  const { organization } = store.getters;
                  const { authURL } = await sendRequest('GET', `/v0/organizations/${organization.Name}/github`);
                  const githubInstallLink = `${authURL}?state=${organization.Name}`;
                  window.open(githubInstallLink, '_blank');
                },
              },
              {
                name: 'jira',
                path: 'jira',
                beforeEnter: async () => {
                  const { authURL } = await sendRequest('GET', '/v0/jira');
                  const { organization } = store.getters;
                  const jiraLink = `${authURL}&state=${organization.Name}`;
                  window.open(jiraLink, '_blank');
                },
              },
            ],
          },
          {
            path: 'datadog/',
            name: 'datadog',
            component: datadog,
            meta: { title: strings.metaTitle + strings.Datadog },
          },
          {
            path: 'tokens/',
            name: 'tokens',
            component: reactAuthTokens,
            meta: { title: strings.metaTitle + strings.tokenSettings },
          },
          {
            path: 'sso/',
            name: 'sso',
            component: reactSsoPage,
            meta: {
              title: strings.metaTitle + strings.navigation.SSO + strings.navigation.Settings,
            },
          },
          {
            path: 'danger-zone/',
            name: 'danger-zone',
            component: reactDangerZone,
            meta: { title: strings.metaTitle + strings.navigation.dangerZone },
          },
        ],
      },
      {
        path: 'efficiency/',
        name: strings.noTranslate.efficiency,
        props: true,
        component: reactEfficiency,
        meta: { title: strings.metaTitle + strings.navigation.Capacity },
        children: [
          {
            path: '',
            name: 'org-efficiency',
            component: reactOrgEfficiency,
            meta: {
              title: strings.metaTitle + strings.general.Organization + strings.navigation.Capacity,
            },
          },
          {
            path: 'costs/',
            name: 'cumulative-costs',
            props: true,
            component: cumulativeCosts,
            meta: { title: 'Fairwinds Insights | Costs' },
          },
          {
            path: 'right-sizing/',
            name: 'right-sizing',
            props: true,
            component: rightSizing,
            meta: { title: 'Fairwinds Insights | Right-Sizing' },
          },
          {
            path: strings.noTranslate.settings,
            name: 'costs-settings',
            component: cumulativeCostsSettings,
            meta: { title: 'Fairwinds Insights | Costs Settings' },
          },
        ],
      },
      {
        path: 'admission-requests/',
        name: strings.AdmissionRequests,
        meta: { title: strings.metaTitle + strings.general.Admission },
        component: reactAdmissionRequests,
        children: [],
      },
      {
        path: 'trial-expired',
        name: 'trial-expired',
        component: trialExpired,
        meta: { title: strings.metaTitle + strings.trialExpired },
        beforeEnter: (to, from, next) => {
          const organization = store.getters?.organization;
          if (organization && !organization.Tier) {
            return next();
          }
          return next({ path: `/orgs/${organization.Name}` });
        },
      },
      {
        path: 'strict-org',
        name: 'strict-org',
        component: strictOrgPage,
        meta: { title: strings.metaTitle + 'Strict Organization' }
      },
    ],
  },
];

import React, { useMemo } from 'react';
import { Badge } from 'react-bootstrap';
import clsx from 'clsx';

import { getSeverityLabel } from '~utils/helpers';

import { strings } from '~utils/strings';
import { XSText } from '~utils/texts.react';

import './PillBadge.react.scss';
interface IPillBadgeProps {
  severity?: number;
  text?: string;
  color?: string;
}

const PillBadge = ({ severity, text, color }: IPillBadgeProps): JSX.Element => {
  const severityLabel = useMemo((): string | undefined => getSeverityLabel(severity), [severity]);

  const createClassname = (className: string) =>
    clsx(className, 'pill--badges', XSText({ textTransform: strings.textStyling.capitalize }));

  return (
    <>
      {(color && text) || color ? (
        <Badge pill className={createClassname(color)}>
          {text}
        </Badge>
      ) : null}
      {text && !color ? (
        <Badge pill className={createClassname(text.toLowerCase())}>
          {text}
        </Badge>
      ) : null}
      {severityLabel ? (
        <Badge pill className={createClassname(severityLabel)}>
          {severityLabel}
        </Badge>
      ) : null}
    </>
  );
};

export default PillBadge;

import * as React from 'react';
import { SVGProps } from 'react';
import { COLORS } from '~utils/styling';

const InstallHubIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={19} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M19.84 6.3a.67.67 0 0 0-.52-.25h-1.75L15.94.48A.67.67 0 0 0 15.3 0H4.7a.67.67 0 0 0-.64.48L2.44 6.05H.68a.67.67 0 0 0-.67.8l2.33 10.98a.67.67 0 0 0 .67.53h13.98a.67.67 0 0 0 .67-.53l2.33-10.98a.67.67 0 0 0-.15-.56zM5.19 1.32h9.61l1.38 4.72H3.82L5.2 1.33zm11.25 15.69H3.54L1.5 7.36h17l-2.06 9.66z"
      fill={COLORS.CORE.WHITE}
    />
    <path
      d="M5.98 9.35a.67.67 0 0 0-.66.66v4.14a.67.67 0 1 0 1.33 0V10a.67.67 0 0 0-.67-.66zm4.02 0a.67.67 0 0 0-.66.66v4.14a.67.67 0 1 0 1.33 0V10a.67.67 0 0 0-.67-.66zm4.02 0a.67.67 0 0 0-.67.66v4.14a.67.67 0 0 0 1.34 0V10a.67.67 0 0 0-.67-.66z"
      fill={COLORS.CORE.WHITE}
    />
  </svg>
);

export default InstallHubIcon;

import Vue from 'vue';
import Vuex from 'vuex';
import { getCurrentUser, sendRequestMixin } from '@/utils/request';
import { REPORTS_DETAILS, DEPRECATED_REPORTS } from '@/utils/constants';
import { strings } from '~utils/strings';
import {
  REMOVE_CURRENT_USER,
  REMOVE_ORG_REPOSITORY,
  REMOVE_REPORT_CONFIGS,
  SET_APP_READY,
  SET_AUTH_STEP,
  SET_CLUSTER,
  SET_CLUSTER_SUMMARY,
  SET_CLUSTERS,
  SET_CURRENT_USER,
  SET_MEMBERSHIPS,
  SET_ORG_REPOSITORIES,
  SET_ORGANIZATION,
  SET_REMOVED_REPORTS,
  SET_REPORTS_CONFIGS,
  SET_REPORTS_STATUS,
  SET_REPOSITORY,
  SET_TIERS,
  SET_VULNERABILITIES,
  TOGGLE_NAV_SIZE,
  TOGGLE_TOAST,
  UPDATE_CLUSTER,
  UPDATE_CURRENT_USER,
  UPDATE_MEMBERSHIPS,
  UPDATE_ORG,
  UPDATE_REPORTS_CONFIGS,
  UPDATE_TIERS,
  SET_STRICT_SAML_ORGS,
  TOGGLE_ADD_REPO_MODAL,
  TOGGLE_REPO_SETTINGS_MODAL,
  SET_IS_ERROR_PAGE_SHOWN,
  TOGGLE_ADD_APP_GROUP_MODAL,
} from './action.types';

Vue.use(Vuex);
function reportInstalled(state, report) {
  return state.reportsStatus[report]?.Enabled;
}

function reportConfigured(state, report) {
  return state.reportsConfigs[report] || REPORTS_DETAILS[report]?.required;
}

async function getRepositories(context, org) {
  try {
    const repositories = await sendRequestMixin.methods.sendRequest(
      'GET',
      `/v0/organizations/${org}/ci/repositories`,
    );
    context.commit(SET_ORG_REPOSITORIES, repositories);
  } catch (e) {
    context.commit(SET_ORG_REPOSITORIES, []);
  }
}

export default new Vuex.Store({
  state: {
    organization: {},
    clusters: null,
    cluster: null,
    clusterSummary: null,
    tiers: null,
    memberships: [],
    membership: {},
    user: null,
    vulnerabilities: [],
    reportsConfigs: {},
    reportsStatus: {},
    removedReports: [],
    repositories: [],
    repository: null,
    auth: {
      activeStep: 1,
    },
    appReady: false,
    isNavBarCollapsed: false,
    toast: {
      message: '',
      position: 'b-toaster-top-center',
      type: '',
    },
    orgMatch: true,
    strictSAMLOrgs: [],
    isAddRepoModalShown: false,
    isRepoSettingsModalShown: false,
    isErrorPageShown: false,
    isAddAppGroupModalShown: false,
  },
  mutations: {
    [SET_APP_READY](state, status) {
      state.appReady = status;
    },
    [SET_MEMBERSHIPS](state, memberships) {
      state.memberships = memberships;
    },
    [SET_ORGANIZATION](state, newOrg) {
      state.organization = newOrg;
    },
    [SET_REPOSITORY](state, newRepository) {
      state.repository = newRepository;
    },
    [SET_CLUSTERS](state, clusters) {
      state.clusters = clusters?.sort((c1, c2) => (c1.Name < c2.Name ? -1 : 1));
    },
    [SET_ORG_REPOSITORIES](state, repositories) {
      state.repositories = repositories;
    },
    [SET_CLUSTER](state, cluster) {
      state.cluster = cluster;
    },
    [SET_CLUSTER_SUMMARY](state, summary) {
      state.clusterSummary = summary;
    },
    [SET_TIERS](state, summary) {
      state.tiers = summary;
    },
    [SET_VULNERABILITIES](state, vulnerabilities) {
      state.vulnerabilities = vulnerabilities;
    },
    [SET_CURRENT_USER](state, user) {
      state.user = user;
    },
    [SET_REPORTS_CONFIGS](state, reportsConfigs) {
      state.reportsConfigs = reportsConfigs;
    },
    [SET_REPORTS_STATUS](state, reportsStatus) {
      state.reportsStatus = reportsStatus;
    },
    [SET_AUTH_STEP](state, activeStep) {
      state.auth.activeStep = activeStep;
    },
    [REMOVE_CURRENT_USER](state) {
      state.user = null;
      state.memberships = [];
    },
    [SET_REMOVED_REPORTS](state, reports) {
      state.removedReports = reports;
    },
    [TOGGLE_NAV_SIZE](state, collapsed) {
      window.localStorage.setItem('isNavCollapsed', collapsed);
      state.isNavBarCollapsed = collapsed;
    },
    [TOGGLE_TOAST](state, toast) {
      state.toast = { ...state.toast, ...toast };
    },
    [SET_STRICT_SAML_ORGS](state, orgs) {
      state.strictSAMLOrgs = orgs || [];
    },
    [TOGGLE_ADD_REPO_MODAL](state, isShown) {
      state.isAddRepoModalShown = isShown;
    },
    [TOGGLE_REPO_SETTINGS_MODAL](state, isShown) {
      state.isRepoSettingsModalShown = isShown;
    },
    [SET_IS_ERROR_PAGE_SHOWN](state, isErrorPageShown) {
      state.isErrorPageShown = isErrorPageShown;
    },
    [TOGGLE_ADD_APP_GROUP_MODAL](state, isShown) {
      state.isAddAppGroupModalShown = isShown;
    },
  },

  getters: {
    isAppReady(state) {
      return state.appReady;
    },
    memberships(state) {
      return state.memberships;
    },
    organization(state) {
      return state.organization;
    },
    organizationTierAccess(state) {
      if (!state.tiers || !state.organization || state.organization.Tier === undefined) {
        return {};
      }
      return state.tiers[state.organization.Tier].Access;
    },
    organizationTier(state) {
      if (!state.tiers || !state.organization || state.organization.Tier === undefined) {
        return {};
      }
      return state.tiers[state.organization.Tier];
    },
    organizationTiers(state) {
      return state.tiers;
    },
    repository(state) {
      return state.repository;
    },
    clusters(state) {
      return state.clusters;
    },
    clusterSummary(state) {
      return state.clusterSummary;
    },
    cluster(state) {
      return state.cluster;
    },
    tiers(state) {
      return state.tiers;
    },
    vulnerabilities(state) {
      return state.vulnerabilities;
    },
    user(state) {
      return state.user;
    },
    isOrgOwner(state, getters) {
      return getters.user?.IsSuperDuperAdmin || (getters.user && getters.membership?.IsOwner);
    },
    activeStep(state) {
      return state.auth.activeStep;
    },
    reportsConfigs(state) {
      return state.reportsConfigs;
    },
    reportsStatus(state) {
      return state.reportsStatus;
    },
    removedReports(state) {
      return state.removedReports;
    },
    repositories(state) {
      return state.repositories;
    },
    isNavBarCollapsed(state) {
      return state.isNavBarCollapsed;
    },
    strictSAMLOrgs(state) {
      return state.strictSAMLOrgs;
    },

    reportsWithHistory(state) {
      if (!state.cluster) return [];
      return Object.keys(REPORTS_DETAILS).filter(
        (report) => state.reportsStatus[report] && state.reportsStatus[report].LastSeen,
      );
    },
    installedReports(state) {
      if (!state.cluster) return [];
      return Object.keys(REPORTS_DETAILS).filter((report) => reportInstalled(state, report));
    },
    notInstalledReports(state) {
      if (!state.cluster) return [];
      return Object.keys(REPORTS_DETAILS).filter((report) => !reportInstalled(state, report));
    },
    installingReports(state) {
      if (!state.cluster) return [];
      return Object.keys(REPORTS_DETAILS).filter(
        (report) => reportConfigured(state, report) && !reportInstalled(state, report),
      );
    },
    uninstallingReports(state) {
      if (!state.cluster) return [];
      return Object.keys(REPORTS_DETAILS).filter(
        (report) => !reportConfigured(state, report) && reportInstalled(state, report),
      );
    },
    uninstalledReports(state, getters) {
      const withHistory = getters.reportsWithHistory;
      const installed = getters.installedReports;
      return withHistory.filter((r) => installed.indexOf(r) === -1);
    },
    neverInstalledReports(state, getters) {
      const withHistory = getters.reportsWithHistory;
      const notInstalled = getters.notInstalledReports;
      return notInstalled.filter((r) => withHistory.indexOf(r) === -1);
    },
    toast(state) {
      return state.toast;
    },
    membership(state) {
      if (state.memberships && state.organization) {
        return state.memberships.find(
          (membership) => membership.Organization === state.organization.Name,
        );
      }
      return false;
    },
    isAddRepoModalShown(state) {
      return state.isAddRepoModalShown;
    },
    isRepoSettingsModalShown(state) {
      return state.isRepoSettingsModalShown;
    },
    isErrorPageShown(state) {
      return state.isErrorPageShown;
    },
    isAddAppGroupModalShown(state) {
      return state.isAddAppGroupModalShown;
    },
  },

  actions: {
    async [UPDATE_MEMBERSHIPS](context, membershipData) {
      if (!membershipData) {
        // eslint-disable-next-line no-param-reassign
        membershipData = await getCurrentUser();
      }
      const { additionalSAMLOrgs, memberships, user } = membershipData;
      context.commit(SET_CURRENT_USER, user);
      context.commit(SET_MEMBERSHIPS, memberships);
      context.commit(SET_STRICT_SAML_ORGS, additionalSAMLOrgs);
    },
    async [UPDATE_CLUSTER](context, cluster) {
      context.commit(SET_IS_ERROR_PAGE_SHOWN, false);
      if (!cluster) {
        context.commit(SET_CLUSTER, null);
        context.commit(SET_CLUSTER_SUMMARY, null);
        return;
      }
      let clusterDetails = null;
      let configs = {};
      let reportsStatus = {};
      try {
        [clusterDetails, configs, reportsStatus] = await Promise.all([
          sendRequestMixin.methods.sendRequest(
            'GET',
            `/v0/organizations/${
              context.state?.organization?.Name || context.state.cluster.Organization
            }/clusters/${cluster}/overview`,
          ),
          sendRequestMixin.methods.sendRequest(
            'GET',
            `/v0/organizations/${
              context.state?.organization?.Name || context.state.cluster.Organization
            }/clusters/${cluster}/reports/configs`,
          ),
          sendRequestMixin.methods.sendRequest(
            'GET',
            `/v0/organizations/${
              context.state?.organization?.Name || context.state.cluster.Organization
            }/clusters/${cluster}/reports`,
          ),
        ]);
      } catch (err) {
        context.commit(SET_CLUSTER, null);
        context.commit(SET_CLUSTER_SUMMARY, null);
        throw err;
      }
      const configObj = configs.reduce((obj, item) => ({ ...obj, [item.ReportType]: item }), {});
      // Removing any deprecated reports from the list of reports
      DEPRECATED_REPORTS.forEach((report) => delete configObj[report]);
      const statusObj = reportsStatus.reduce((obj, item) => ({ ...obj, [item.Name]: item }), {});
      context.commit(SET_CLUSTER, clusterDetails.Cluster);
      context.commit(SET_CLUSTER_SUMMARY, clusterDetails);
      context.commit(SET_REPORTS_CONFIGS, configObj);
      context.commit(SET_REPORTS_STATUS, statusObj);
      context.commit(SET_REMOVED_REPORTS, []);
    },
    async [UPDATE_ORG](context, org) {
      if (!org) {
        context.commit(SET_CLUSTERS, null);
        context.commit(SET_ORGANIZATION, null);
        context.commit(SET_ORG_REPOSITORIES, []);
        return;
      }
      let clusters = null;
      let orgDetails = null;
      try {
        [orgDetails, clusters] = await Promise.all([
          sendRequestMixin.methods.sendRequest('GET', `/v0/organizations/${org}`),
          sendRequestMixin.methods.sendRequest('GET', `/v0/organizations/${org}/cluster-summary`),
        ]);
      } catch (err) {
        context.commit(SET_CLUSTERS, null);
        context.commit(SET_ORGANIZATION, null);
        context.commit(SET_ORG_REPOSITORIES, []);
        if (err.status === 402) {
          context.commit(SET_ORGANIZATION, { Name: org, Tier: 0, isOrgsRedirected: true });
          return;
        }
        return;
      }
      getRepositories(context, org);
      context.commit(SET_CLUSTERS, clusters);
      context.commit(SET_ORGANIZATION, orgDetails);
      localStorage.setItem(strings.localStorage.organization, orgDetails.Name);
    },
    async [UPDATE_TIERS](context) {
      const data = await sendRequestMixin.methods.sendRequest('GET', '/v0/tiers');
      context.commit(SET_TIERS, data);
    },
    [UPDATE_CURRENT_USER](context, user) {
      context.commit(SET_CURRENT_USER, user);
    },
    async [UPDATE_REPORTS_CONFIGS](context, payload) {
      const { report, options = {}, method = 'POST' } = payload;
      await sendRequestMixin.methods.sendRequest(
        method,
        `/v0/organizations/${context.state.organization.Name}/clusters/${context.state.cluster.Name}/reports/${report}/configs`,
        { data: options, showSuccessAlert: false },
      );
      const configs = await sendRequestMixin.methods.sendRequest(
        'GET',
        `/v0/organizations/${context.state.organization.Name}/clusters/${context.state.cluster.Name}/reports/configs`,
      );
      const configObj = configs.reduce((obj, item) => ({ ...obj, [item.ReportType]: item }), {});
      const removed = context.state.removedReports.filter((key) => key !== report);
      context.commit(SET_REPORTS_CONFIGS, configObj);
      context.commit(SET_REMOVED_REPORTS, removed);
    },

    async [REMOVE_REPORT_CONFIGS](context, report) {
      const { Options = {} } = context.state.reportsConfigs[report];
      await sendRequestMixin.methods.sendRequest(
        'DELETE',
        `/v0/organizations/${context.state.organization.Name}/clusters/${context.state.cluster.Name}/reports/${report}/configs`,
        { data: Options, showSuccessAlert: false },
      );
      const configs = await sendRequestMixin.methods.sendRequest(
        'GET',
        `/v0/organizations/${context.state.organization.Name}/clusters/${context.state.cluster.Name}/reports/configs`,
      );
      const configObj = configs.reduce((obj, item) => ({ ...obj, [item.ReportType]: item }), {});
      context.commit(SET_REPORTS_CONFIGS, configObj);
      context.commit(SET_REMOVED_REPORTS, [...context.state.removedReports, report]);
    },
    async [REMOVE_ORG_REPOSITORY](context, repo) {
      await sendRequestMixin.methods.sendRequest(
        'DELETE',
        `/v0/organizations/${repo.Organization}/ci/repositories?repository=${repo.Name}`,
        { showSuccessAlert: false },
      );
      const repos = context.state.repositories.filter((repository) => repository.ID !== repo.ID);
      context.commit(SET_ORG_REPOSITORIES, repos);
    },
    async [TOGGLE_TOAST](context, payload) {
      context.commit(TOGGLE_TOAST, payload);
    },
  },
});

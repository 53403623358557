<template src="./App.pug" lang="pug"></template>
<script>
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { init, WidgetOptionItemType } from '@datadog/ui-apps-sdk';
import { mapGetters } from 'vuex';
import initPlugins from '@/utils/plugins';
import { getCurrentUser } from '@/utils/request';
import { getCurrentTimezone } from '~reactHelpers';
import { IS_PLG_UPGRADE_MODAL_SHOW } from '~utils/constants';
// Note: We need to bring in vexilla at the start of the app
//           so that it can initiate its fetch as soon as possible
// eslint-disable-next-line
import LeftNavigation from './reactComponents/NavigationReact/LeftNavigation/LeftNavigation.react';
import TopNavigation from './reactComponents/NavigationReact/TopNavigation/TopNavigation.react';
import Footer from './reactComponents/NavigationReact/Footer/Footer.react';
import MessageDialog from './reactComponents/MessageDialog/MessageDialog.react';
import toast from './components/toasts/index.vue';
import { UPDATE_CLUSTER, UPDATE_ORG } from './store/action.types';

dayjs.extend(utc);
dayjs.extend(timezone);

export default {
  components: {
    toast,
    'left-navigation': LeftNavigation,
    'top-navigation': TopNavigation,
    'bottom-footer': Footer,
    'message-dialog': MessageDialog,
  },
  data: () => ({
    embed: false,
    datadogWidget: false,
    isFreeTierDisabled: localStorage.getItem('isFreeTierDisabled'),
  }),
  computed: {
    ...mapGetters(['repositories', 'organization', 'clusters', 'cluster', 'repository', 'memberships', 'user', 'isAppReady', 'isNavBarCollapsed', 'reportsStatus', 'reportsConfigs']),
    isAuthRegisterPage() {
      return this.$route.path.startsWith('/auth/register') || this.$route.path.startsWith('/datadog-activation');
    },
    selfHosted() {
      return window.insightsConfig && window.insightsConfig.selfHosted;
    },
    isLoginPage() {
      return this.$route.path.startsWith('/auth/login') || this.$route.path.startsWith('/auth/sso');
    },
    isAuthPage() {
      return this.isAuthRegisterPage || this.isLoginPage;
    },
    showNavigationPanel() {
      return !this.embed && !this.isAuthPage && !this.$route.query.forceLogin && !this.$route.path.includes('download-report') && this.$route.path !== '/orgs' && this.$route.name !== 'action-items-reports-print-preview';
    },
    showBottomFooter() {
      return !this.isAuthPage && this.$route.name !== 'action-items-reports-print-preview' && this.$route.name !== 'action-items-cluster-reports-print-preview';
    },
    isTesting() {
      return localStorage.getItem('isTesting');
    },
    isDownloadPage() {
      return this.$route.name === 'download-report';
    },
    isUpgradeModalShown() {
      return (localStorage.getItem(IS_PLG_UPGRADE_MODAL_SHOW) || this.organization?.Tier === 0) && this.$route.path === '/orgs';
    },
    apiOverride() {
      return localStorage.getItem('api_host');
    },
    currentYear() {
      return new Date().getFullYear();
    },
  },
  created() {
    if (window !== window.top) {
      this.initDatadog();
    }
  },
  watch: {
    organization() {
      initPlugins();
      this.setDatadogOptions();
    },
    clusters() {
      this.setDatadogOptions();
    },
    memberships() {
      this.setDatadogOptions();
    },
  },
  methods: {
    async initDatadog() {
      let ddContext = null;
      try {
        this.datadog = init({
          debug: process.env.NODE_ENV !== 'production',
          authProvider: {
            url: '/auth/login',
            authStateCallback: async () => {
              const login = await getCurrentUser();
              return {
                isAuthenticated: !!login.user,
                args: { ...login },
              };
            },
          },
        });
        this.datadogWidget = true;
        ddContext = await this.datadog.getContext();
      } catch (e) {
        // Unhandled - this means we're not connected to datadog
      }
      this.datadog.events.on('dashboard_custom_widget_options_change', async (options) => {
        if (options.org) {
          await this.$store.dispatch(UPDATE_ORG, options.org);
        }
        if (options.cluster) {
          await this.$store.dispatch(UPDATE_CLUSTER, options.cluster);
          this.$router.push({
            params: options,
            name: 'datadog-action-items',
          });
        }
      });

      if (ddContext && !this.organization?.Name && !this.cluster?.Name) {
        this.embed = true;
        if (ddContext.widget) {
          const { options } = ddContext.widget.definition;
          await this.$store.dispatch(UPDATE_ORG, options.org);
          await this.$store.dispatch(UPDATE_CLUSTER, options.cluster);

          this.$router.push({
            params: options,
            name: 'datadog-action-items',
          });
        }
      }
      window.datadog = this.datadog;
    },
    setDatadogOptions() {
      if (!this.datadog) return;
      this.datadog.dashboard.customWidget.updateOptions([{
        type: WidgetOptionItemType.STRING,
        name: 'org',
        label: 'Your organization',
        enum: this.memberships?.map((m) => m.Organization),
        order: 1,
      }, {
        type: WidgetOptionItemType.STRING,
        name: 'cluster',
        label: 'Your cluster',
        enum: this.clusters?.map((c) => c.Name),
        order: 1,
      }]).catch(() => {
        // Ignore errors
      });
    },
    datePast(d) {
      return new Date(d) < new Date();
    },
    daysUntil(d) {
      const timeZone = getCurrentTimezone();
      const now = timeZone ? dayjs().utc().tz(timeZone) : dayjs().utc();
      const then = timeZone ? dayjs(d).utc().tz(timeZone) : dayjs().utc();
      return Math.round(then.diff(now, 'days', true));
    },
    daysUntilMessage(d) {
      const days = this.daysUntil(d);
      let message = `${days} day`;
      if (days !== 1) {
        message += 's';
      }
      return message;
    },
    dateUpcoming(d) {
      return this.daysUntil(d) <= 3;
    },
    route() {
      return this.$route;
    },
    router() {
      return this.$router;
    },
    store() {
      return this.$store;
    },
    onPLGModalClicked() {
      window.location.href = 'https://fairwinds.com';
    },
  },
};
</script>
<style src="./assets/fonts/poppins/stylesheet.css"></style>
<style src="./App.scss" lang="scss"></style>

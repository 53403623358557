import * as React from 'react';
import { SVGProps } from 'react';
import { COLORS } from '~utils/styling';

const RepositoriesIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M5.63 1A2.64 2.64 0 0 0 3 3.61a2.6 2.6 0 0 0 1.82 2.46v7.86A2.63 2.63 0 0 0 3 16.4C3 17.83 4.18 19 5.63 19a2.63 2.63 0 0 0 2.64-2.6c0-1.14-.77-2.13-1.83-2.47v-.13c0-1.18.37-1.65 1.05-2.07.68-.42 1.74-.67 2.9-.95a9.27 9.27 0 0 0 3.43-1.35 4.43 4.43 0 0 0 1.73-3.35A2.61 2.61 0 0 0 17.4 3.6a2.64 2.64 0 0 0-5.27 0c0 1.14.76 2.11 1.8 2.46-.08 1.1-.48 1.65-1.08 2.09-.68.5-1.71.8-2.84 1.07s-2.35.51-3.38 1.15l-.19.12V6.07a2.6 2.6 0 0 0 1.83-2.46C8.27 2.2 7.07 1 5.63 1zm0 1.6a1 1 0 0 1 1.02 1.01c0 .56-.45.99-1.02.99a.98.98 0 0 1-1.01-.99 1 1 0 0 1 1.01-1zm9.13 0a1 1 0 0 1 1.02 1.01c0 .56-.45.99-1.02.99a.98.98 0 0 1-1.01-.99 1 1 0 0 1 1.01-1zM5.63 15.4a1 1 0 0 1 1.02 1c0 .57-.45 1-1.02 1a.98.98 0 0 1-1.01-1 1 1 0 0 1 1.01-1z"
      fill={COLORS.CORE.WHITE}
    />
  </svg>
);

export default RepositoriesIcon;

import { Route, ExpandableItem } from './Navigation.config.react';
import { IStore, IRouter } from '~globalTypes';
import { strings } from '~utils/strings';

export const routeHref = (router: () => IRouter, navRoute?: Route) => {
  // handle the case for expandable menus
  if (!navRoute) {
    return {};
  }
  switch (navRoute.type) {
    case strings.noTranslate.link:
      return {
        href: navRoute.name,
        newTab: true,
      };
    case 'route':
      return {
        href: router().resolve({
          name: navRoute.name,
          params: {
            org:
              navRoute.params?.org || localStorage.getItem(strings.localStorage.organization) || '',
          },
        })?.href,
        newTab: false,
      };
    default:
      return {
        href: '#',
        newTab: false,
      };
  }
};

export const assertExpandableItemDependencies = (
  store: () => IStore,
  itemRoute: ExpandableItem['route'],
): boolean => {
  if (itemRoute?.dependencies) {
    return itemRoute?.dependencies.every((dependency) => {
      switch (dependency) {
        case 'org':
          return store().getters.organization?.Name;
        case 'cluster':
          return store().getters.cluster?.Name;
        case 'isSuperAdmin':
          return store().getters.user?.IsSuperAdmin;
        case 'user':
          return store().getters.user;
        case 'loggedOut':
          return !store().getters.user;
        default:
          console.error(`Unknown dependency type`, dependency);
          return false;
      }
    });
  }
  return true;
};

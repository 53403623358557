import * as React from 'react';
import { SVGProps } from 'react';
import { COLORS } from '~utils/styling';

const HelpIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx={10} cy={10} r={8.25} stroke={COLORS.CORE.WHITE} strokeWidth={1.5} />
    <path
      d="M7.66 5.52c.34-.2.7-.34 1.07-.43a4.8 4.8 0 0 1 1.08-.13c.6 0 1.13.1 1.58.3.46.2.82.47 1.07.84.25.35.38.77.38 1.24 0 .42-.08.8-.24 1.12a3.6 3.6 0 0 1-.63.9c-.25.26-.5.53-.77.78a5.8 5.8 0 0 0-.67.81c-.2.28-.31.6-.35.95l-.07.4H9.06v-.59c0-.44.06-.82.18-1.15.14-.32.3-.61.5-.86.19-.25.38-.49.57-.7.2-.24.36-.47.49-.7.13-.24.2-.5.2-.8 0-.34-.11-.6-.34-.78a1.54 1.54 0 0 0-.98-.28 4.1 4.1 0 0 0-2.02.6V5.53zm.92 9.8v-2h2.03v2H8.58z"
      fill={COLORS.CORE.WHITE}
    />
  </svg>
);

export default HelpIcon;

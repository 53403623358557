import React from 'react';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import clsx from 'clsx';

import SupportIcon from '~assetIcons/support.svg';

import { IRouter } from '~utils/global.types.react';

import { handlePageChange, getCurrentTimezone } from '~utils/global.helpers.react';
import { strings } from '~utils/strings';
import { MediumBasicText } from '~utils/texts.react';

import './Footer.style.react.scss';

dayjs.extend(timezone);

type FooterProps = {
  isNavBarCollapsed: boolean;
  router: () => IRouter;
};

const Footer = ({ isNavBarCollapsed, router }: FooterProps) => {
  const timeZone = getCurrentTimezone();

  return (
    <footer
      className={`main-footer ${isNavBarCollapsed ? 'footer__collapsed' : ''}`}
      aria-label="site"
    >
      <div className="support-button-div">
        <button
          className={clsx('support-bubble', MediumBasicText({ color: strings.textStyling.white }))}
          id="support-bubble"
          aria-label="go to the support page"
          onClick={() => handlePageChange(router, 'contact-us')}
        >
          <img alt="Contact" title="Contact" src={SupportIcon} />
          Support
        </button>
      </div>
      <div className="footer-content">
        <p>
          &copy; {strings.Fairwinds} {timeZone ? dayjs().tz(timeZone).year() : dayjs().year()}
        </p>
        <p>
          <a href="https://www.fairwinds.com/privacy-policy" target="_blank">
            {strings.navigation.privacyPolicy}
          </a>
        </p>
        <p>
          <a href="https://insights.fairwinds.com/open-source" target="_blank">
            {strings.navigation.openSource}
          </a>
        </p>
        <p>
          <a href="https://www.fairwinds.com/insights-terms-and-conditions" target="_blank">
            {strings.navigation.termsConditions}
          </a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;

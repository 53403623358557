import * as React from 'react';
import { SVGProps } from 'react';
import { COLORS } from '~utils/styling';

const OrgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M17.41 10.46a2.35 2.35 0 1 0-3.57 0c-.24.14-.46.31-.66.5A4.06 4.06 0 0 0 12 9.94a2.9 2.9 0 0 0-2-5.04 2.92 2.92 0 0 0-2 5.03c-.46.26-.86.62-1.18 1.04-.2-.2-.42-.37-.66-.51a2.35 2.35 0 1 0-3.57 0A3.48 3.48 0 0 0 .9 13.44v1.12c0 .37.3.66.66.66h16.88c.36 0 .66-.3.66-.66v-1.12c0-1.27-.68-2.37-1.69-2.98zM14.6 8.94a1.03 1.03 0 1 1 2.05 0 1.03 1.03 0 0 1-2.05 0zM8.41 7.8a1.59 1.59 0 1 1 3.18 0 1.59 1.59 0 0 1-3.18 0zM3.35 8.94a1.03 1.03 0 1 1 2.05 0 1.03 1.03 0 0 1-2.05 0zm-1.13 4.5a2.15 2.15 0 0 1 3.93-1.2c-.12.37-.19.78-.19 1.2v.46H2.23v-.46zm5.07 0a2.72 2.72 0 0 1 5.42 0v.46H7.3v-.46zm10.48.46h-3.73v-.46c0-.42-.07-.83-.19-1.2a2.15 2.15 0 0 1 3.92 1.2v.46z"
      fill={COLORS.CORE.WHITE}
      stroke={COLORS.CORE.WHITE}
      strokeWidth={0.2}
    />
  </svg>
);

export default OrgIcon;

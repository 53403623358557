// eslint-disable-next-line no-unused-vars
import React from 'react';
import type { VariantProps } from 'class-variance-authority';
// eslint-disable-next-line import/no-extraneous-dependencies
import { cva, cx } from 'class-variance-authority';
import { strings } from './strings';

import './scss/font-styles.scss';

export type BaseProps = VariantProps<typeof Base>;
export const Base = cva('text-base', {
  variants: {
    weight: {
      thin: 'thin-weight', // 300
      regular: 'regular-weight', // 400
      medium: 'medium-weight', // 500
      semibold: 'semibold-weight', // 600
      bold: 'bold-weight', // 700
    },
    bottomMargin: {
      bottomXL: 'bottom-xl', // 2rem
      bottomLG: 'bottom-lg', // 1.5rem
      bottomMD: 'bottom-md', // 1rem
      bottomSM: 'bottom-sm', // 0.75rem
      bottomXS: 'bottom-xs', // 10px
      none: 'bottom-none', // 0
    },
    topMargin: {
      xl: 'top-xl', // 2rem
      lg: 'top-lg', // 1.5rem
      md: 'top-md', // 10px
      sm: 'top-sm', // 5px
      none: 'top-none', // 0
    },
    color: {
      primary: 'color-primary',
      default: 'color-default',
      white: 'color-white',
      link: 'color-link',
      darkGray: 'color-dark-gray',
      danger: 'color-danger',
      tableHeader: 'color-table-header',
      dropdown: 'color-dropdown-font',
      success: 'color-success',
    },
    textTransform: {
      capitalize: strings.textStyling.capitalize,
      noCapitalize: 'no-capitalize',
      uppercase: 'uppercase',
      italic: 'italicize',
    },
  },
  defaultVariants: {
    weight: strings.textStyling.regular, //400
    bottomMargin: 'none',
    topMargin: 'none',
    color: strings.textStyling.default, // black
    textTransform: 'noCapitalize',
  },
});

export type TitleSizeProps = VariantProps<typeof TitleSize>;
export const TitleSize = cva('', {
  variants: {
    size: {
      xxl: 'title-xxl', // 4.5rem
      xl: 'title-xl', // 3.75rem
      lg: 'title-lg', // 3rem
      md: 'title-md', // 2.25rem
      sm: 'title-sm', // 1.875rem
      xs: 'title-xs', // 1.5rem
    },
  },
  defaultVariants: {
    size: strings.textStyling.lg, // 3rem
  },
});

export type TextSizeProps = VariantProps<typeof TextSize>;
export const TextSize = cva('', {
  variants: {
    size: {
      xl: 'text-xl', // 1.25rem
      lg: 'text-lg', // 1.125rem
      md: 'text-md', // 1rem
      sm: 'text-sm', // 0.875rem, 1.25rem line height
      extraSpacing: 'text-sm-extra-spacing', // 0.875rem, 2.5rem line height
      xs: 'text-xs', // 0.75rem, 1.125rem line height
      xxs: 'text-xxs', // 0.75rem, 1rem line height
    },
  },
  defaultVariants: {
    size: strings.textStyling.xl, // 1.25rem
  },
});

export interface TitleProps extends BaseProps, TitleSizeProps {}

export interface TextProps extends BaseProps, TextSizeProps {}

/*         Titles               */

// defaults: font-size: 3rem, default variants
export const Title = (props?: TitleProps) => {
  if (props) {
    const { weight, color, textTransform, bottomMargin, topMargin, size } = props;
    return cx(Base({ weight, color, textTransform, bottomMargin, topMargin }), TitleSize({ size }));
  } else {
    return cx(Base(), TitleSize());
  }
};

// defaults: font-size: 1.5rem, weight: 300, capitalized, default variants
export const ThinTitle = (props?: TitleProps) => {
  if (props) {
    const {
      weight = strings.textStyling.thin,
      color,
      textTransform = strings.textStyling.capitalize,
      bottomMargin,
      topMargin,
      size = strings.textStyling.xs,
    } = props;
    return cx(Base({ weight, color, textTransform, bottomMargin, topMargin }), TitleSize({ size }));
  } else {
    return cx(
      Base({ weight: strings.textStyling.thin, textTransform: strings.textStyling.capitalize }),
      TitleSize({ size: strings.textStyling.xs }),
    );
  }
};

// defaults: font-size: 1.25rem, color: primary/purple, default variants
export const CardTitlePrimary = (props?: TextProps) => {
  if (props) {
    const {
      weight,
      color = strings.textStyling.primary,
      textTransform,
      bottomMargin,
      topMargin,
      size,
    } = props;
    return cx(Base({ weight, color, textTransform, bottomMargin, topMargin }), TextSize({ size }));
  } else {
    return cx(Base({ color: strings.textStyling.primary }), TextSize());
  }
};

// defaults: font-size: 1.25rem, weight 500, default variants
export const CardTitleDefault = (props?: TextProps) => {
  if (props) {
    const {
      weight = strings.textStyling.medium,
      color,
      textTransform,
      bottomMargin,
      topMargin,
      size,
    } = props;
    return cx(Base({ weight, color, textTransform, bottomMargin, topMargin }), TextSize({ size }));
  } else {
    return cx(Base({ weight: strings.textStyling.medium }), TextSize());
  }
};

// defaults: bottom (1rem) & top (10px) margins: md, default variants
export const CardTitleMargins = (props?: TextProps) => {
  if (props) {
    const {
      weight,
      color,
      textTransform,
      bottomMargin = strings.textStyling.medBottom,
      topMargin = strings.textStyling.md,
      size,
    } = props;
    return cx(Base({ weight, color, textTransform, bottomMargin, topMargin }), TextSize({ size }));
  } else {
    return cx(
      Base({ bottomMargin: strings.textStyling.medBottom, topMargin: strings.textStyling.md }),
      TextSize(),
    );
  }
};

// defaults: font-size: 0.75rem, weight thin, default variants
export const XSCardTitle = (props?: TextProps) => {
  if (props) {
    const {
      weight = strings.textStyling.thin,
      color,
      textTransform,
      bottomMargin,
      topMargin,
      size = strings.textStyling.xs,
    } = props;
    return cx(Base({ weight, color, textTransform, bottomMargin, topMargin }), TextSize({ size }));
  } else {
    return cx(
      Base({ weight: strings.textStyling.thin }),
      TextSize({ size: strings.textStyling.xs }),
    );
  }
};

// defaults: font-size: 0.875rem, color primary, weight 500, default variants
export const SmallCardTitle = (props?: TextProps) => {
  if (props) {
    const {
      weight = strings.textStyling.medium,
      color = strings.textStyling.primary,
      textTransform,
      bottomMargin,
      topMargin,
      size = 'sm',
    } = props;
    return cx(Base({ weight, color, textTransform, bottomMargin, topMargin }), TextSize({ size }));
  } else {
    return cx(
      Base({ weight: strings.textStyling.medium, color: strings.textStyling.primary }),
      TextSize({ size: 'sm' }),
    );
  }
};

/*         Text               */

// defaults: font-size: 0.75rem, default variants
export const XSText = (props?: TextProps) => {
  if (props) {
    const {
      weight,
      color,
      textTransform,
      bottomMargin,
      topMargin,
      size = strings.textStyling.xs,
    } = props;
    return cx(Base({ weight, color, textTransform, bottomMargin, topMargin }), TextSize({ size }));
  } else {
    return cx(Base(), TextSize({ size: strings.textStyling.xs }));
  }
};

// defaults: font-size: 0.875rem, default variants
export const BasicText = (props?: TextProps) => {
  if (props) {
    const { weight, color, textTransform, bottomMargin, topMargin, size = 'sm' } = props;
    return cx(Base({ weight, color, textTransform, bottomMargin, topMargin }), TextSize({ size }));
  } else {
    return cx(Base(), TextSize({ size: 'sm' }));
  }
};

// defaults: font-size: 1rem, weight 500, default variants
export const MediumBasicText = (props?: TextProps) => {
  if (props) {
    const {
      weight = strings.textStyling.medium,
      color,
      textTransform,
      bottomMargin,
      topMargin,
      size = strings.textStyling.md,
    } = props;
    return cx(Base({ weight, color, textTransform, bottomMargin, topMargin }), TextSize({ size }));
  } else {
    return cx(
      Base({ weight: strings.textStyling.medium }),
      TextSize({ size: strings.textStyling.md }),
    );
  }
};

// defaults: font-size: 1.25rem, default variants
export const XLBasicText = (props?: TextProps) => {
  if (props) {
    const {
      weight,
      color,
      textTransform,
      bottomMargin,
      topMargin,
      size = strings.textStyling.xl,
    } = props;
    return cx(Base({ weight, color, textTransform, bottomMargin, topMargin }), TextSize({ size }));
  } else {
    return cx(Base(), TextSize({ size: strings.textStyling.xl }));
  }
};

/*         Specific Components              */

// defaults: font-size: 1rem, link color, default variants
export const LinkText = (props?: TextProps) => {
  if (props) {
    const {
      weight,
      color = strings.textStyling.link,
      textTransform,
      bottomMargin,
      topMargin,
      size = strings.textStyling.md,
    } = props;
    return cx(Base({ weight, color, textTransform, bottomMargin, topMargin }), TextSize({ size }));
  } else {
    return cx(
      Base({ color: strings.textStyling.link }),
      TextSize({ size: strings.textStyling.md }),
    );
  }
};

// defaults: font-size: 0.75rem, color tableHeader, capitalize, default variants
export const ColumnHeaderText = (props?: TextProps) => {
  if (props) {
    const {
      weight,
      color = 'dropdown',
      textTransform = strings.textStyling.capitalize,
      bottomMargin,
      topMargin,
      size = strings.textStyling.xs,
    } = props;
    return cx(Base({ weight, color, textTransform, bottomMargin, topMargin }), TextSize({ size }));
  } else {
    return cx(
      Base({ color: 'dropdown', textTransform: strings.textStyling.capitalize }),
      TextSize({ size: strings.textStyling.xs }),
    );
  }
};

import {
  ACTION_ITEMS_CLUSTER_LISTS,
  ACTION_ITEMS_CLUSTER,
  ACTION_ITEMS_LISTS,
  ACTION_ITEMS,
  CLUSTER_REPORT_HUB,
  CLUSTERS,
  COSTS,
  CUMULATIVE_COSTS,
  CUMULATIVE_COSTS_CLUSTER,
  DATADOG,
  EDITRULE,
  EFFICIENCY,
  IMAGE_DETAIL,
  INTEGRATIONS,
  NEWRULE,
  NODE_EFFICIENCY,
  ORG_DASHBOARD,
  ORG_EFFICIENCY,
  REACT_VULNERABILITIES_ALL_IMAGES_CLUSTER,
  REACT_VULNERABILITIES_ALL_IMAGES,
  REACT_VULNERABILITIES_ALL_VULNERABILITIES_CLUSTER,
  REACT_VULNERABILITIES_ALL_VULNERABILITIES,
  REACT_VULNERABILITIES,
  COSTS_SETTINGS,
  COSTS_SETTINGS_CLUSTER,
  COMPLIANCE,
  AUTOMATION,
  ALL_POLICIES,
  REACT_REPOSITORIES,
  ACTION_ITEMS_CLUSTER_REPORTS,
  ACTION_ITEMS_REPORTS,
  PROFILE,
  EMAIL_NOTIFICATIONS,
  REGION,
  PASSWORD,
  PASSWORD_RESET,
  TEAM_MANAGEMENT,
  NOTIFICATIONS,
  SSO,
  DANGER_ZONE,
  TOKENS,
  NEW_ORG,
  NEW_CLUSTER,
  ADMIN,
  ACTION_ITEMS_CLUSTER_SUMMARY,
  ACTION_ITEMS_SUMMARY,
  ADMISSION_REQUESTS,
  REACT_ADMISSION_REQUESTS_CLUSTER,
  ADMISSION_REQUESTS_CLUSTER,
  RIGHT_SIZING,
  RIGHT_SIZING_CLUSTER,
} from '../Navigation.config.react';

export const costsSettingsVisible = [CUMULATIVE_COSTS, CUMULATIVE_COSTS_CLUSTER, ORG_EFFICIENCY, NODE_EFFICIENCY];

export const noUpdateStickyCluster = [
  ALL_POLICIES,
  REACT_REPOSITORIES,
  COMPLIANCE,
  AUTOMATION,
  INTEGRATIONS,
  PROFILE,
  EMAIL_NOTIFICATIONS,
  REGION,
  PASSWORD,
  PASSWORD_RESET,
  TEAM_MANAGEMENT,
  NOTIFICATIONS,
  SSO,
  DANGER_ZONE,
  TOKENS,
  NEW_ORG,
  NEW_CLUSTER,
  ADMIN,
];

// this object contains all of the checking function when we want to check hide the Add Cluster button
// or hide the cluster configure alert toast message.
// or handle cluster selection/unselection.
export const handlers = {
  includes: (currentRoute: string, checkingRoutes: string[]) => {
    if (!currentRoute || !checkingRoutes?.length) return false;
    for (const route of checkingRoutes) {
      if (route && currentRoute.includes(route)) return true;
    }
    return false;
  },
  equals: (currentRoute: string, checkingRoutes: string[]) => {
    if (!currentRoute || !checkingRoutes?.length) return false;
    for (const route of checkingRoutes) {
      if (route && route === currentRoute) return true;
    }
    return false;
  },
  different: (currentRoute: string, checkingRoutes: string[]) => !handlers.equals(currentRoute, checkingRoutes),
};

// When we want to hide the Add Cluster button on specific routes
// we need to define those routes here
export const hideAddClusterButtonRoutes = [
  {
    name: REACT_VULNERABILITIES,
    handler: handlers.includes,
  },
  {
    name: EFFICIENCY,
    handler: handlers.includes,
  },
  {
    name: COSTS,
    handler: handlers.includes,
  },
  {
    name: ACTION_ITEMS,
    handler: handlers.includes,
  },
  {
    name: ADMISSION_REQUESTS,
    handler: handlers.includes,
  },
];

// on some pages, we will have the clusters dropdown.
// after selecting a cluster from that dropdown, if the selected cluster has not been configured.
// an error message will be shown. If we don't show the error message on specific routes.
// we need to define those routes here.
export const hideClusterConfigureAlertRoutes = [
  {
    name: CLUSTER_REPORT_HUB,
    handler: handlers.equals,
  },
];

export const handlerNames = {
  handleActionItemPagesNavigation: 'handleActionItemPagesNavigation',
  handleActionItemReportsNavigation: 'handleActionItemReportsNavigation',
  handleActionItemSummaryNavigation: 'handleActionItemSummaryNavigation',
  goToRoute: 'goToRoute',
  goToRouteWithMeta: 'goToRouteWithMeta',
  handlePageChange: 'handlePageChange',
  updateClusterReportHub: 'updateClusterReportHub',
  handleCostSettingPageNavigation: 'handleCostSettingPageNavigation',
  handleRightSizingPageNavigation: 'handleRightSizingPageNavigation',
};

// define the routes and their corresponding handlers
// when a cluster is selected from the clusters dropdown.
export const clusterSelectionConfigs = [
  {
    conditionRoutes: [ACTION_ITEMS_CLUSTER],
    routeName: ACTION_ITEMS_CLUSTER,
    conditionHandler: handlers.equals,
    handlerName: handlerNames.handleActionItemPagesNavigation,
  },
  {
    conditionRoutes: [ACTION_ITEMS],
    routeName: ACTION_ITEMS_CLUSTER,
    conditionHandler: handlers.equals,
    handlerName: handlerNames.handleActionItemPagesNavigation,
  },
  {
    conditionRoutes: [ACTION_ITEMS_CLUSTER_SUMMARY],
    routeName: ACTION_ITEMS_CLUSTER_SUMMARY,
    conditionHandler: handlers.equals,
    handlerName: handlerNames.handleActionItemSummaryNavigation,
  },
  {
    conditionRoutes: [ACTION_ITEMS_SUMMARY],
    routeName: ACTION_ITEMS_CLUSTER_SUMMARY,
    conditionHandler: handlers.equals,
    handlerName: handlerNames.handleActionItemSummaryNavigation,
  },
  {
    conditionRoutes: [ACTION_ITEMS_CLUSTER_REPORTS],
    routeName: ACTION_ITEMS_CLUSTER_REPORTS,
    conditionHandler: handlers.equals,
    handlerName: handlerNames.handleActionItemReportsNavigation,
  },
  {
    conditionRoutes: [ACTION_ITEMS_REPORTS],
    routeName: ACTION_ITEMS_CLUSTER_REPORTS,
    conditionHandler: handlers.equals,
    handlerName: handlerNames.handleActionItemReportsNavigation,
  },
  {
    conditionRoutes: [ACTION_ITEMS_LISTS],
    routeName: ACTION_ITEMS_CLUSTER_LISTS,
    conditionHandler: handlers.equals,
    handlerName: handlerNames.handleActionItemPagesNavigation,
  },
  {
    conditionRoutes: [ORG_EFFICIENCY],
    routeName: NODE_EFFICIENCY,
    conditionHandler: handlers.equals,
    handlerName: handlerNames.handleActionItemPagesNavigation,
  },
  {
    conditionRoutes: [CUMULATIVE_COSTS],
    routeName: CUMULATIVE_COSTS_CLUSTER,
    conditionHandler: handlers.equals,
    handlerName: handlerNames.handleActionItemPagesNavigation,
  },
  {
    conditionRoutes: [CUMULATIVE_COSTS_CLUSTER],
    routeName: CUMULATIVE_COSTS_CLUSTER,
    conditionHandler: handlers.equals,
    handlerName: handlerNames.handleActionItemPagesNavigation,
  },
  {
    conditionRoutes: [COSTS_SETTINGS],
    routeName: COSTS_SETTINGS,
    conditionHandler: handlers.equals,
    handlerName: handlerNames.handleCostSettingPageNavigation,
  },
  {
    conditionRoutes: [COSTS_SETTINGS_CLUSTER],
    routeName: COSTS_SETTINGS_CLUSTER,
    conditionHandler: handlers.equals,
    handlerName: handlerNames.handleCostSettingPageNavigation,
  },
  {
    conditionRoutes: [RIGHT_SIZING],
    routeName: RIGHT_SIZING,
    conditionHandler: handlers.equals,
    handlerName: handlerNames.handleRightSizingPageNavigation,
  },
  {
    conditionRoutes: [RIGHT_SIZING_CLUSTER],
    routeName: RIGHT_SIZING_CLUSTER,
    conditionHandler: handlers.equals,
    handlerName: handlerNames.handleRightSizingPageNavigation,
  },
  {
    conditionRoutes: [IMAGE_DETAIL],
    routeName: REACT_VULNERABILITIES_ALL_IMAGES_CLUSTER,
    conditionHandler: handlers.includes,
    handlerName: handlerNames.goToRoute,
  },
  {
    conditionRoutes: [REACT_VULNERABILITIES_ALL_IMAGES],
    routeName: REACT_VULNERABILITIES_ALL_IMAGES_CLUSTER,
    conditionHandler: handlers.includes,
    handlerName: handlerNames.goToRoute,
  },
  {
    conditionRoutes: [REACT_VULNERABILITIES_ALL_VULNERABILITIES],
    routeName: REACT_VULNERABILITIES_ALL_VULNERABILITIES_CLUSTER,
    conditionHandler: handlers.includes,
    handlerName: handlerNames.goToRoute,
  },
  {
    conditionRoutes: [REACT_VULNERABILITIES],
    routeName: REACT_VULNERABILITIES_ALL_IMAGES_CLUSTER,
    conditionHandler: handlers.includes,
    handlerName: handlerNames.goToRoute,
  },
  {
    conditionRoutes: [ADMISSION_REQUESTS],
    routeName: REACT_ADMISSION_REQUESTS_CLUSTER,
    conditionHandler: handlers.includes,
    handlerName: handlerNames.goToRoute,
  },
  {
    conditionRoutes: [CLUSTER_REPORT_HUB],
    routeName: CLUSTER_REPORT_HUB,
    conditionHandler: handlers.equals,
    handlerName: handlerNames.updateClusterReportHub,
  },
  {
    conditionRoutes: [ORG_DASHBOARD, CLUSTERS],
    conditionHandler: handlers.different,
    handlerName: handlerNames.goToRouteWithMeta,
  },
];

// define the routes and their corresponding handlers
// when the `All Cluster` option is selected from the clusters dropdown.
export const clusterUnselectionConfigs = [
  {
    conditionRoutes: [REACT_VULNERABILITIES_ALL_VULNERABILITIES, REACT_VULNERABILITIES_ALL_VULNERABILITIES_CLUSTER],
    routeName: REACT_VULNERABILITIES_ALL_VULNERABILITIES,
    conditionHandler: handlers.includes,
    handlerName: handlerNames.goToRoute,
  },
  {
    conditionRoutes: [REACT_VULNERABILITIES],
    routeName: REACT_VULNERABILITIES_ALL_IMAGES,
    conditionHandler: handlers.includes,
    handlerName: handlerNames.goToRoute,
  },
  {
    conditionRoutes: [ADMISSION_REQUESTS, ADMISSION_REQUESTS_CLUSTER],
    routeName: ADMISSION_REQUESTS,
    conditionHandler: handlers.equals,
    handlerName: handlerNames.goToRoute,
  },
  {
    conditionRoutes: [ACTION_ITEMS_CLUSTER_SUMMARY],
    routeName: ACTION_ITEMS_SUMMARY,
    conditionHandler: handlers.includes,
    handlerName: handlerNames.handleActionItemSummaryNavigation,
  },
  {
    conditionRoutes: [ACTION_ITEMS_CLUSTER_REPORTS],
    routeName: ACTION_ITEMS_REPORTS,
    conditionHandler: handlers.includes,
    handlerName: handlerNames.handleActionItemReportsNavigation,
  },
  {
    conditionRoutes: [ACTION_ITEMS_CLUSTER],
    routeName: ACTION_ITEMS,
    conditionHandler: handlers.includes,
    handlerName: handlerNames.handleActionItemPagesNavigation,
  },
  {
    conditionRoutes: [ACTION_ITEMS_CLUSTER_LISTS],
    routeName: ACTION_ITEMS_LISTS,
    conditionHandler: handlers.includes,
    handlerName: handlerNames.handleActionItemPagesNavigation,
  },
  {
    conditionRoutes: [NODE_EFFICIENCY],
    routeName: ORG_EFFICIENCY,
    conditionHandler: handlers.includes,
    handlerName: handlerNames.handleActionItemPagesNavigation,
  },
  {
    conditionRoutes: [CUMULATIVE_COSTS],
    routeName: CUMULATIVE_COSTS,
    conditionHandler: handlers.includes,
    handlerName: handlerNames.handleActionItemPagesNavigation,
  },
  {
    conditionRoutes: [CUMULATIVE_COSTS_CLUSTER],
    routeName: CUMULATIVE_COSTS_CLUSTER,
    conditionHandler: handlers.includes,
    handlerName: handlerNames.handleActionItemPagesNavigation,
  },
  {
    conditionRoutes: [COSTS_SETTINGS],
    routeName: COSTS_SETTINGS,
    conditionHandler: handlers.equals,
    handlerName: handlerNames.handleCostSettingPageNavigation,
  },
  {
    conditionRoutes: [COSTS_SETTINGS_CLUSTER],
    routeName: COSTS_SETTINGS_CLUSTER,
    conditionHandler: handlers.equals,
    handlerName: handlerNames.handleCostSettingPageNavigation,
  },
  {
    conditionRoutes: [RIGHT_SIZING],
    routeName: RIGHT_SIZING,
    conditionHandler: handlers.equals,
    handlerName: handlerNames.handleRightSizingPageNavigation,
  },
  {
    conditionRoutes: [RIGHT_SIZING_CLUSTER],
    routeName: RIGHT_SIZING_CLUSTER,
    conditionHandler: handlers.equals,
    handlerName: handlerNames.handleRightSizingPageNavigation,
  },
];

// active routes for the top navigation.
export const topNavigationActiveRoutes = [
  {
    relatedRoutes: [INTEGRATIONS, DATADOG],
    activeRoute: INTEGRATIONS,
  },
  {
    relatedRoutes: [NEWRULE, EDITRULE],
    activeRoute: NEWRULE,
  },
];

import { sendRequestMixin } from '@/utils/request';
import { getOrganization, getOrgTierName } from '@/utils/plugins';

const logger = {
  logEvent: (name, details) => {
    if (process.env.VUE_APP_LOG_EVENTS && window.console) {
      /* eslint-disable-next-line no-console */
      console.log(name, details);
    }
    const data = {
      EventName: name,
      Properties: details || {},
    };
    if (typeof data.Properties !== 'object') {
      data.Properties = { details: data.Properties };
    }

    const organization = getOrganization();
    const orgTier = getOrgTierName();
    if (organization) {
      data.Properties.organization = organization;
    }
    if (orgTier) {
      data.Properties.org_tier = orgTier;
    }

    sendRequestMixin.methods
      .sendRequest('POST', '/v0/track-event', { data, showSuccessAlert: false })
      .catch((e) => {
        if (window.console) {
          /* eslint-disable-next-line no-console */
          console.log('error sending track-event', e);
        }
      });
  },
  logError: (name, details) => {
    logger.logEvent('error', {
      error_type: name,
      ...details,
    });
  },
};

export const loggerMixin = {
  methods: {
    logEvent(name, details) {
      return logger.logEvent(name, details);
    },
    logError(name, details) {
      // eslint-disable-next-line no-console
      console.error(name, details);
      return logger.logError(name, details);
    },
  },
};

export default logger;

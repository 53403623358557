<template>
  <svg viewBox="0 0 23 8" fill="none" xmlns="http://www.w3.org/2000/svg" v-on="$listeners">
    <path d="M1 1l6 6 6-6" :stroke="color"></path>
  </svg>
</template>

<script>
import { COLORS } from '~utils/styling';

export default {
  name: 'caretDownIcon',
  props: {
    color: {
      type: String,
      default: COLORS.CORE.PRIMARY,
    },
  },
};
</script>

import React, { useEffect, useMemo, useState } from 'react';

import BrandIconWhite from '~reactIcons/BrandIconWhite.react';
import MenuItems from './Item/Item.react';
import FairwindsMinimalIcon from '~reactIcons/Navigation/FairwindsMinimal.icon.react';

import {
  CENTAURUS_ROUTE,
  ExpandableItem,
  MAIN_NAVIGATION_ITEMS,
  NavigationItem,
  HIGHLIGHTED_ROUTES_CRITERIA,
  REACT_VULNERABILITIES,
  RECOVER_PASSWORD,
  RECOVER_END,
  NEW_ORG,
  ORG_EFFICIENCY,
  NODE_EFFICIENCY,
  ORG_DASHBOARD,
  ORGS,
} from '../Navigation.config.react';
import { routeHref, assertExpandableItemDependencies } from '../Navigation.helpers.react';

import { ADD_ORG_PARAM_ROUTES } from '../../../router';

import { IStore, IRoute, IRouter } from '~globalTypes';

import { hasKey } from '~reactHelpers';
import { strings } from '~utils/strings';
import { redirectPaths } from '~utils/constants';

import './LeftNavigation.react.scss';

type LeftNavigationProps = {
  route: IRoute;
  router: () => IRouter;
  store: () => IStore;
};

const LeftNavigation = ({ route, router, store }: LeftNavigationProps) => {
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [hoveredItem, setHoveredItem] = useState<string>('');
  const [appSection, setAppSection] = useState<'all' | 'organization' | 'clusters'>('all');

  // TODO: move to parent component
  useEffect(() => {
    if (localStorage.getItem('isNavCollapsed') === 'true') {
      setCollapsed(true);
    }
  }, []);

  useEffect(() => {
    const { organization, cluster } = store().getters;

    if (
      organization?.Name &&
      (!cluster?.Name ||
        (cluster?.Name && router().currentRoute?.name?.includes(REACT_VULNERABILITIES)))
    ) {
      setAppSection('organization');
    } else if (organization?.Name && cluster?.Name) {
      setAppSection('clusters');
    }
  }, [store().getters?.organization?.Name, store().getters?.cluster?.Name]);

  /**
   * Determines which item from the navigation to highlight
   * @param navigationItem the current navigation
   */
  const highlightMenuItem = (navigationItem: NavigationItem | ExpandableItem) => {
    const routeName = navigationItem.route?.name;
    const { secondaryNav, label } = navigationItem;

    // Handle the weird case where we have to highlight both the `Org Dashboard` navigation item
    // and the org name within the org expandable menu
    if (routeName === ORG_DASHBOARD && !('title' in navigationItem)) {
      return label === store().getters.organization?.Name;
    }

    if (shouldHighlight(routeName)) {
      return true;
    }

    return !!(
      routeName === route.name || secondaryNav?.find((item) => item.route?.name === route.name)
    );
  };

  const shouldHighlight = (routeName: string | undefined) => {
    if (!routeName) return false;
    return HIGHLIGHTED_ROUTES_CRITERIA.some(
      (criteria) =>
        criteria.mainRoute === routeName &&
        criteria.relatedRoutes.includes(route.name ? route.name : ''),
    );
  };

  const Items = ({ position }: { position: 'top' | 'bottom' }) => {
    // Used to display hover menu in collapsed view
    const handleHover = (itemName: string) => {
      if (collapsed) setHoveredItem(itemName);
    };

    const organizationsItems = useMemo((): ExpandableItem[] => {
      // if we are in centaurus, just return centaurus
      if (
        !store().getters.memberships?.length &&
        route?.params?.org === strings.noTranslate.centaurus
      ) {
        return [CENTAURUS_ROUTE];
      }

      const orgs = store().getters.memberships?.map(
        (membership): ExpandableItem => ({
          label: membership.Organization,
          route: {
            name: ORG_DASHBOARD,
            params: {
              org: membership.Organization,
            },
            dependencies: ['org'],
            type: 'route',
          },
        }),
      );

      return orgs
        ? [
            ...(orgs || {}),
            {
              label: 'Add Organization',
              route: {
                name: NEW_ORG,
                params: {
                  org: store()?.getters?.organization?.Name || '',
                },
                dependencies: ['org'],
                type: 'route',
              },
            },
          ]
        : [];
    }, []);

    const initiateAction = (action: string) => {
      switch (action) {
        case 'Collapse':
          collapseNav();
          break;
      }
    };

    const handleStickyRouting = (selectedRoute: string, selectedCluster: string) => {
      let newPath = '';
      const params: Record<string, string> = {
        org: route?.params?.org,
      };

      if ((selectedRoute || route?.name) && redirectPaths[selectedRoute || route?.name]) {
        newPath = redirectPaths[selectedRoute || route?.name];
        if (params) params.cluster = selectedCluster;
      }
      router().push({
        name: newPath || selectedRoute || route?.name,
        params,
        query: {
          cluster: selectedCluster,
          Cluster: selectedCluster,
        },
      });
    };

    const handleMenuItemClick = async (
      selectedNav: NavigationItem | ExpandableItem,
      event: React.SyntheticEvent,
    ) => {
      if (event.metaKey || event.ctrlKey || event?.shiftKey) {
        // we're going to open the page in a new tab.
        return;
      }
      event.preventDefault();
      if (!selectedNav.route) return;

      const selectedCluster = JSON.parse(
        localStorage.getItem(strings.noTranslate.currentCluster) || '{}',
      );
      const isSticky = JSON.parse(localStorage.getItem(strings.noTranslate.sticky) || 'false');

      switch (selectedNav.route?.type) {
        case 'action': {
          initiateAction(selectedNav.label);
          break;
        }
        default: {
          if (isSticky && selectedCluster.length > 0 && redirectPaths[selectedNav?.route?.name]) {
            return handleStickyRouting(
              selectedNav.route?.name || route?.name || '',
              selectedCluster,
            );
          }
          const params = selectedNav.route?.params || route.params;
          delete params?.cluster;

          if (params) {
            Object.keys(params).forEach((param) => {
              if (!selectedNav.route?.dependencies?.includes(param)) {
                if (hasKey(params, param)) delete params[param];
              }
            });
          }

          if (ADD_ORG_PARAM_ROUTES.includes(route.name)) {
            const selectedOrg = localStorage.getItem(strings.localStorage.organization);
            if (selectedOrg && params) {
              params.org = selectedOrg;
            } else if (selectedNav.route?.name !== 'logout') {
              router().push({ name: ORGS });
              return;
            }
          }

          let updatedRoute;
          if (!isSticky && selectedNav.route?.name === NODE_EFFICIENCY) {
            updatedRoute = ORG_EFFICIENCY;
          }

          router()
            .push({
              name: updatedRoute || selectedNav.route?.name,
              params,
            })
            .catch((e: any) => {
              if (e.message.includes('Redirected when going')) {
                return;
              }
              console.error(e);
            });
        }
      }
    };

    const ExpandableItems = ({ items }: { items: ExpandableItem[] }) => (
      <>
        {items
          .filter((item) => assertExpandableItemDependencies(store, item.route))
          ?.map((item) => {
            return (
              <MenuItems.Item
                className="navigation-panel__items-expanded"
                item={item}
                active={highlightMenuItem(item)}
                onClick={handleMenuItemClick}
                key={item.label}
                onMouseHover={handleHover}
                routeHref={routeHref(router, item.route)}
                store={store}
                router={router}
              />
            );
          })}
      </>
    );

    const menuLabel = (item: NavigationItem) => {
      if (item.label === 'Organizations' && store().getters.organization?.Name) {
        return store().getters.organization.Name;
      }
      if (item.label === strings.noTranslate.Profile && store().getters.user) {
        return store().getters.user?.FirstName;
      }
      return item.label;
    };

    return (
      <>
        {MAIN_NAVIGATION_ITEMS(router()?.currentRoute?.name || '')
          [position]?.filter((item) => item.section.includes(appSection) && !item.isHidden)
          .map((item) => {
            if (item.expandableItems && !collapsed) {
              if (item.label === 'Organizations') {
                item.expandableItems = organizationsItems;
              }
              return (
                <MenuItems.Expandable item={item} defaultOpen={false} label={menuLabel(item)}>
                  <MenuItems>
                    <ExpandableItems items={item.expandableItems} />
                  </MenuItems>
                </MenuItems.Expandable>
              );
            }

            return (
              <MenuItems.Item
                item={item}
                label={menuLabel(item)}
                active={highlightMenuItem(item)}
                onMouseHover={handleHover}
                onClick={handleMenuItemClick}
                collapsed={collapsed}
                hoveredItem={hoveredItem}
                routeHref={routeHref(router, item?.route)}
                store={store}
                router={router}
              />
            );
          })}
      </>
    );
  };

  const collapseNav = () => {
    setCollapsed(!collapsed);
    store().commit('TOGGLE_NAV_SIZE', !collapsed);
  };

  const BrandIcon = () => {
    if (collapsed) {
      return (
        <FairwindsMinimalIcon
          height="22px"
          width="20px"
          className="navigation-panel__brand-icon padded"
          aria-hidden={true}
          link={router().resolve({ name: ORGS }).href}
        />
      );
    }
    return (
      <BrandIconWhite
        className="navigation-panel__brand-icon padded"
        aria-hidden={true}
        link={router().resolve({ name: ORGS }).href}
      />
    );
  };

  return (
    <>
      {route.name !== RECOVER_END && route.name !== RECOVER_PASSWORD && (
        <nav className={`navigation-panel ${collapsed ? 'collapsed' : ''}`} aria-label="Primary">
          <div>
            <BrandIcon />
          </div>
          <div className={`navigation-panel-container ${collapsed ? 'no-scroll' : ''}`}>
            <section aria-label="organization">
              <MenuItems className="navigation-panel__middle">
                <Items position="top" />
              </MenuItems>
            </section>
            <section aria-label="Settings & Profile">
              <MenuItems className="navigation-panel__bottom">
                <Items position="bottom" />
              </MenuItems>
            </section>
          </div>
        </nav>
      )}
    </>
  );
};

export default LeftNavigation;
